import { FC } from 'react'

import { cn } from 'utils'

import { CREDIT_CARD_EXPIRATION_DATE, FORM_FIELD_LABEL } from '../consts'
import { ExtendTextFieldProps } from './types'
import { MaskedTextFormField } from './MaskedTextFormField'

export const ExpirationDateFormField: FC<ExtendTextFieldProps> = ({
  autoCompleteGroup,
  ...props
}) => {
  return (
    <MaskedTextFormField
      mask="00/00"
      label={props.label ?? FORM_FIELD_LABEL.ccExpirationDate}
      autoComplete={cn(autoCompleteGroup, 'cc-exp')}
      minLength={CREDIT_CARD_EXPIRATION_DATE.formattedLength}
      maxLength={CREDIT_CARD_EXPIRATION_DATE.formattedLength}
      {...props}
    />
  )
}
