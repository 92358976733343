import { AxiosRequestConfig } from 'axios'

import { NestController } from '../NestController'
import { CompanyReferralModel } from './types'

export class CompanyReferral extends NestController {
  constructor() {
    super({ controller: 'company-referral' })
  }

  public getByUuid = (uuid: string, options?: AxiosRequestConfig) => {
    return this.get<CompanyReferralModel>(`/${uuid}`, options)
  }
}
