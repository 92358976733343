import { FC, HTMLAttributes } from 'react'

import { cn } from 'utils'

import classes from './error-message.module.css'

export const ErrorMessage: FCRC<HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...props
}) => {
  if (!children) {
    return null
  }

  return (
    <span className={cn(classes.message, className)} {...props}>
      {children}
    </span>
  )
}
