import { FC } from 'react'

import { SelectFormField } from 'components/form-field/SelectFormField/SelectFormField'
import { COUNTRIES } from 'const/country'
import { ExtendSelectFormFieldProps } from 'components/form-field/SelectFormField/types'
import { cn } from 'utils'

export const SelectCountryFormField: FC<Omit<ExtendSelectFormFieldProps, 'options'>> = ({
  autoCompleteGroup,
  ...props
}) => {
  return (
    <SelectFormField
      options={COUNTRIES}
      placeholder="Select country"
      label={props.label ?? 'Country'}
      autoComplete={cn(autoCompleteGroup, 'country')}
      {...props}
    />
  )
}
