import { NestController } from '../NestController'
import { OfferingCalculationPriceResponseModel, OfferingModel } from './types'
import { NestRestApiQuery } from '../types'

export class OfferingsController extends NestController {
  constructor() {
    super({ controller: 'offerings' })
  }

  calculationPrice = (offeringUuid: string, maxPayout: number, discountCode?: string) => {
    return this.get<OfferingCalculationPriceResponseModel>(
      `/${offeringUuid}/calculation/price?maximumPayout=${maxPayout}${
        discountCode ? '&discountCode=' + discountCode : ''
      }`,
    )
  }

  getAll = (query?: NestRestApiQuery<OfferingModel>) => {
    return this.get<OfferingModel[]>('/', query)
  }
}
