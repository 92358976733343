import { StrapiCollection } from 'api/strapi/index'
import { StrapiCollectionConfig } from 'api/strapi/StrapiCollection'

import { Faq } from './types'

const SEARCH_LIMIT = 5
export class FaqCollection extends StrapiCollection<Faq> {
  constructor() {
    super({ controller: 'faqs' })
  }

  override defaultFindOneParams = {
    populate: {
      related_faqs: {
        fields: ['id', 'title'],
      },
    },
  }

  override defaultFindParams = {
    populate: [],
  }

  public async trendingItems() {
    const { items } = await this.find({
      filters: { trending: true, publishedAt: { $notNull: true } },
      pagination: { limit: 4 },
    })

    return items
  }

  public search = async (query: string, config?: StrapiCollectionConfig): Promise<Faq[]> => {
    const { items } = await this.find(
      {
        filters: { $or: [{ title: { $containsi: query } }, { content: { $containsi: query } }] },
        fields: ['id', 'title', 'slug'],
        pagination: { limit: SEARCH_LIMIT },
      },
      config,
    )
    return items
  }
}
