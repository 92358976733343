import { HTMLAttributes, FC } from 'react'

import classes from 'components/header/default-header/burger/burger.module.css'
import { cn } from 'utils'

interface BurgerProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean
}

export const Burger: FC<BurgerProps> = ({ open, className, ...props }) => {
  return (
    <div className={cn(classes.burger, open && classes.open, className)} {...props}>
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
