import { AxiosRequestConfig } from 'axios'

import { StrapiCollection } from 'api/strapi/index'

import { IThingsToKnow, IThingsToKnowData } from './types'

export class ThingsToKnow extends StrapiCollection<IThingsToKnowData> {
  constructor() {
    super({ controller: 'things-to-knows' })
  }

  public getThingsToKnow(options?: AxiosRequestConfig) {
    return this.get<IThingsToKnow>('/', options)
  }
}
