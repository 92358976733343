import { ComponentProps } from 'react'
import { useRouter } from 'next/router'

import { Button } from 'components/button'

export const ReloadPageButton = ({
  children = 'refresh page',
  ...props
}: Omit<ComponentProps<typeof Button>, 'onClick'>) => {
  const { reload } = useRouter()

  return (
    <Button onClick={reload} theme="inlineLink" {...props}>
      {children}
    </Button>
  )
}
