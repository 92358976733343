import { ARTICLES_SEARCH_FORM_FIELD } from 'sections/articles/search-form/consts'
import { ORIGIN_URL } from 'const/config'

export const QUERY_USE_CASE_ID = 'use-case'
export const QUERY_SUCCESS_ORDER_ID = 'order'
export const ARTICLE_CONTENT = 'content'
export const THE_INDEX_MARKET_LIST = 'markets'
export const HOW_IT_WORKS_CALCULATION_SECTION = 'calculation'
export const BACK_TO_QUERY_PARAM = 'backTo'

//NOTE: If you want change link, make sure than link doesn't use in content of Strapi
class Routes {
  homePage = '/'
  theIndex = '/the-index'
  theIndexMarkets = `${this.theIndex}#${THE_INDEX_MARKET_LIST}`
  prodBaseUrl = 'https://rezitrade.com'

  withBackPage(path: string, backTo: string) {
    return `${path}?${BACK_TO_QUERY_PARAM}=${encodeURIComponent(backTo)}`
  }

  market(slug: string): string {
    return `${this.theIndex}/${slug}`
  }

  articles = '/articles'

  articlesBySlug(tagSlug: string, basicRoute: string) {
    return basicRoute + `?${ARTICLES_SEARCH_FORM_FIELD.tagSlug}=${tagSlug}`
  }

  article(slug: string, basicRoute: string): string {
    return `${basicRoute}/${slug}`
  }

  articleContent(slug: string, basicRoute: string): string {
    return `${basicRoute}/${slug}#${ARTICLE_CONTENT}`
  }

  whyBuy = '/why-buy'
  homeseller = '/homeseller'

  whyBuyUseCaseHistory(id: number) {
    return this.whyBuy + `?${QUERY_USE_CASE_ID}=${id}`
  }

  howItWorks = '/how-it-works'
  howItWorksCalculation = `${this.howItWorks}#${HOW_IT_WORKS_CALCULATION_SECTION}`
  faqs = '/faqs'

  faq(slug: string): string {
    return `${this.faqs}/${slug}`
  }

  faqCategory(slug: string) {
    return `${this.faqs}/categories/${slug}`
  }

  configurationFlow = '/configuration-flow'

  stepPreselect(stepLink: string) {
    const url = new URL(stepLink, ORIGIN_URL)

    return url.pathname
  }

  stepMarket = `${this.configurationFlow}/market`
  stepCoverage = `${this.configurationFlow}/coverage`
  stepPersonalInfo = `${this.configurationFlow}/personal-info`
  stepReview = `${this.configurationFlow}/review`
  stepPayment = `${this.configurationFlow}/payment`
  stepProperty = `${this.configurationFlow}/property`
  stepContract = `${this.configurationFlow}/contract`

  configurationFlowSuccess(orderUuid: string) {
    return `${this.configurationFlow}/success?${QUERY_SUCCESS_ORDER_ID}=${orderUuid}`
  }

  aboutUs = '/about'
  meetTheTeam = '/meet-the-team'

  teamMember(id: number) {
    return `${this.meetTheTeam}/${id}`
  }

  joinUs = '/join-us'

  opportunity(id: number) {
    return `${this.joinUs}/${id}`
  }

  statistics = '/statistics'

  partnerPortal = '/partner-portal'
  partnerPortalLogin = `${this.partnerPortal}/login`
  partnerPortalLogout = `${this.partnerPortal}/logout`
  partnerPortalForgotPassword = `${this.partnerPortal}/forgot-password`
  partnerPortalRegistration = `${this.partnerPortal}/registration`
  partnerPortalDashboard = `${this.partnerPortal}/dashboard`
  partnerPortalProfile = `${this.partnerPortal}/my-profile`
  partnerPortalContactUs = `${this.partnerPortal}/contact-us`

  partnerPortalNewOrderStepMarket = `${this.partnerPortal}/orders/new/market`
  partnerPortalNewOrderStepProperty = `${this.partnerPortal}/orders/new/property`
  partnerPortalNewOrderStepPurchaserInfo = `${this.partnerPortal}/orders/new/purchaser-info`
  partnerPortalNewOrderStepCoverage = `${this.partnerPortal}/orders/new/coverage`
  partnerPortalNewOrderStepReview = `${this.partnerPortal}/orders/new/review`
  partnerPortalNewOrderStepPayment = `${this.partnerPortal}/orders/new/payment`
  partnerPortalNewOrderSuccess = (orderUuid: string) =>
    `${this.partnerPortal}/orders/new/success?${QUERY_SUCCESS_ORDER_ID}=${orderUuid}`
  partnerPortalOrder = (orderUuid: string) => `${this.partnerPortal}/orders/${orderUuid}`
  partnerPortalReferral = (orderUuid: string) => `${this.partnerPortal}/referrals/${orderUuid}`

  contactUs = '/contact-us'

  privacyPolicy = '/privacy-policy'
  termsOfUse = '/terms-of-use'

  clientsLanding = '/clients'
  agentsLanding = '/agents'

  landingHowItWorks = (basicRoute: string) => `${basicRoute}/how-it-works`
  landingArticles = (basicRoute: string) => `${basicRoute}/articles`
  landingHelp = (basicRoute: string) => `${basicRoute}/help`
  listOfMarkets = (basicRoute: string) => `${basicRoute}#${this.listOfMarketsAnchor}`
  joinProgram = (basicRoute: string) => `${basicRoute}#${this.joinProgramAnchor}`

  // anchor ids:
  listOfMarketsAnchor = 'list-of-markets'
  joinProgramAnchor = 'join-the-program'
}

export const routes = new Routes()
