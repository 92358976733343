import { ReactNode, useEffect, useState } from 'react'

interface NoSsrProps {
  fallback?: ReactNode
}

export const NoSsr: FCC<NoSsrProps> = ({ children, fallback }) => {
  const [isMounted, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (isMounted) {
    return <>{children}</>
  }

  return <>{fallback ?? null}</>
}
