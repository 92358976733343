import { AxiosRequestConfig } from 'axios'

import { NestController } from '../NestController'
import { FindAllResponseModel } from '../NestCollection'
import { IRealEstateCompany, ICreateAgentPayload, AgentModel } from './types'

export class RealEstateCompanyController extends NestController {
  constructor() {
    super({ controller: 'real-estate-company' })
  }

  getAgents = (query?: AxiosRequestConfig['params'], config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<AgentModel>>('/agents', query, config)
  }

  createAgent = async (data: ICreateAgentPayload) => {
    return await this.post<AgentModel>('/agents', data)
  }

  getAgentsOfSpecificCompany = (
    companyUuid: string,
    query?: AxiosRequestConfig['params'],
    config?: AxiosRequestConfig,
  ) => {
    return this.get<FindAllResponseModel<AgentModel>>(
      `/companies/${companyUuid}/agents`,
      query,
      config,
    )
  }

  getCompany = (query?: AxiosRequestConfig['params'], config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<IRealEstateCompany>>('/companies', query, config)
  }

  createCompany = async (data: { name: string }) => {
    return await this.post<IRealEstateCompany>('/companies', data)
  }
}
