import { ComponentProps, useId, FC } from 'react'
import { useController } from 'react-hook-form'
import { IMask, IMaskInput } from 'react-imask'
import { ReactMaskProps } from 'react-imask/dist/mixin'
// eslint-disable-next-line import/no-extraneous-dependencies
import { MaskedPattern } from 'imask'

import { cn } from 'utils'

import { BaseFormField } from '../BaseFormField'
import { TextFormFieldProps } from './types'
import formFieldClasses from '../form-field.module.css'

export type MaskInputProps = ComponentProps<typeof IMaskInput>

export interface MaskTextFormField extends Omit<TextFormFieldProps, 'defaultValueTransformer'> {
  mask: MaskInputProps['mask'] | ReturnType<typeof IMask.createMask>
  blocks?: MaskedPattern['blocks']
  lazy?: MaskedPattern['lazy']
  /** Set to formik unmasked value */
  unmask?: boolean | ((maskedValue: string, unmaskedValue: string) => string)
  valueAsNumber?: boolean
}

export const MaskedTextFormField: FC<MaskTextFormField> = ({
  after,
  autoComplete,
  before,
  children,
  className,
  classNameInput,
  classNameInputWrapper,
  classNameLabel,
  defaultValue,
  description,
  fullWidth = true,
  hiddenLabel,
  info,
  label,
  mask,
  name,
  placeholder,
  valueAsNumber,
  variation,
  unmask,
  ...rest
}) => {
  const {
    field: { onChange, ref, value, ...field },
    fieldState,
  } = useController({
    name,
    defaultValue,
  })
  const labelId = useId()

  const hasError = !!fieldState.error

  const myOnChange: ReactMaskProps['onAccept'] = (maskedVal, maskRef) => {
    let nextValue = maskedVal

    if (typeof unmask === 'function') {
      nextValue = unmask(maskedVal, maskRef.unmaskedValue)
    }

    if (typeof unmask === 'boolean' && unmask) {
      nextValue = maskRef.unmaskedValue
    }

    onChange(valueAsNumber ? parseFloat(nextValue.replace(/,/g, '')) : nextValue)
  }

  return (
    <BaseFormField
      after={after}
      before={before}
      className={className}
      classNameInputWrapper={classNameInputWrapper}
      classNameLabel={classNameLabel}
      description={description}
      error={fieldState.error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      info={info}
      label={label}
      labelId={labelId}
      variation={variation}
    >
      <IMaskInput
        id={labelId}
        aria-invalid={hasError}
        aria-labelledby={rest['aria-labelledby']}
        /* @ts-ignore*/
        mask={mask}
        blocks={rest.blocks}
        inputRef={ref}
        className={cn(
          formFieldClasses.field,
          fullWidth && formFieldClasses.fullWidth,
          hasError && formFieldClasses.errorField,
          classNameInput,
        )}
        {...field}
        {...rest}
        onAccept={myOnChange}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={value?.toString()}
      />
    </BaseFormField>
  )
}
