import { useController } from 'react-hook-form'
import { DetailedHTMLProps, InputHTMLAttributes, FC } from 'react'

import CheckReact from 'icons/check-box.svg'
import { cn } from 'utils'

import { BaseFormField, BaseFormFieldProps } from '../BaseFormField'
import classes from './base-checkbox-from-field.module.css'

type CheckboxHTMLProps = SetRequired<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'name'
> & {
  theme?: 'accentMark'
  verticalAlign?: 'start'
}

export type BaseCheckboxFormFieldProps = {
  hideDefaultController?: boolean
  classNameLabel?: string
  classNameMark?: string
} & BaseFormFieldProps &
  Omit<CheckboxHTMLProps, 'className'>

export const BaseCheckboxFormField: FC<BaseCheckboxFormFieldProps> = ({
  name,
  defaultValue,
  children,
  checked,
  classNameLabel,
  classNameInputWrapper,
  className,
  classNameMark,
  theme,
  verticalAlign,
  ...restInputProps
}) => {
  const { field, fieldState, formState } = useController({
    name,
    defaultValue: defaultValue ?? checked ?? false,
  })

  return (
    <BaseFormField
      error={fieldState.error}
      label={null}
      className={className}
      classNameInputWrapper={classNameInputWrapper}
      classNameError={classes.error}
    >
      <label
        className={cn(
          classes.label,
          theme && classes[theme],
          verticalAlign && classes[`${verticalAlign}Align`],
          classNameLabel,
        )}
      >
        <input
          type="checkbox"
          {...field}
          {...restInputProps}
          aria-busy={formState.isLoading}
          checked={field.value}
          className={cn('visuallyHidden', classes.input)}
          disabled={formState.isLoading || restInputProps.disabled === true}
        />
        <div className={cn(classes.mark, classNameMark)}>
          <CheckReact />
        </div>
        {children}
      </label>
    </BaseFormField>
  )
}
