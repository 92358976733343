import { AxiosResponse } from 'axios'

export class BackendError extends Error {
  private _errors: Record<string, string[]> = {}
  private _response?: AxiosResponse<unknown>

  constructor(
    message: string | Record<string, string | string[]>,
    errors?: Record<string, string | string[]>,
    response?: AxiosResponse<unknown>,
  ) {
    super(typeof message === 'string' ? message : Object.values(message).flat().join('\n'))

    if (errors) {
      const entries: [string, string[]][] = Object.entries(errors).map(([field, message]) => {
        return [field, Array.isArray(message) ? message : [message]]
      })
      this._errors = Object.fromEntries(entries)
    }
    this._response = response
  }

  get error() {
    return this.message
  }
  get errors() {
    return this._errors
  }

  get response() {
    return this._response
  }

  get notFound(): boolean {
    return this._response?.status === 404
  }
}
