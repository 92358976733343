import { StrapiCollection } from 'api/strapi/index'

import { CTAModel } from './types'

export class CTACollection extends StrapiCollection<CTAModel> {
  constructor() {
    super({ controller: 'ctas' })
  }

  override defaultFindOneParams = {
    populate: ['image'],
  }

  override defaultFindParams = {
    populate: ['image'],
  }
}
