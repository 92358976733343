import { FC } from 'react'

import { cn } from 'utils/cn'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL, MAX_ADDRESS_LENGTH } from '../consts'

interface AddressFormField extends ExtendTextFieldProps {
  autoComplete: `address-line${1 | 2 | 3 | 4}` | 'street-address'
}

export const AddressFormField: FC<AddressFormField> = ({
  autoCompleteGroup,
  autoComplete,
  ...props
}) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.address}
      autoComplete={cn(autoCompleteGroup, autoComplete)}
      maxLength={MAX_ADDRESS_LENGTH}
      {...props}
    />
  )
}
