import { StrapiController } from 'api/strapi/index'
import { IAmType } from 'const/i-am-options'

export interface SubscribeData {
  first_name: string
  last_name: string
  email: string
  type: IAmType
  agreed: boolean
}

export class NewslettersController extends StrapiController {
  async subscribe(data: SubscribeData) {
    return await this.post('/subscribers', { data })
  }
}
