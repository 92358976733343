import { FC } from 'react'

import { FORM_FIELD_LABEL, SSN } from 'components/form-field/consts'

import { ExtendMaskerFieldProps } from './types'
import { MaskedTextFormField } from './MaskedTextFormField'

export const SocialSecureNumberFormField: FC<ExtendMaskerFieldProps> = ({
  autoCompleteGroup,
  ...props
}) => {
  return (
    <MaskedTextFormField
      mask={SSN.mask}
      maxLength={SSN.formattedLength}
      minLength={SSN.formattedLength}
      placeholder={SSN.placeholder}
      info="See FAQs for why we require your social security number"
      label={props.label ?? FORM_FIELD_LABEL.socialNumber}
      {...props}
    />
  )
}
