import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { CSSProperties, FC, useCallback } from 'react'

import { nest } from 'api'
import { FindAllResponseModel } from 'api/nest/NestCollection'
import { OrderModel } from 'api/nest/orders/types'
import { AsyncError } from 'components/async-data'
import { Pagination } from 'components/pagination'
import { ReloadPageButton } from 'components/reload-page-button'
import { QUERY_KEY } from 'const/query'
import { RecordPerPage } from 'sections/pp-dashboard-page/record-per-page'
import { cn } from 'utils'
import { routes } from 'utils/routes'
import { queryNormalize } from 'utils/url'

import { ActionsProps, ActionType } from './actions'
import { GRID_COLUMNS } from './consts'
import { GridRow } from './orders-grid-row'
import classes from './orders-grid.module.css'

interface OrdersGridProps {
  className?: string
  initialData?: FindAllResponseModel<OrderModel>
  orders?: FindAllResponseModel<OrderModel>
}

export const OrdersGrid: FC<OrdersGridProps> = ({ className, initialData }) => {
  const { query, push } = useRouter()

  const ordersQuery = useQuery(
    [
      QUERY_KEY.orders,
      queryNormalize(query.ordersPage),
      queryNormalize(query.ordersTake),
      queryNormalize(query.ordersSearch),
    ],
    () =>
      nest.broker.getMyOrders({
        // orderField: 'createdAt',
        orderType: 'DESC',
        page: queryNormalize(query.ordersPage),
        take: queryNormalize(query.ordersTake),
        search: queryNormalize(query.ordersSearch),
      }),
    {
      initialData,
    },
  )

  const onAction: ActionsProps['onAction'] = useCallback(
    (action, order) => {
      if (action === ActionType.view) {
        return push(routes.partnerPortalOrder(order.uuid))
      }

      if (action === ActionType.viewContract) {
        return window.open(nest.agreements.getPreviewAgreementPdfUrl(order.uuid))
      }
    },
    [push],
  )

  const { itemCount, pageCount, page, take } = ordersQuery.data?.meta ?? {
    itemCount: 0,
    pageCount: 0,
    page: 0,
    take: 0,
  }

  if (ordersQuery.error) {
    return (
      <AsyncError error={ordersQuery.error}>
        <p>
          Error loading orders, please <ReloadPageButton /> or try again later
        </p>
      </AsyncError>
    )
  }

  return (
    <>
      <section
        className={cn(classes.grid, ordersQuery.isInitialLoading && classes.wait, className)}
        style={
          {
            '--main-column-number': GRID_COLUMNS.length,
          } as CSSProperties
        }
      >
        <div className={cn(classes.gridRow, classes.headerRow)}>
          {GRID_COLUMNS.map((column) => (
            <div
              className={cn(classes.gridItem, classes.headerTitle, column.className)}
              key={column.headerTitle}
            >
              {column.headerTitle}
            </div>
          ))}
        </div>

        {ordersQuery.isInitialLoading && <p className={classes.noData}>Loading...</p>}
        {!ordersQuery.isInitialLoading && ordersQuery.data?.items?.length === 0 && (
          <p className={classes.noData}>No orders placed yet</p>
        )}

        {ordersQuery.data?.items?.map((order) => (
          <GridRow order={order} key={order.uuid} onAction={onAction} />
        ))}
      </section>
      <div className={classes.footer}>
        <RecordPerPage className={classes.record} takeParamName="ordersTake" />
        <Pagination
          page={page}
          pageCount={pageCount}
          pageParamName="ordersPage"
          className={classes.pagination}
          shallow
        />
        <strong className={classes.rowCount}>
          Showing {(page - 1) * take + 1}-{Math.min(page * take, itemCount)} of {itemCount}
        </strong>
      </div>
    </>
  )
}
