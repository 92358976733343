export enum OrderStatusEnum {
  PurchaseInitiated = 'PurchaseInitiated',
  PaymentInitiated = 'PaymentInitiated',
  Suspended = 'Suspended',
  Declined = 'Declined',
  IssuedActive = 'IssuedActive',
  IssuedPending = 'IssuedPending',
  NotIssued = 'NotIssued',
  Cancelled = 'Cancelled',
  CancelledByClient = 'CancelledByClient',
  Expired = 'Expired',
  PayoutOwed = 'PayoutOwed',
  PayoutCompleted = 'PayoutCompleted',
}
