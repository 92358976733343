import React from 'react'

const dispatchStorageEvent = (key, newValue) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setSessionStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value)
  window.sessionStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
}

const removeSessionStorageItem = (key) => {
  window.sessionStorage.removeItem(key)
  dispatchStorageEvent(key, null)
}

const getSessionStorageItem = (key) => {
  return window.sessionStorage.getItem(key)
}

const useSessionStorageSubscribe = (callback) => {
  window.addEventListener('storage', callback)
  return () => window.removeEventListener('storage', callback)
}

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const getSnapshot = () => getSessionStorageItem(key)

  const store = React.useSyncExternalStore(useSessionStorageSubscribe, getSnapshot, () => '{}')

  const setState = React.useCallback(
    (v) => {
      try {
        const nextState =
          typeof v === 'function' && typeof store === 'string' ? v(JSON.parse(store)) : v

        if (nextState === undefined || nextState === null) {
          removeSessionStorageItem(key)
        } else {
          setSessionStorageItem(key, nextState)
        }
      } catch (e) {}
    },
    [key, store],
  )

  React.useEffect(() => {
    if (getSessionStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setSessionStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return [store ? JSON.parse(store) : initialValue, setState]
}
