export enum PurchaserTypeEnum {
  contractHolder = 'contract-holder',
  broker = 'broker',
  other = 'other',
}

export enum PurchaseTypeEnum {
  ForOwnHome = 'for_own_home', // Direct purchase
  ForOtherHome = 'for_other_home', // Gift flow
  CoveredByPartner = 'covered_by_partner', // Two-way flow
  PaidByHomeowner = 'paid_by_homeowner', // Partner Purchase
  PaidByThirdParty = 'paid_by_third_party', // Three-way flow
  PartnerDeferredPayment = 'partner_deferred_payment', // Deferred payment flow
}

export function getPurchaserType(purchaseType: PurchaseTypeEnum): PurchaserTypeEnum {
  switch (purchaseType) {
    case PurchaseTypeEnum.ForOwnHome:
      return PurchaserTypeEnum.contractHolder
    case PurchaseTypeEnum.ForOtherHome:
      return PurchaserTypeEnum.other
    case PurchaseTypeEnum.CoveredByPartner:
      return PurchaserTypeEnum.broker
    case PurchaseTypeEnum.PaidByHomeowner:
      return PurchaserTypeEnum.contractHolder
    case PurchaseTypeEnum.PaidByThirdParty:
      return PurchaserTypeEnum.other
    case PurchaseTypeEnum.PartnerDeferredPayment:
      return PurchaserTypeEnum.broker
    default:
      throw new Error('Unknown Purchase Type')
  }
}
