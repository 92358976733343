import { ComponentProps } from 'react'

import { cn } from 'utils//cn'
import { RCFC } from 'utils/types'

import classes from './icon-button.module.css'

export interface IconButtonProps extends ComponentProps<'button'> {
  beforeText?: string
  afterText?: string
}

export const IconButton: RCFC<IconButtonProps> = ({
  className,
  afterText,
  beforeText,
  children,
  ...buttonProps
}) => {
  return (
    <button className={cn(classes.button, className)} {...buttonProps}>
      {beforeText && <span className={classes.beforeText}>{beforeText}</span>}
      {children}
      {afterText && <span className={classes.afterText}>{afterText}</span>}{' '}
    </button>
  )
}
