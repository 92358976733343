import { addPathSearchParameter } from 'utils/url'
import { PAGE_QUERY_NAME } from 'const/list'

export const withPage = (path: string, page: string | number, pageQueryName?: string): string => {
  return addPathSearchParameter({
    path,
    name: pageQueryName || PAGE_QUERY_NAME,
    value: String(page),
    clearPrevValue: true,
  })
}
