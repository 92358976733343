import { Tag } from 'api/index'
import { TeamPerson } from 'api/strapi/teams/types'
import { StrapiImage, StrapiPublishedRecord } from 'api/strapi/StrapiRecord'
import { ArticleSearchFormModel } from 'sections/articles/search-form/search-form'

export interface SearchArticleModel extends Partial<ArticleSearchFormModel> {
  page: number
}

interface BaseComponentModel<T extends string> {
  id: number
  __component: T
}

export interface RowSectionOptions {
  id: number
  width: number
  with_border: boolean
}

export interface RowComponentModel<T extends string, FirstSection, SecondSection>
  extends BaseComponentModel<T> {
  first_section: FirstSection
  second_section: SecondSection
  reverse: boolean
  second_section_options: RowSectionOptions
  first_section_options: RowSectionOptions
}

export const enum ComponentName {
  text = 'content-sections.only-text',
  textAndImage = 'content-sections.text-and-image',
  fullWidthImage = 'content-sections.full-width-image',
  styledList = 'content-sections.list',
  styledListItem = 'content-sections.list-item',
  textAndText = 'content-sections.text-and-text',
  teamMembers = 'content-sections.team-members',
}

export const enum ListTheme {
  accentArrow = 'accent-arrow',
  checkmark = 'checkmark',
}

export interface ComponentTextModel extends BaseComponentModel<ComponentName.text> {
  text: string
  with_border: boolean
  list_theme?: ListTheme
}

export interface ComponentFullWidthImageModel
  extends BaseComponentModel<ComponentName.fullWidthImage> {
  image: StrapiImage
  description?: string
  with_border: boolean
}

export interface ComponentTextAndImageModel
  extends Omit<
    RowComponentModel<ComponentName.textAndImage, ComponentTextModel, ComponentFullWidthImageModel>,
    'second_section'
  > {
  second_section_image: StrapiImage
  second_section_image_description?: string
}

export interface ComponentStyledListItemModel
  extends BaseComponentModel<ComponentName.styledListItem> {
  text: string
}

export interface ComponentTeamMembers extends BaseComponentModel<ComponentName.teamMembers> {
  description?: string
  heading?: string
  pre_heading?: string
  team_people: TeamPerson[]
}

export interface ComponentTextAndTextModel
  extends RowComponentModel<ComponentName.textAndText, ComponentTextModel, ComponentTextModel> {}

export type ArticleContentSection =
  | ComponentTextModel
  | ComponentTextAndImageModel
  | ComponentFullWidthImageModel
  | ComponentTextAndTextModel
  | ComponentTeamMembers

//use custom_published_at instead of publishedAt
export interface Article extends Omit<StrapiPublishedRecord, 'publishedAt'> {
  title: string
  description: string
  featured?: boolean
  cover: StrapiImage
  tags?: Tag[] | null
  content_sections?: ArticleContentSection[]
  custom_published_at: string
  content_for_search: string | null
  related_articles?: Omit<Article, 'related_articles'>[]
  slug: string
}
