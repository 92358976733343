import { AxiosRequestConfig } from 'axios'

import { NestPaginationQueryWithSearch } from 'api/nest/types'

import { FindAllResponseModel, NestCollection } from '../NestCollection'
import { ReferralCreateModel, ReferralModel, ReferralStatus, ReferralUpdateModel } from './types'
import { resolveUrl } from '../../../utils/url'
import { BASE_NEST_URL } from '../../../const/config'

export class ReferralsCollection extends NestCollection<ReferralModel, true> {
  constructor() {
    super({ controller: 'referrals' })
  }

  create = (data: ReferralCreateModel, config?: AxiosRequestConfig) => {
    return this.post('/', { status: ReferralStatus.paid, ...data }, config)
  }

  searchByAuthUser = (query: NestPaginationQueryWithSearch, config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<ReferralModel>>('/', query, {
      baseURL: resolveUrl(BASE_NEST_URL, '/me/referrals'),
      ...config,
    })
  }

  advancedSearch = async (query: NestPaginationQueryWithSearch, config?: AxiosRequestConfig) => {
    const page = query.page ? Number(query.page) : 1
    const take = query.take ? Number(query.take) : undefined

    let referralsInfo = await this.searchByAuthUser(
      {
        ...query,
        orderType: 'DESC',
        page,
        take,
      },
      config,
    )

    const maxPage = referralsInfo.meta.pageCount

    if (maxPage && page > maxPage) {
      referralsInfo = await this.searchByAuthUser(
        {
          ...query,
          orderType: 'DESC',
          page: maxPage,
          take,
        },
        config,
      )
    }

    return referralsInfo
  }

  update = ({ uuid, ...data }: ReferralUpdateModel, config?: AxiosRequestConfig) => {
    return this.request.patch(`/${uuid}`, data, config)
  }
}
