import * as RadixSlider from '@radix-ui/react-slider'
import { SliderProps as RadixSliderProps, SliderRangeProps } from '@radix-ui/react-slider'
import * as React from 'react'
import { forwardRef, useCallback, useEffect, useState, FC } from 'react'

import { cn } from 'utils'

import classes from './slider.module.css'

const CustomRange: React.ForwardRefExoticComponent<
  SliderRangeProps & React.RefAttributes<HTMLSpanElement>
> = forwardRef((props, ref) => {
  const { style } = props
  let right = style?.right ?? '0'
  const floatRight = typeof right === 'number' ? right : parseFloat(right)

  if (floatRight <= 24) {
    right = `calc(${style?.right} + 10px)`
  } else if (floatRight >= 76) {
    right = `calc(${style?.right} - 15px)`
  }

  const newStyle = {
    ...props.style,
    right,
  }

  return <span {...props} style={newStyle} ref={ref} />
})

CustomRange.displayName = 'CustomRange'

export interface SliderProps
  extends Omit<RadixSliderProps, 'onValueChange' | 'defaultValue' | 'value'> {
  defaultValue?: number
  value?: number
  onValueChange?: (value: number) => void
  valueFormatter?: (value: number) => string | number | JSX.Element
  classNameTooltip?: string
}

export const Slider: FC<SliderProps> = ({
  className,
  onValueChange: onChange,
  defaultValue,
  valueFormatter,
  classNameTooltip,
  value: fieldValue,
  ...rootProps
}) => {
  const [value, setValue] = useState(fieldValue ?? defaultValue ?? 0)

  const onValueChange: RadixSliderProps['onValueChange'] = useCallback(
    ([value]) => {
      setValue(value!)
      onChange && onChange(value)
    },
    [onChange],
  )

  useEffect(() => {
    if (typeof fieldValue === 'number') setValue(fieldValue)
  }, [fieldValue])

  return (
    <RadixSlider.Root
      {...rootProps}
      defaultValue={defaultValue ? [defaultValue] : undefined}
      className={cn(classes.root, className)}
      onValueChange={onValueChange}
      value={[value]}
    >
      <RadixSlider.Track className={classes.track}>
        <RadixSlider.Range className={classes.range} asChild={true}>
          <CustomRange />
        </RadixSlider.Range>
      </RadixSlider.Track>

      <RadixSlider.Thumb className={classes.thumb}>
        <span className={cn(classes.tooltip, classNameTooltip)}>
          {valueFormatter ? valueFormatter(value) : value}
        </span>
      </RadixSlider.Thumb>
    </RadixSlider.Root>
  )
}
