import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { logAxiosError } from 'api/strapi/utils'

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.'
export const SHORT_DEFAULT_ERROR_MESSAGE = 'Something went wrong'

export abstract class Request {
  request: AxiosInstance

  protected constructor(axiosConfig: AxiosRequestConfig) {
    this.request = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      ...axiosConfig,
    })
    this.request.interceptors.request.use(undefined, this.handlerRequestError)
    this.request.interceptors.response.use(undefined, this.handlerResponseError)
  }

  protected async get<T = unknown>(
    url: string,
    query?: AxiosRequestConfig['params'],
    config?: Omit<AxiosRequestConfig, 'params'>,
  ): Promise<T> {
    const response = await this.request.get<T>(url, { ...config, params: query })
    return response.data
  }

  protected async post<T = unknown>(
    url: string,
    data?: AxiosRequestConfig['data'],
    config?: Omit<AxiosRequestConfig, 'data'>,
  ): Promise<T> {
    try {
      const response = await this.request.post<T>(url, data, config)
      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw error
      }

      throw new Error(DEFAULT_ERROR_MESSAGE)
    }
  }

  protected async put<T = unknown>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: Omit<AxiosRequestConfig, 'data'>,
  ): Promise<T> {
    const response = await this.request.put<T>(url, data, config)
    return response.data
  }

  protected async patch<T = unknown>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: Omit<AxiosRequestConfig, 'data'>,
  ): Promise<T> {
    const response = await this.request.patch<T>(url, data, config)
    return response.data
  }

  static responseErrorLogger(error: AxiosError | Error) {
    logAxiosError(error)
  }

  protected handlerRequestError(error: AxiosError): void | Promise<AxiosError> {
    logAxiosError(error)
    return Promise.reject(error)
  }

  private getFileNameByResponse = (response: AxiosResponse) => {
    let filename = ''
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }

    return filename
  }

  protected getFileInfoForSave(response: AxiosResponse<string>) {
    return {
      blob: new Blob([response.data], { type: response.headers['content-type'] }),
      fileName: this.getFileNameByResponse(response),
    }
  }

  protected abstract handlerResponseError(error: AxiosError): void | Promise<AxiosError>
}
