import React, { FC, useMemo } from 'react'
import { LinkProps } from 'next/dist/client/link'

import { useQueryMatch } from 'utils/router'
import { addPathSearchParameter } from 'utils/url'
import { cn } from 'utils/cn'

import { Link } from './Link'

interface IQueryNavLink extends Pick<LinkProps, 'replace' | 'scroll' | 'shallow'> {
  navQueryName: string
  navQueryValue: string | number
  activeClassName?: string
  className?: string
  forceActive?: boolean
  clearPrevValue?: boolean
  onClick?: React.MouseEventHandler
}

export const QueryNavLink: FCRC<IQueryNavLink> = ({
  navQueryName,
  navQueryValue,
  children,
  className,
  activeClassName,
  replace = true,
  shallow = true,
  scroll = false,
  forceActive,
  clearPrevValue,
  ...rest
}) => {
  const { route, match } = useQueryMatch(navQueryName, navQueryValue)

  const link = useMemo(() => {
    return addPathSearchParameter({
      path: route.asPath,
      name: navQueryName,
      value: String(navQueryValue),
      clearPrevValue,
    })
  }, [clearPrevValue, navQueryName, navQueryValue, route.asPath])

  return (
    <Link
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      href={link}
      prefetch={false}
      className={cn(className, (match || forceActive) && activeClassName)}
      {...rest}
    >
      {children}
    </Link>
  )
}
