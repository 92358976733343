import { ReactNode, FC } from 'react'

import { percentFormatter } from 'utils/number'
import DownIcons from 'icons/down-icons.svg'
import { usdFormatter } from 'utils'
import { BaseFormField } from 'components/form-field/BaseFormField'

import { SliderFormField, SliderFormFieldProps } from './SliderFormField'
import styles from './extended-slider-form-field.module.css'

type Formatter = (value: number) => string | number | JSX.Element
export interface ExtendedSliderFormFieldProps extends SliderFormFieldProps {
  label?: ReactNode
  info?: string
  description?: string
  className?: string
  name: string
  defaultValue?: number
  formatter: Formatter
}

export interface SliderFormFieldComponent extends FC<ExtendedSliderFormFieldProps> {
  formatPercent: Formatter
  formatCurrency: Formatter
}

export const ExtendedSliderFormField = (({
  className,
  label,
  info,
  name,
  description,
  formatter,
  defaultValue,
  ...props
}) => {
  return (
    <BaseFormField
      className={className}
      classNameLabel={styles.label}
      label={label}
      info={info}
      description={description}
      variation="large"
      fullWidth
    >
      <SliderFormField
        {...props}
        name={name}
        valueFormatter={formatter}
        defaultValue={defaultValue}
      />
    </BaseFormField>
  )
}) as SliderFormFieldComponent

ExtendedSliderFormField.formatPercent = (value: number) => {
  return (
    <span className={styles.percentSlideTooltip}>
      <DownIcons />
      {percentFormatter(value, 0)}
    </span>
  )
}

ExtendedSliderFormField.formatCurrency = (value) =>
  usdFormatter(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
