import { FC } from 'react'

import { cn } from 'utils'

import { CC_CVV_LENGTH, FORM_FIELD_LABEL } from '../consts'
import { ExtendTextFieldProps } from './types'
import { MaskedTextFormField } from './MaskedTextFormField'

export const CvvFormField: FC<ExtendTextFieldProps> = ({
  autoCompleteGroup,
  classNameLabel,
  ...props
}) => {
  return (
    <MaskedTextFormField
      mask="0000"
      label={props.label ?? FORM_FIELD_LABEL.cvv}
      autoComplete={cn(autoCompleteGroup, 'cc-csc')}
      info="3 or 4 digits usually found on the signature strip"
      minLength={CC_CVV_LENGTH}
      maxLength={CC_CVV_LENGTH}
      {...props}
    />
  )
}
