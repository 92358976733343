import { FC } from 'react'

import { SelectFormField } from 'components/form-field/SelectFormField/SelectFormField'
import { STATES } from 'const/states'
import { ExtendSelectFormFieldProps } from 'components/form-field/SelectFormField/types'
import { cn } from 'utils'

import { FORM_FIELD_LABEL } from '../consts'

export const SelectStateFormField: FC<Omit<ExtendSelectFormFieldProps, 'options'>> = ({
  autoCompleteGroup,
  ...props
}) => {
  return (
    <SelectFormField
      options={STATES}
      placeholder="Select state"
      label={props.label ?? FORM_FIELD_LABEL.state}
      autoComplete={cn(autoCompleteGroup, 'address-level1')}
      {...props}
    />
  )
}
