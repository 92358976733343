import { MouseEventHandler, FC } from 'react'

import { cn } from 'utils'
import { OrderModel } from 'api/nest/orders/types'

import classes from './actions.module.css'

export const enum ActionType {
  view = 'view',
  viewContract = 'viewContract',
}

export interface ActionMenuProps {
  setIsOpen: (value: boolean) => void
  order: OrderModel
  onClick: (action: ActionType, order: OrderModel) => void
}

const LIST = [
  {
    title: 'View',
    action: ActionType.view,
  },
  {
    title: 'View Contract',
    action: ActionType.viewContract,
  },
]

export const Menu: FC<ActionMenuProps> = ({ setIsOpen, onClick, order }) => {
  const onClickAction: MouseEventHandler<HTMLLIElement> = (event) => {
    onClick(event.currentTarget.dataset.actionType as ActionType, order)
    setIsOpen(false)
  }

  return (
    <ul className={cn(classes.menu)}>
      {LIST.map(({ action, title }, index) => (
        <li
          className={classes.menuItem}
          data-action-type={action}
          key={index}
          onClick={onClickAction}
          tabIndex={0}
        >
          <button className={classes.button}>{title}</button>
        </li>
      ))}
    </ul>
  )
}
