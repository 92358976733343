import { ComponentProps, FC } from 'react'

import { ExtendedSliderFormField } from './ExtendedSliderFormField'

type TriggerLevelSliderFormFieldProps = Omit<
  ComponentProps<typeof ExtendedSliderFormField>,
  'formatter' | 'description' | 'min' | 'max' | 'step' | 'info'
>

export const TriggerLevelSliderFormField: FC<TriggerLevelSliderFormFieldProps> = ({
  label,
  ...props
}) => {
  return (
    <ExtendedSliderFormField
      formatter={ExtendedSliderFormField.formatPercent}
      max={100}
      min={0}
      step={1}
      {...props}
    />
  )
}

TriggerLevelSliderFormField.defaultProps = {
  label: (
    <>
      Choose your <strong>trigger</strong>
    </>
  ),
}
