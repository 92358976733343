import PhoneInput from 'react-phone-input-2'
import React, { FC, useId, useRef } from 'react'
import { UseFormReturn, useController } from 'react-hook-form'

import { NoSsr } from 'components/no-ssr'

import { BaseFormField } from '../BaseFormField'
import { cn } from '../../../utils'
import formFieldClasses from '../form-field.module.css'
import { ExtendTextFieldProps, IPhoneNumberValidationData } from './types'
import {
  FORM_FIELD_LABEL,
  US_PHONE_NUMBER_LENGTH,
  SessionStorageKeysEnum,
  CountriesEnum,
  prefferedCountries,
  excludedCountries,
} from '../consts'

import 'react-phone-input-2/lib/style.css'

interface PhoneFormFieldProps extends ExtendTextFieldProps {
  formMethods: UseFormReturn<any, any>
}

interface ICountry {
  countryCode: string
  dialCode: string
  format: string
  iso2: string
  name: string
  priority: number
  regions: string[]
}

export const InternationalPhoneFormField: FC<PhoneFormFieldProps> = ({
  name,
  defaultValue = '',
  label,
  classNameInput,
  fullWidth = true,
  className,
  classNameInputWrapper,
  classNameLabel,
  defaultValueTransformer,
  after,
  before,
  variation,
  info,
  description,
  formMethods,
  ...inputProps
}) => {
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({
    name,
    defaultValue,
  })
  const labelId = useId()

  const debounceTimeout = useRef<NodeJS.Timeout>()

  const hasError = !!fieldState.error

  const saveToSessionStorage = (isWhitelistedCountry: boolean, country: ICountry) => {
    const isUS = country.name === CountriesEnum.us
    const obj: IPhoneNumberValidationData = {
      isWhitelistedCountry,
      requiredLength: isUS ? US_PHONE_NUMBER_LENGTH : null,
    }
    sessionStorage.setItem(SessionStorageKeysEnum.phoneInputValidationData, JSON.stringify(obj))
  }

  const checkValidity = (inputNumber: string, country: ICountry, countries: ICountry[]) => {
    const isWhitelistedCountry = countries.some((count) => inputNumber.startsWith(count.dialCode))

    saveToSessionStorage(isWhitelistedCountry, country) // to enhance validation

    return isWhitelistedCountry
  }

  const handleInputChange = async (
    inputNumber: string,
    newCountry: {} | ICountry,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const dialCode = (newCountry as ICountry).dialCode
    formMethods.setValue(name, e.type === 'click' ? dialCode : inputNumber)

    debounceTimeout.current && clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(async () => {
      await formMethods.trigger(name)
    }, 1500)
  }

  const handleMount = () => {
    sessionStorage.removeItem(SessionStorageKeysEnum.phoneInputValidationData)
  }

  return (
    <NoSsr>
      <BaseFormField
        error={fieldState.error}
        label={label ?? FORM_FIELD_LABEL.phone}
        labelId={labelId}
        fullWidth={fullWidth}
        className={className}
        classNameInputWrapper={classNameInputWrapper}
        classNameLabel={classNameLabel}
        variation={variation}
        description={description}
      >
        <PhoneInput
          {...field}
          specialLabel=""
          aria-invalid={hasError}
          country="us"
          preferredCountries={prefferedCountries}
          excludeCountries={excludedCountries}
          enableSearch
          inputProps={{
            ref,
            className: cn(
              formFieldClasses.field,
              fullWidth && formFieldClasses.fullWidth,
              hasError && formFieldClasses.errorField,
              formFieldClasses.phoneInput,
              classNameInput,
            ),
            id: labelId,
            ...inputProps,
          }}
          onChange={handleInputChange}
          isValid={(inputNumber, country: ICountry, countries: ICountry[]) =>
            checkValidity(inputNumber, country, countries)
          }
          onMount={handleMount}
        />
      </BaseFormField>
    </NoSsr>
  )
}
