import { FC } from 'react'

import { Loader } from 'components/loader'
import { Heading, HeadingProps } from 'components/heading'
import { LoaderProps } from 'components/loader/loader'
import { cn } from 'utils'

import classes from './loader-with-description.module.css'

interface LoaderWithDescriptionProps {
  className?: string
  title: string
  description?: string
  loader?: LoaderProps
  theme?: LoaderProps['theme']
  heading?: Omit<HeadingProps, 'ref'>
}

export const LoaderWithDescription: FC<LoaderWithDescriptionProps> = ({
  loader,
  heading,
  className,
  description,
  title,
  theme,
}) => {
  return (
    <div className={cn(classes.container, theme && classes[`${theme}Theme`], className)}>
      <Loader
        {...loader}
        theme="inherit"
        className={cn(classes.loader, loader?.className)}
        size={loader?.size || 'medium'}
      />
      <Heading
        {...heading}
        theme={heading?.theme || 'h5'}
        component={heading?.component}
        className={cn(classes.title, heading?.className)}
      >
        {title}
      </Heading>
      {description && <p className={classes.desc}>{description}</p>}
    </div>
  )
}
