import { FC, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { usePathname } from 'next/navigation'

import { BuyNowButton } from 'components/buy-now-button'
import { LinkButton, NavLink, NavLinkProps } from 'components/links'
import { cn } from 'utils'
import { routes } from 'utils/routes'
import { useFlowStorage } from 'utils/use-flow-storage'

import {
  LandingPageTypeEnum,
  areHeaderBtnsVisible,
  checkForLandingPage,
  getBasicRoute,
} from '../utils'
import styles from './header-nav.module.css'

const HEADER_MENU_ITEMS: Record<string, string> = {
  'WHY BUY': routes.whyBuy,
  'HOW IT WORKS': routes.howItWorks,
  'THE INDEX': routes.theIndex,
  'ARTICLES & INSIGHTS': routes.articles,
  FAQs: routes.faqs,
}

export interface HeaderNavProps {
  className?: string
  isMobileMenuOpen: boolean
  onCloseMobileMenu: () => void
}

export const HeaderNav: FC<HeaderNavProps> = ({
  className,
  isMobileMenuOpen,
  onCloseMobileMenu,
  ...props
}) => {
  const [withAnimation, setWithAnimation] = useState(false)
  const [flowStorageData] = useFlowStorage()
  const pathname = usePathname()
  const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

  useEffectOnce(() => {
    // NOTE: This is a hack to doesn't get the animation to work on the first render
    setWithAnimation(true)
  })

  const getLandingPagesMenuItems = (
    landingPageType: LandingPageTypeEnum,
  ): Record<string, string> => {
    const basicRoute = getBasicRoute(landingPageType)

    return {
      'HOW IT WORKS': routes.landingHowItWorks(basicRoute),
      ARTICLES: routes.landingArticles(basicRoute),
      HELP: routes.landingHelp(basicRoute),
    }
  }

  const getMenuItems = () => {
    if (isLandingPage && landingPageType) {
      return getLandingPagesMenuItems(landingPageType)
    }
    return HEADER_MENU_ITEMS
  }

  const menuItems = getMenuItems()

  return (
    <>
      <div
        className={cn(styles.mobileMenuOverlay, isMobileMenuOpen && styles.openOverlay)}
        onClick={onCloseMobileMenu}
      />
      <nav
        {...props}
        className={cn(
          styles.nav,
          isMobileMenuOpen && styles.open,
          withAnimation && styles.withAnimation,
          className,
        )}
      >
        <ul className={cn(styles.list, isLandingPage && styles.formattedList)}>
          {Object.entries(menuItems).map(([name, link]) => (
            <Item href={link} onClick={onCloseMobileMenu} key={name}>
              {name}
            </Item>
          ))}

          <li className={cn(styles.item, styles.buyNowMobileItem)}>
            {areHeaderBtnsVisible(flowStorageData, pathname) && (
              <>
                <LinkButton
                  theme="secondary"
                  href={routes.homeseller}
                  className={styles.buyNowMobileLink}
                  onClick={() => onCloseMobileMenu()}
                >
                  Home Sellers
                </LinkButton>
                <BuyNowButton
                  toIgnoreSessionRole={true}
                  toClearStorage={true}
                  className={styles.buyNowMobileLink}
                  onClick={() => onCloseMobileMenu()}
                />
              </>
            )}
          </li>
        </ul>
      </nav>
    </>
  )
}

const Item: FC<NavLinkProps> = ({ activeClassName, className, ...props }) => (
  <li className={styles.item}>
    <NavLink
      {...props}
      activeClassName={cn(styles.active, activeClassName)}
      className={cn(styles.link, className)}
    />
  </li>
)
