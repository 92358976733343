import { Controller } from 'react-hook-form'
import { FC } from 'react'

import { Slider, SliderProps } from 'components/slider'

export type SliderFormFieldProps = SliderProps & {
  name: string
}

export const SliderFormField: FC<SliderFormFieldProps> = ({ name, defaultValue, ...rest }) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field }) => {
        return (
          <Slider
            {...rest}
            name={field.name}
            onBlur={field.onBlur}
            value={field.value}
            onValueChange={field.onChange}
          />
        )
      }}
    />
  )
}
