import { DateTime } from 'luxon'

export const dateFormatter = (value: string | Date, options?: Intl.DateTimeFormatOptions) => {
  const date =
    value instanceof Date ? DateTime.fromJSDate(value) : DateTime.fromISO(value, { zone: 'UTC' })
  return date.setLocale('en-us').toLocaleString({
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    ...options,
  })
}

export const unixToDate = (sec: number) => {
  const MS_IN_SEC = 1000
  return new Date(sec * MS_IN_SEC)
}

export const isValidStringForDateInput = (date?: string) => {
  if (!date) return false

  return /^\d{4}-\d{2}-\d{2}$/.test(date)
}

export const isIsoString = (date: string) => /^\d{4}-\d{2}-\d{2}T/.test(date)

const DATE_INPUT_VALUE_FORMAT = 'yyyy-MM-dd'
export const dateStringToDateInputValue = (date?: string | Date) => {
  if (!date) return ''

  if (typeof date === 'string') {
    if (isValidStringForDateInput(date)) return date
    if (isIsoString(date)) return DateTime.fromISO(date).toFormat(DATE_INPUT_VALUE_FORMAT)
    return ''
  }

  return DateTime.fromJSDate(date).toFormat(DATE_INPUT_VALUE_FORMAT)
}

const DEFAULT_SERVER_DATE_FORMAT = 'yyyy-MM-dd'
export const transformFormDateToFormat = (
  date: string | Date,
  format: string = DEFAULT_SERVER_DATE_FORMAT,
) => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat(format)
  }

  return DateTime.fromJSDate(date).toFormat(format)
}

export const sortByDate = function <T>(array: T[], keyWithDate: keyof T) {
  return array.sort(function (a, b) {
    return a[keyWithDate] < b[keyWithDate] ? -1 : a[keyWithDate] > b[keyWithDate] ? 1 : 0
  })
}
