import { ReactNode } from 'react'

import { RCFC } from 'utils/types'
import { LoaderWithDescription } from 'components/loader-with-description'

import { AsyncError, AsyncErrorProps } from './async-error/async-error'

interface AsyncDataProps extends AsyncErrorProps {
  isLoading: boolean
  loadingTitle?: string
  loadingDescription?: string
  /** Render children if has error */
  forceRender?: boolean
  /** Use only when has single error */
  additionalErrorInfo?: ReactNode
  skeleton?: ReactNode
}

export const AsyncData: RCFC<AsyncDataProps> = ({
  isLoading,
  loadingDescription,
  loadingTitle = 'Loading...',
  error,
  children,
  forceRender,
  additionalErrorInfo,
  skeleton,
  ...rest
}) => {
  if (isLoading) {
    if (skeleton) {
      return <>{skeleton}</>
    }

    return <LoaderWithDescription title={loadingTitle} description={loadingDescription} />
  }

  if (error) {
    return (
      <>
        <AsyncError error={error} {...rest}>
          {additionalErrorInfo}
        </AsyncError>
        {forceRender && <>{children}</>}
      </>
    )
  }

  return <>{children}</>
}
