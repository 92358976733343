import { useRouter } from 'next/router'

import { cn } from 'utils'
import { Dots } from 'components/pagination/pagination-item/dots'
import { DOTS, usePagination } from 'utils/use-pagination'
import { Arrow } from 'components/pagination/pagination-item/arrow'
import { withNoSsr } from 'components/no-ssr'

import styles from './pagination.module.css'
import { withPage } from './utils'
import { PaginationItem } from './pagination-item'

type Props = {
  page: number
  pageCount: number
  pageParamName?: string
  className?: string
  classNameItem?: string
  margin?: number
  shallow?: boolean
}

const _Pagination = ({
  page,
  pageCount,
  pageParamName,
  margin = 1,
  className,
  classNameItem,
  shallow,
}: Props) => {
  const router = useRouter()
  const pagination = usePagination({
    currentPage: page,
    totalPageCount: pageCount,
    siblingCount: margin,
  })
  const prev = page - 1 >= 1 ? withPage(router.asPath, page - 1, pageParamName) : null
  const next = page + 1 <= pageCount ? withPage(router.asPath, page + 1, pageParamName) : null

  if (!pagination) return null

  return (
    <nav
      role="navigation"
      aria-label="Pagination Navigation"
      className={cn(styles.pagination, className)}
    >
      <ol>
        <Arrow key="prev" href={prev} aria-label="Previous page" isNext={false} shallow={shallow} />
        {pagination.map((paginationPage, index) => {
          if (paginationPage === DOTS) {
            return <Dots key={`dot-${index}`} />
          }

          return (
            <PaginationItem
              page={paginationPage}
              pageParamName={pageParamName}
              key={paginationPage}
              isActive={paginationPage === page}
              className={classNameItem}
              shallow={shallow}
            />
          )
        })}
        <Arrow key="next" href={next} aria-label="Next page" isNext={true} shallow={shallow} />
      </ol>
    </nav>
  )
}

export const Pagination = withNoSsr(_Pagination)
