import { NestController } from 'api/nest/NestController'
import { PropertyCreateModel, PropertyModel } from 'api/nest/properties/types'

export class PropertiesController extends NestController {
  constructor() {
    super({ controller: 'properties' })
  }

  public create = async (data: PropertyCreateModel) => {
    return await this.post<PropertyModel>('/', data)
  }

  public getByUuid = (uuid: string) => {
    return this.get<PropertyModel>(`/${uuid}`)
  }

  public update = (uuid: string, newData: Partial<PropertyModel>) => {
    return this.patch<PropertyModel>(`/${uuid}`, newData)
  }
}
