import { FC } from 'react'

import { cn } from 'utils/cn'

import { TextFormField } from './TextFormField'
import { ExtendTextFieldProps } from './types'
import { FORM_FIELD_LABEL, MAX_EMAIL_LENGTH } from '../consts'

export const EmailFormField: FC<ExtendTextFieldProps> = ({ autoCompleteGroup, ...props }) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.email}
      type="email"
      autoComplete={cn(autoCompleteGroup, 'email')}
      maxLength={MAX_EMAIL_LENGTH}
      {...props}
    />
  )
}
