import { FC } from 'react'
import { useRouter } from 'next/router'

import { NavLink } from 'components/links'
import classes from 'components/pagination/pagination-item/pagination-item.module.css'
import { cn } from 'utils'

import { withPage } from '../utils'

interface PaginationLinkProps {
  page: number
  pageParamName?: string
  isActive: boolean
  className?: string
  shallow?: boolean
}

export const PaginationItem: FC<PaginationLinkProps> = ({
  page,
  pageParamName,
  isActive,
  className,
  shallow,
}) => {
  const router = useRouter()

  return (
    <li className={cn(classes.item, className)}>
      <NavLink
        href={withPage(router.asPath, page, pageParamName)}
        aria-label={`Goto page ${page}`}
        className={classes.link}
        activeClassName={classes.activeClassName}
        forceActive={isActive}
        scroll={true}
        shallow={shallow}
      >
        {page}
      </NavLink>
    </li>
  )
}
