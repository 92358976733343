import { useRef } from 'react'

import { ConfigurationFlowStorage } from './use-flow-storage.types'
import { useSessionStorage } from './use-session-storage'

const DEFAULT_FLOW_STORAGE_VALUE = {} as ConfigurationFlowStorage

//NOTE: Don't use cookie for this storage because it has problem with speed of save data between steps. Sometimes we went to next step but the data had not been update yet.
export const useFlowStorage = () => {
  const [value, setValue] = useSessionStorage<ConfigurationFlowStorage>(
    'configurationFlow',
    DEFAULT_FLOW_STORAGE_VALUE,
  )

  const setValueWithTimestamp = useRef(
    (newValue: Omit<ConfigurationFlowStorage, 'lastUpdateTime'>) => {
      const valueWithTimestamp = {
        ...newValue,
        lastUpdateTime: new Date().getTime(),
      }

      setValue(valueWithTimestamp)
    },
  )

  return [value, setValueWithTimestamp.current] as const
}
