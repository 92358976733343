import { FC } from 'react'

import { ReferralStatus } from 'api/nest'
import { cn } from 'utils'

import classes from './status.module.css'

interface StatusProps {
  status: ReferralStatus
}

const CLASSES_BY_STATUS: Record<ReferralStatus, string | undefined> = {
  [ReferralStatus.paid]: classes.paid,
  [ReferralStatus.processing]: classes.processing,
  [ReferralStatus.pending]: classes.pending,
  [ReferralStatus.cancelled]: classes.cancelled,
}

export const Status: FC<StatusProps> = ({ status }) => {
  return <span className={cn(classes.status, CLASSES_BY_STATUS[status])}>{status}</span>
}
