import { ComponentProps, FC, memo } from 'react'

import { cn } from 'utils'
import { SubFooter } from 'components/footer'
import {
  PartnerPortalHeader,
  PartnerPortalHeaderProps,
} from 'components/header/partner-portal-header'
import { LinkButton } from 'components/links'
import styles from 'components/layout/layout.module.css'

import { NoSsr } from '../no-ssr'

export interface PartnerPortalLayoutProps extends PartnerPortalHeaderProps {
  className?: string
  classNameMain?: string
}

const _PartnerPortalLayout: FCRC<PartnerPortalLayoutProps> = ({
  className,
  children,
  classNameMain,
  ...headerProps
}) => {
  return (
    <div className={cn(styles.layout, className)}>
      <PartnerPortalHeader {...headerProps} />
      <main className={cn(styles.partnerMain, classNameMain)}>
        {children}
        {headerProps.backLink && (
          <NoSsr>
            <LinkButton
              className={styles.partnerBackMobileLink}
              theme="goBack"
              href={headerProps.backLink}
            >
              {headerProps.backLinkText}
            </LinkButton>
          </NoSsr>
        )}
      </main>
      <footer>
        <SubFooter className={styles.partnerFooter} />
      </footer>
    </div>
  )
}

export const PartnerPortalLayout =
  memo<ComponentProps<typeof _PartnerPortalLayout>>(_PartnerPortalLayout)
