import { createElement, forwardRef, HTMLProps, ReactHTML, ReactNode } from 'react'

import { cn } from 'utils'

import styles from './heading.module.css'

type HeadingTheme = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type HeadingAlign = 'center' | 'right'

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  align?: HeadingAlign
  children: ReactNode
  className?: string
  component?: keyof ReactHTML
  theme: HeadingTheme
}

export const Heading = forwardRef<HTMLElement, HeadingProps>(
  ({ className, theme, component = theme, align, ...props }, ref) => {
    return createElement(component, {
      ...props,
      className: cn(styles.heading, styles[`${theme}Theme`], styles[`${align}Align`], className),
      ref,
    })
  },
)

Heading.displayName = 'Heading'
