import { NestController } from 'api/nest/NestController'

import { CandidateCreateModel, CandidateModel } from './types'

export class Candidates extends NestController {
  constructor() {
    super({ controller: 'candidates' })
  }

  public create(data: CandidateCreateModel) {
    const formData = Object.keys(data).reduce((acc, key) => {
      const value = data[key]
      value && acc.append(key, value)
      return acc
    }, new FormData())

    return this.post<CandidateModel>('/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}
