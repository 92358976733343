import { memo, ReactNode, FC } from 'react'

import { cn } from 'utils'
import { HelpTooltip } from 'components/help-tooltip'

import classes from './label.module.css'

export interface LabelProps {
  className?: string
  classNameLabelText?: string
  htmlFor?: string
  fullWidth?: boolean
  children?: ReactNode | null
  optional?: boolean
  info?: ReactNode | null
  variation?: 'large'
}

const _Label: FC<LabelProps> = ({
  children,
  className,
  classNameLabelText,
  htmlFor,
  fullWidth,
  optional,
  info,
  variation,
}) => {
  if (!children) {
    return null
  }

  return (
    <label
      className={cn(classes.label, fullWidth && classes.fullWidth, className)}
      htmlFor={htmlFor}
    >
      <span className={cn(classes.labelText, variation && classes[variation], classNameLabelText)}>
        {children}
      </span>
      {optional && <span className={classes.optional}> (optional)</span>}
      {info && <HelpTooltip className={classes.helpInfo}>{info}</HelpTooltip>}
    </label>
  )
}

export const Label = memo(_Label)
