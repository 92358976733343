import { NestController } from 'api/nest/NestController'

import { SubscriptionCreatModel, SubscriptionModel } from './types'

export class Subscriptions extends NestController {
  constructor() {
    super({ controller: 'subscriptions' })
  }

  public create(data: SubscriptionCreatModel) {
    return this.post<SubscriptionModel>('/', data)
  }
}
