import { MouseEventHandler, FC } from 'react'

import { IconButton } from 'components/icon-button'
import { DIALOG_NAME } from 'const/dialogs'
import { useControlRouterDialog } from 'components/dialog/utils'
import CloseIconSVG from 'icons/close.svg'
import { cn } from 'utils'

import styles from './dialog.module.css'

interface CloseDialogButtonProps {
  onClose: () => void
  className?: string
  withBeforeText?: boolean
}

export const CloseDialogButton: FC<CloseDialogButtonProps> = ({
  onClose,
  className,
  withBeforeText = true,
}) => {
  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  return (
    <IconButton
      onClick={onClick}
      className={cn(styles.closeIcon, className)}
      beforeText={withBeforeText ? 'Close' : ''}
    >
      <CloseIconSVG />
    </IconButton>
  )
}

interface CloseRouteDialogButtonProps {
  dialogName: DIALOG_NAME
  className?: string
}

export const CloseRouteDialogButton: FC<CloseRouteDialogButtonProps> = ({
  dialogName,
  className,
}) => {
  const { close } = useControlRouterDialog()

  return <CloseDialogButton onClose={() => close(dialogName)} className={className} />
}
