import { useQueryClient } from '@tanstack/react-query'
import { memo, useCallback, useMemo, FC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { nest } from 'api'
import { MarketModel, ReferralModel } from 'api/nest'
import { DIALOG_NAME as COMMON_DIALOG_NAME } from 'const/dialogs'
import { useDialogContext } from 'components/dialog/utils'
import { QUERY_KEY } from 'const/query'

import { ReferralFormModel } from '../referral-form/types'
import { ReferralForm } from '../referral-form'

interface AddReferralProps {
  markets: MarketModel[]
  editReferral: ReferralModel
}

const DIALOG_NAME = COMMON_DIALOG_NAME.dashboardEditReferral

export const _EditReferral: FC<AddReferralProps> = ({ markets, editReferral }) => {
  const { close } = useDialogContext()
  const queryClient = useQueryClient()

  const onSubmit: SubmitHandler<ReferralFormModel> = useCallback(
    async (values) => {
      await nest.referrals.update({ uuid: editReferral.uuid, ...values })
      queryClient.invalidateQueries([QUERY_KEY.referrals])
      close()
      toast.success('Referral updated')
    },
    [close, editReferral.uuid, queryClient],
  )

  const defaultValues = useMemo((): Partial<ReferralFormModel> => {
    if (editReferral) {
      const { market, phone, email, firstName, lastName, postalCode, homeValue } = editReferral

      return {
        homeValue,
        email,
        firstName,
        lastName,
        phone,
        marketUuid: market.uuid,
        postalCode,
      }
    }
    return {}
  }, [editReferral])

  return (
    <ReferralForm
      onSubmit={onSubmit}
      markets={markets}
      dialogName={DIALOG_NAME}
      update
      defaultValues={defaultValues}
    />
  )
}

export const EditReferral = memo<AddReferralProps>(_EditReferral)
