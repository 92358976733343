import { AxiosRequestConfig } from 'axios'

import { NestController } from '../NestController'
import { FindAllResponseModel } from '../NestCollection'
import { AgentModel, ICreateOfficerPayload, IMortgageCompany } from './types'

export class MortgageCompanyController extends NestController {
  constructor() {
    super({ controller: 'mortgage' })
  }

  getOfficers = (query?: AxiosRequestConfig['params'], config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<AgentModel>>('/officers', query, config)
  }

  createOfficer = async (data: ICreateOfficerPayload) => {
    return await this.post<AgentModel>('/officers', data)
  }

  getOfficersOfSpecificCompany = (
    companyUuid: string,
    query?: AxiosRequestConfig['params'],
    config?: AxiosRequestConfig,
  ) => {
    return this.get<FindAllResponseModel<AgentModel>>(
      `/companies/${companyUuid}/officers`,
      query,
      config,
    )
  }

  getCompany = (query?: AxiosRequestConfig['params'], config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<IMortgageCompany>>('/companies', query, config)
  }

  createCompany = async (data: { name: string }) => {
    return await this.post<IMortgageCompany>('/companies', data)
  }
}
