import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'

export interface ISigningUrlData {
  isSigned: boolean
  url: string
  uuid: string
}

export class AgreementsController extends NestController {
  constructor() {
    super({ controller: 'agreements' })
  }

  public getPreviewAgreementHtml = (orderUuid: string, options?: AxiosRequestConfig) => {
    return this.get<string>(`/${orderUuid}/html`, options)
  }

  public getPreviewAgreementPdfUrl = (orderUuid: string) => {
    return `${this.request.defaults.baseURL}/${orderUuid}/preview`
  }

  public getSigningUrl = (orderUuid: string, options?: AxiosRequestConfig) => {
    return this.get<ISigningUrlData>(`/${orderUuid}/signing-url`, options)
  }
}
