import { useRef, useState } from 'react'
import { useEffectOnce, useToggle } from 'react-use'

import { cn } from 'utils'
import { BaseHeader } from 'components/header/base-header'
import { useCallbackOnce } from 'utils/use-callback-once'
import { BuyNowDialog } from 'components/buy-now-dialog'

import classes from './header.module.css'
import { DefaultHeaderTemplate } from './default-header-template'

const emptyFunction = () => {}
export const Header = () => {
  const [isOpenMenu, toggleMenu] = useToggle(false)
  const [isSmallHeader, setIsSmallHeader] = useState(false)
  const headerElement = useRef<HTMLHeadingElement>(null)

  useEffectOnce(() => {
    if (headerElement.current) {
      const observeHandler: IntersectionObserverCallback = (entries) => {
        setIsSmallHeader(!entries[0]!.isIntersecting)
      }

      const observer = new IntersectionObserver(observeHandler, {
        threshold: 0.5,
      })
      observer.observe(window.document.body)

      return () => {
        observer.disconnect()
      }
    }
  })

  const closeMenu = useCallbackOnce(() => {
    toggleMenu(false)
  })

  return (
    <>
      <BaseHeader
        className={cn(classes.headerContent, isSmallHeader && classes.scrolled)}
        ref={headerElement}
      >
        <DefaultHeaderTemplate
          onToggleMenu={toggleMenu}
          isOpenMenu={isOpenMenu}
          onCloseMenu={closeMenu}
        />
      </BaseHeader>

      <BaseHeader className={cn(classes.headerContent, classes.static)}>
        <DefaultHeaderTemplate
          onToggleMenu={emptyFunction}
          onCloseMenu={emptyFunction}
          isOpenMenu={isOpenMenu}
        />
      </BaseHeader>

      <BuyNowDialog classes={{ body: classes.dialogBody }} />
    </>
  )
}
