import { FC } from 'react'

import { Link } from 'components/links'
import { cn } from 'utils'

import ArrowIcon from './arrow.svg'
import classes from './pagination-item.module.css'

interface ArrowProps {
  href: string | null
  isNext: boolean
  shallow?: boolean
}

export const Arrow: FC<ArrowProps> = ({ href, isNext, shallow }) => {
  const isDisabled = href === null

  const onClick = (e) => {
    if (isDisabled) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <li className={cn(classes.item, classes.arrowItem)}>
      <Link
        href={href || ''}
        onClick={onClick}
        scroll={false}
        shallow={shallow}
        className={cn(classes.link, isNext && classes.nextArrow, isDisabled && classes.disabled)}
      >
        <ArrowIcon className={classes.arrow} />
      </Link>
    </li>
  )
}
