import { memo, FC } from 'react'

import { cn } from 'utils'
import { OrderModel } from 'api/nest/orders/types'

import { ActionMenuProps, Actions } from './actions'
import { GRID_COLUMNS } from './consts'
import classes from './orders-grid.module.css'

interface GridRowProps {
  order: OrderModel
  onAction: ActionMenuProps['onClick']
}

const _GridRow: FC<GridRowProps> = ({ order, onAction }) => {
  return (
    <div className={classes.gridRow}>
      {GRID_COLUMNS.map((column) => (
        <div className={cn(classes.gridItem, column.className)} key={column.dataKey}>
          {column.dataKey === 'actions' && <Actions order={order} onAction={onAction} />}
          {column.dataKey !== 'actions' && column.render
            ? column.render(order)
            : order[column.dataKey]}
        </div>
      ))}
    </div>
  )
}

export const GridRow = memo<GridRowProps>(_GridRow)
