import React, { FC } from 'react'
import { useRouter } from 'next/router'

import { ORIGIN_URL } from 'const/config'
import { cn } from 'utils/cn'

import { Link, LinkProps } from './Link'

export interface NavLinkProps extends LinkProps {
  activeClassName?: string
  forceActive?: boolean
}

export const NavLink: FC<NavLinkProps> = ({
  activeClassName,
  className,
  children,
  forceActive,
  ...props
}) => {
  const { asPath } = useRouter()
  const isActive = forceActive || isMatch(asPath, props.href)

  const ariaLabel = props['aria-label']
    ? [props['aria-label'], isActive && 'current page'].filter(Boolean).join(', ')
    : undefined

  return (
    <Link
      aria-current={isActive ? 'true' : undefined}
      aria-label={ariaLabel}
      className={cn(className, isActive && activeClassName)}
      {...props}
    >
      {children}
    </Link>
  )
}

function isMatch(current: string, target: string): boolean {
  const currentUrl = new URL(current, ORIGIN_URL)
  const targetUrl = new URL(target, ORIGIN_URL)

  if (currentUrl.pathname !== targetUrl.pathname) return false

  return Array.from(targetUrl.searchParams.entries()).every(([param, value]) => {
    if (!currentUrl.searchParams.has(param)) return false
    //TODO: to handle arrays or not to handle? That is a question...
    return currentUrl.searchParams.get(param) === value
  })
}
