import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'

const isBlocked = () => {
  const body = document.body
  return body.style.overflow === 'hidden'
}

const block = () => {
  const body = document.body

  const scrollOfTop = window.pageYOffset
  body.setAttribute('scrollOfTop', String(scrollOfTop))

  body.style.overflow = 'hidden'
  body.style.position = 'fixed'
  body.style.top = `-${scrollOfTop}px`
  body.style.width = '100%'
}

const unblock = () => {
  const body = document.body

  const scrollOfTop = Number(body.getAttribute('scrollOfTop'))

  body.style.removeProperty('overflow')
  body.style.removeProperty('position')
  body.style.removeProperty('top')
  body.style.removeProperty('width')
  body.removeAttribute('scrollOfTop')
  window.scrollTo(0, scrollOfTop)
}

export const useControlBodyScroll = () => {
  const router = useRouter()

  useEffectOnce(() => {
    /**
     * If we do redirect from page where body blocked so in next page would be wrong scroll position.
     * This code fix it
     */
    const handleRouteChange = (url, { shallow }) => {
      if (shallow) return
      if (isBlocked()) {
        return unblock()
      }
    }
    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  })

  return { block, unblock, isBlocked }
}

export const useLockBodyScroll = () => {
  const { unblock, block } = useControlBodyScroll()

  useEffectOnce(() => {
    block()

    return unblock
  })
}
