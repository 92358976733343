import { DateTime } from 'luxon'
import { ObjectSchema } from 'yup'

import { yup, YUP_ERROR_MESSAGE } from 'components/form/yup'
import { bytesToMb } from 'utils'
import { BusinessAddressRegistrationModel } from 'api/nest/addresses'

import {
  CC_CVV_LENGTH,
  COMPANY_NAME,
  CREDIT_CARD_EXPIRATION_DATE,
  CREDIT_CARD_NAME_MAX_LENGTH,
  CREDIT_CARD_NAME_MIN_LENGTH,
  CREDIT_CARD_NUMBER,
  LINKEDIN_PROFILE,
  MAX_ADDRESS_LENGTH,
  MAX_CITY_NAME_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MAX_FULL_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_REFERRAL_CODE_LENGTH,
  MAX_ZIP_CODE_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_FULL_NAME_LENGTH,
  MIN_LAST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  NEW_PASSWORD_QUALITY_RULE,
  SSN,
  MIN_PHONE_NUMBER_LENGTH,
  SessionStorageKeysEnum,
} from './consts'
import { IPhoneNumberValidationData } from './TextFormField/types'

export const THE_OLDEST_PERSON_AGE = 120
export const LEGAL_AGE_IN_USA = 18

export const ZIP_CODE_RULE = yup.string().required().length(MAX_ZIP_CODE_LENGTH)
export const FIRST_NAME_RULE = yup
  .string()
  .required()
  .trim()
  .min(MIN_FIRST_NAME_LENGTH)
  .max(MAX_FIRST_NAME_LENGTH)
export const LAST_NAME_RULE = yup
  .string()
  .trim()
  .required()
  .min(MIN_LAST_NAME_LENGTH)
  .max(MAX_LAST_NAME_LENGTH)
export const FULL_NAME_RULE = yup
  .string()
  .required()
  .trim()
  .min(MIN_FULL_NAME_LENGTH)
  .max(MAX_FULL_NAME_LENGTH)
  .test((value, { createError }) => {
    const hasSpace = Boolean(value && value.trim().includes(' '))

    if (!hasSpace) {
      return createError({
        message: 'Full name must contain first and last name',
      })
    }
    return true
  })
export const STATE_RULE = yup.string()
export const CITY_NAME_RULE = yup.string().max(MAX_CITY_NAME_LENGTH)
export const EMAIL_RULE = yup
  .string()
  .required()
  .email()
  .min(MIN_EMAIL_LENGTH)
  .max(MAX_EMAIL_LENGTH)
export const PHONE_RULE = yup
  .string()
  .required()
  .test((value, { createError }) => {
    try {
      const data = sessionStorage.getItem(SessionStorageKeysEnum.phoneInputValidationData)
      if (data) {
        const errors: string[] = []
        const { isWhitelistedCountry, requiredLength }: IPhoneNumberValidationData =
          JSON.parse(data)
        if (!isWhitelistedCountry && value.length > 1) {
          errors.push(`Chosen country code is not supported.`)
        }

        if (isWhitelistedCountry && requiredLength && value.length !== requiredLength) {
          errors.push(`Please enter a valid US phone number with ${requiredLength} digits.`)
        } else if (value.length < MIN_PHONE_NUMBER_LENGTH) {
          errors.push(`Minimum number length is ${MIN_PHONE_NUMBER_LENGTH} characters.`)
        }

        if (errors.length) {
          return createError({
            message: errors.join(' '),
          })
        }
      }

      return true
    } catch (e) {
      return false
    }
  })

interface FileRuleOptions {
  /** megabyte*/
  maxFileSize?: number
}

export const fileRule = ({ maxFileSize }: FileRuleOptions) => {
  let validator = yup.mixed()

  if (maxFileSize) {
    validator = validator.test(
      'fileSize',
      `File size is too large. Max size ${maxFileSize} MB`,
      (value) => {
        // @ts-ignore
        return !(value && bytesToMb(value.size) > maxFileSize)
      },
    )
  }
  return validator
}

export const LINKEDIN_OPTIONAL_RULE = yup.string().when({
  is: function (val: unknown) {
    return typeof val === 'string' && val.replace(new RegExp(`^${LINKEDIN_PROFILE.url}`, 'gi'), '')
  },
  then: (schema) =>
    schema
      .min(
        LINKEDIN_PROFILE.minLengthWithMask,
        `Nickname path must be at least ${LINKEDIN_PROFILE.minLength} characters`,
      )
      .max(LINKEDIN_PROFILE.maxLength),
})

export const SOCIAL_NUMBER_RULE = yup.string().test((value, { createError }) => {
  if (value) {
    const valueWithoutFormatting = value.replace(/\D/g, '')

    if (valueWithoutFormatting.length !== SSN.length) {
      return createError({
        message: YUP_ERROR_MESSAGE.string?.length,
        params: { length: SSN.length },
      })
    }
  }

  return true
})
export const BIRTHDAY_RULE = yup
  .date()
  .typeError('Please enter a valid date')
  .max(
    DateTime.now().minus({ year: LEGAL_AGE_IN_USA }).toJSDate(),
    `You must be ${LEGAL_AGE_IN_USA} years old or over`,
  )
  .min(
    DateTime.now().minus({ year: THE_OLDEST_PERSON_AGE }).toJSDate(),
    `The oldest person ${THE_OLDEST_PERSON_AGE} years old. Please enter a valid date`,
  )
export const ADDRESS_RULE = yup.string().max(MAX_ADDRESS_LENGTH)

export const CC_NUMBER_RULE = yup.string().length(CREDIT_CARD_NUMBER.length)
export const CC_NAME_RULE = yup
  .string()
  .min(CREDIT_CARD_NAME_MIN_LENGTH)
  .max(CREDIT_CARD_NAME_MAX_LENGTH)
export const CC_CVV_RULE = yup.string().length(CC_CVV_LENGTH)
export const CC_EXPIRATION_DATE = yup.string().test((value, { createError }) => {
  if (value) {
    const DEFAULT_ERROR_MESSAGE = 'Please enter a valid date by format MM/YY'
    if (value.length !== CREDIT_CARD_EXPIRATION_DATE.formattedLength) {
      return createError({
        message: DEFAULT_ERROR_MESSAGE,
      })
    }
  }

  return true
})

export const COMPANY_REFERRAL_CODE_RULE_REQUIRED = yup
  .string()
  .required()
  .uuid('Please enter a valid referral code')
export const COMPANY_REFERRAL_CODE_RULE_OPTIONAL = yup.string().optional()
export const REFERRAL_CODE_RULE = yup.string().max(MAX_REFERRAL_CODE_LENGTH)
export const NEW_PASSWORD_RULE = yup
  .string()
  .required()
  .min(MIN_PASSWORD_LENGTH)
  .max(MAX_PASSWORD_LENGTH)
  .test((value, { createError }) => {
    let error: string | undefined
    for (let i = 0; i < NEW_PASSWORD_QUALITY_RULE.length; i++) {
      const rule = NEW_PASSWORD_QUALITY_RULE[i]!
      const ruleRegexp = rule.testRegexp

      ruleRegexp.lastIndex = 0 // reset regexp
      if (!ruleRegexp.test(value!)) {
        error = rule.errorMessage
        break
      }
    }

    if (error) {
      return createError({
        message: error,
      })
    }

    return true
  })
export const COMPANY_NAME_RULE = yup
  .string()
  .required()
  .min(COMPANY_NAME.minLength)
  .max(COMPANY_NAME.maxLength)
export const MESSAGE_RULE = yup.string()
export const CURRENT_PASSWORD_RULE = yup.string()
export const BANK_HOLDER_NAME_RULE = yup.string()
export const BANK_ROUTING_NUMBER_RULE = yup.string().trim()
export const BANK_NAME_RULE = yup.string()
export const BANK_ACCOUNT_NUMBER_RULE = yup.string()
//https://plaid.com/resources/banking/account-numbers-explained/#what-is-an-account-number

export const BUSINESS_ADDRESS_CREATE_SCHEMA: ObjectSchema<BusinessAddressRegistrationModel> =
  yup.object({
    postalCode: ZIP_CODE_RULE,
  })
