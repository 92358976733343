import { useQueryClient } from '@tanstack/react-query'
import { memo, useCallback, FC } from 'react'
import { toast } from 'react-toastify'

import { nest } from 'api'
import { MarketModel } from 'api/nest'
import { DIALOG_NAME } from 'const/dialogs'
import { QUERY_KEY } from 'const/query'
import { ReferralFormProps } from 'sections/pp-dashboard-page/referral-form/referral-form'
import { useDialogContext } from 'components/dialog/utils'

import { ReferralForm } from '../referral-form'

interface AddReferralProps {
  markets: MarketModel[]
}

const _AddReferral: FC<AddReferralProps> = ({ markets }) => {
  const { close } = useDialogContext()
  const queryClient = useQueryClient()

  const onSubmit: ReferralFormProps['onSubmit'] = useCallback(
    async (values) => {
      await nest.referrals.create(values)
      queryClient.invalidateQueries([QUERY_KEY.referrals])
      toast.success('Referral created successfully and added the top of the list')
      close()
    },
    [close, queryClient],
  )

  return (
    <ReferralForm
      onSubmit={onSubmit}
      markets={markets}
      dialogName={DIALOG_NAME.dashboardNewReferral}
    />
  )
}

export const AddReferral = memo<AddReferralProps>(_AddReferral)
