import { NestController } from 'api/nest/NestController'
import {
  ChargeAuthNetPaymentRequestModel,
  ChargePaymentResponseModel,
  BoaContextPayloadRequestModel,
  ChargeBoaPaymentRequestModel,
} from 'api/nest/payment/types'

export class PaymentController extends NestController {
  constructor() {
    super({ controller: 'payment' })
  }

  public chargeAuthNet = async (data: ChargeAuthNetPaymentRequestModel) => {
    return await this.post<ChargePaymentResponseModel>('/authorize/charge', data)
  }

  public getBoaContext = async (data: BoaContextPayloadRequestModel) => {
    return await this.post<string>('/boa/context', data)
  }

  public chargeBoa = async (data: ChargeBoaPaymentRequestModel) => {
    return await this.post<ChargePaymentResponseModel>('/boa/charge', data)
  }
}
