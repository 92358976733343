import { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Form, useForm } from 'components/form'
import {
  CheckboxFormField,
  EmailFormField,
  FirstNameFormField,
  LastNameFormField,
  SelectFormField,
  ZipCodeFormField,
} from 'components/form-field'
import { SubmitFormButton } from 'components/submit-form-button'
import { I_AM_TYPE_SELECT_OPTIONS } from 'const/i-am-options'

import { SUBSCRIBE_FIELDS_NAME, SUBSCRIBE_VALIDATOR } from './consts'
import { ISubscribeForm } from './types'
import classes from './subscribe-form.module.css'
import { nest } from '../../../api'

export const SubscribeForm = () => {
  const formMethods = useForm(SUBSCRIBE_VALIDATOR, {
    reValidateMode: 'onChange',
  })

  const onSubmit: SubmitHandler<ISubscribeForm> = async (data) => {
    await nest.subscriptions.create(data)
    toast.success('Your message has been sent! You can subscribe to another zip code')
    formMethods.resetField(SUBSCRIBE_FIELDS_NAME.postalCode)
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit} className={classes.form}>
      <EmailFormField
        className={classes.email}
        name={SUBSCRIBE_FIELDS_NAME.email}
        label={null}
        fullWidth
        placeholder="Your email address"
      />
      <FirstNameFormField
        className={classes.fName}
        name={SUBSCRIBE_FIELDS_NAME.firstName}
        label={null}
        fullWidth
        placeholder="Your first name"
      />
      <LastNameFormField
        className={classes.lName}
        name={SUBSCRIBE_FIELDS_NAME.lastName}
        label={null}
        fullWidth
        placeholder="Your last name"
      />
      <ZipCodeFormField
        className={classes.state}
        name={SUBSCRIBE_FIELDS_NAME.postalCode}
        label={null}
        fullWidth
        placeholder="Your zip code"
      />
      <SelectFormField
        options={I_AM_TYPE_SELECT_OPTIONS}
        name={SUBSCRIBE_FIELDS_NAME.whoAmI}
        label={null}
        fullWidth
        placeholder="I am a..."
        aria-label="I am a..."
      />
      <CheckboxFormField
        name={SUBSCRIBE_FIELDS_NAME.agree}
        className={classes.agree}
        verticalAlign="start"
      >
        I consent to receive occasional email communications from REZITRADE and acknowledge that I
        can unsubscribe at any time.
      </CheckboxFormField>
      <SubmitFormButton theme="primaryLight" className={classes.submit}>
        Subscribe
      </SubmitFormButton>
    </Form>
  )
}
