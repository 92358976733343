import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import {
  InvestorAchModel,
  InvestorCreateModel,
  InvestorGetCreditCardModel,
  InvestorModel,
  InvestorUpdateModel,
} from 'api/nest/investor/types'

export class InvestorController extends NestController {
  constructor() {
    super({ controller: 'investors' })
  }

  public getByUuid = (uuid: string, options?: AxiosRequestConfig) => {
    return this.get<InvestorModel>(`/${uuid}`, options)
  }

  public update = (uuid: string, newData: InvestorUpdateModel, options?: AxiosRequestConfig) => {
    const mailingAddress = newData.mailingAddress
      ? {
          ...newData.mailingAddress,
          // optional street2 and region props must default to empty strings for controlled form inputs; send null values to backend to avoid length validation errors
          street2: newData.mailingAddress?.street2 === '' ? null : newData.mailingAddress.street2,
          region: newData.mailingAddress?.region === '' ? null : newData.mailingAddress.region,
        }
      : undefined

    return this.patch<InvestorModel>(
      `/${uuid}`,
      {
        ...newData,
        mailingAddress,
      },
      options,
    )
  }

  public create = async (data: InvestorCreateModel, isValidationRequired = false) => {
    const mailingAddress = data.mailingAddress
      ? {
          ...data.mailingAddress,
          // optional street2 and region props must default to empty strings for controlled form inputs; send null values to backend to avoid length validation errors
          street2: data.mailingAddress?.street2 === '' ? null : data.mailingAddress.street2,
          region: data.mailingAddress?.region === '' ? null : data.mailingAddress.region,
        }
      : undefined

    return await this.post<InvestorModel>('/', {
      ...data,
      mailingAddress,
      isValidationRequired,
    })
  }

  public getAch = (uuid: string, options?: AxiosRequestConfig) => {
    return this.get<InvestorAchModel>(`/${uuid}/ach`, options)
  }

  public confirmEmail = (email: string, options?: AxiosRequestConfig) => {
    return this.post<InvestorModel>(`/confirm-email`, { email }, options)
  }

  /* public checkEmail = (email: string, options?: AxiosRequestConfig) => {
    return this.post<InvestorModel>(`/check-email`, { email }, options)
  }*/

  public plaidSuccess = (uuid: string) => {
    return this.post<InvestorModel>(`/${uuid}/plaid-success`)
  }

  public getCreditCard = (uuid: string, options?: AxiosRequestConfig) => {
    return this.get<InvestorGetCreditCardModel>(`/${uuid}/credit-card`, options)
  }

  public getPaymentInfo = async (uuid: string) => {
    return Promise.allSettled([this.getAch(uuid), this.getCreditCard(uuid)])
  }

  public isValidated = (email: string) => {
    return this.post<InvestorModel>('is-validated', { email })
  }

  public getByEmail = (email: string, options?: AxiosRequestConfig) => {
    return this.get<InvestorModel>(`/email`, { email }, options)
  }

  public getByToken = (token: string, options?: AxiosRequestConfig) => {
    return this.get<InvestorModel>(`/token`, { token }, options)
  }

  public validate = ({
    uuid,
    ...params
  }: {
    uuid: string
    token: string
    isActivationLink?: boolean
  }) => {
    return this.post<'Success'>(`/${uuid}/validate`, params)
  }
}
