import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SearchIcon from 'icons/search.svg'
import CloseIconSVG from 'icons/close.svg'
import { cn } from 'utils'
import { SearchFieldsEnum } from 'sections/pp-dashboard-page/search-field/search-field'

import { TextFormField } from './TextFormField'
import { ExtendTextFieldProps } from './types'
import classes from './search-form-field.module.css'

interface SearchFormFieldProps extends ExtendTextFieldProps {
  classNameIcon?: string
  formMethods?: UseFormReturn<
    {
      search: string
    },
    any
  >
}

export const SearchFormField: FC<SearchFormFieldProps> = ({
  classNameInput,
  classNameIcon,
  className,
  formMethods,
  name,
  ...props
}) => {
  return (
    <TextFormField
      name={name}
      type="search"
      autoComplete="off"
      before={<SearchIcon className={cn(classes.searchIcon, classNameIcon)} />}
      after={
        <CloseIconSVG
          className={classes.clearIcon}
          onClick={() => formMethods?.setValue(name as SearchFieldsEnum.search, '')}
        />
      }
      {...props}
      classNameInput={cn(classes.searchInput, className)}
      className={cn(classes.searchInputContainer, className)}
      maxLength={50}
    />
  )
}
