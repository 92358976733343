import { useFormContext } from 'react-hook-form'
import { FC } from 'react'

import { IS_DEVELOPMENT_MODE } from 'const/config'
import { AsyncError } from 'components/async-data'

interface FormGeneralErrorProps {
  className?: string
  /**
   * Need only for layout debug;
   */
  forceShow?: boolean
  fullWidth?: boolean
  /**
   * Show contact to support text;
   * @default false
   */
  withContactUs?: boolean
}

export const GENERAL_FORM_ERROR_NAME = 'general'

export const FormGeneralError: FC<FormGeneralErrorProps> = ({
  className,
  forceShow,
  fullWidth,
  withContactUs = false,
}) => {
  const { getFieldState } = useFormContext()
  const generalError = getFieldState(GENERAL_FORM_ERROR_NAME).error?.message

  const forceShowError = IS_DEVELOPMENT_MODE && forceShow

  if (generalError || forceShowError) {
    return (
      <AsyncError
        className={className}
        fullWidth={fullWidth}
        error={generalError}
        withContactUs={withContactUs}
        errorParser={(error: string) => error}
      />
    )
  }

  return null
}
