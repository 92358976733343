import { FC } from 'react'
import NextImage from 'next/image'

import { cn } from 'utils'

import DefaultAvatar from './default.svg'
import classes from './avatar.module.css'

interface AvatarProps {
  src?: string | null
  alt?: string
  className?: string
  classNameDefaultAvatar?: string
  onClick?: () => void
  size?: number
  rounded?: boolean
}

export const Avatar: FC<AvatarProps> = ({
  src,
  onClick,
  size,
  rounded,
  className,
  classNameDefaultAvatar,
}) => {
  return (
    <span
      style={{ width: `${size}px` }}
      className={cn(
        classes.avatar,
        onClick && classes.clickableAvatar,
        rounded && classes.rounded,
        className,
      )}
      onClick={onClick}
    >
      {src ? (
        <NextImage src={src} width={size} height={size} alt="Avatar" className={classes.image} />
      ) : (
        <DefaultAvatar className={cn(classes.defaultAvatar, classNameDefaultAvatar)} />
      )}
    </span>
  )
}
