import { FC, useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'

export type HiddenFormFieldProps = {
  name: string
  value?: boolean | string
}

export const HiddenFormField: FC<HiddenFormFieldProps> = ({ name, value }) => {
  const { field } = useController({
    name,
  })

  const { setValue } = useFormContext()

  useEffect(() => {
    if (field.value === undefined && value !== undefined) {
      setValue(name, value, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
    }
  }, [field, setValue, name, value])

  return <input {...field} value={field.value || value} type="hidden" />
}
