import { useMemo, FC } from 'react'
import { DateTime } from 'luxon'

import { cn } from 'utils'
import { dateStringToDateInputValue } from 'utils/date'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL } from '../consts'
import { LEGAL_AGE_IN_USA, THE_OLDEST_PERSON_AGE } from '../validators'

export const BirthdayFormField: FC<ExtendTextFieldProps> = ({ autoCompleteGroup, ...props }) => {
  const minDate = useMemo(
    () =>
      dateStringToDateInputValue(DateTime.now().minus({ year: THE_OLDEST_PERSON_AGE }).toJSDate()),
    [],
  )

  const maxDate = useMemo(
    () => dateStringToDateInputValue(DateTime.now().minus({ year: LEGAL_AGE_IN_USA }).toJSDate()),
    [],
  )

  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.dayOfBirth}
      autoComplete={cn(autoCompleteGroup, 'bday')}
      type="date"
      defaultValueTransformer={dateStringToDateInputValue}
      min={minDate}
      max={maxDate}
      {...props}
    />
  )
}
