import { FC } from 'react'
import { IMask } from 'react-imask'

import { ExtendMaskerFieldProps } from 'components/form-field/TextFormField/types'
import { cn } from 'utils'
import { MaskedTextFormField } from 'components/form-field/TextFormField/MaskedTextFormField'

import styles from './currency-form-field.module.css'

const Addon: FC = () => <span className={styles.addon} />

const mask = IMask.createMask({
  mask: Number,
  scale: 0, // digits after point, 0 for integers
  signed: false, // disallow negative
  thousandsSeparator: `,`, // any single char
})

export type CurrencyFormFieldProps = ExtendMaskerFieldProps & { valueAsNumber?: boolean }
export const CurrencyFormField: FC<CurrencyFormFieldProps> = ({
  className,
  classNameInputWrapper,
  ...props
}) => {
  return (
    <MaskedTextFormField
      {...props}
      mask={mask}
      classNameInputWrapper={cn(styles.wrapper, classNameInputWrapper)}
      className={cn(styles.field, className)}
      before={<Addon />}
    />
  )
}
