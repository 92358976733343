import { useRef } from 'react'

import { Footer } from 'components/footer'
import { cn } from 'utils'
import { Header } from 'components/header'
import { NoSsr } from 'components/no-ssr'

import styles from './layout.module.css'

export interface LayoutProps {
  className?: string
}

export const Layout: FCRC<LayoutProps> = ({ className, children, ...props }) => {
  const mainElement = useRef<HTMLElement | null>(null)

  return (
    <div {...props} className={cn(styles.layout, className)}>
      <Header />
      <main className={styles.main} ref={mainElement}>
        {children}
      </main>
      <NoSsr>
        <Footer />
      </NoSsr>
    </div>
  )
}
