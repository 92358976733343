import { FC } from 'react'

import { ExtendTextFieldProps } from 'components/form-field/TextFormField/types'
import { BANK_ROUTING_NUMBER_LENGTH } from 'components/form-field/consts'
import { AnyNumberFormField } from 'components/form-field/TextFormField/AnyNumberFormField'

export const RoutingNumberFormField: FC<ExtendTextFieldProps> = (props) => {
  return (
    <AnyNumberFormField
      minLength={BANK_ROUTING_NUMBER_LENGTH}
      maxLength={BANK_ROUTING_NUMBER_LENGTH}
      {...props}
    />
  )
}
