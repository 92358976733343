import { OrderTerms } from 'api/nest/orders/types'

const BILLION = 1000000000
const MILLION = 1000000
const THOUSAND = 1000

export const percentFormatter = (value?: string | number, decimal = 2) =>
  value ? `${Number(value).toFixed(decimal)}%` : '0%'

export const percentOfNumber = (number: number, percent: number) => {
  return number * (percent / 100)
}

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
}

export const bytesToMb = (bytes: number) => {
  const BYTES_IN_MB = 1024 * 1024
  return bytes / BYTES_IN_MB
}

export const round = (value: number | string, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return (Math.round(Number(value) * multiplier) / multiplier).toFixed(precision)
}

interface usdFormatterOptions extends Intl.NumberFormatOptions {
  hideZeroDecimal?: boolean
}

export const usdFormatter = (
  value: number,
  { hideZeroDecimal, ...options }: usdFormatterOptions = {},
  fractionDigitsOptions?: {
    maximumFractionDigits: number
    minimumFractionDigits: number
  },
): string => {
  const maximumFractionDigits = fractionDigitsOptions?.maximumFractionDigits ?? 0
  const minimumFractionDigits = fractionDigitsOptions?.minimumFractionDigits ?? 0

  const formattedValue = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits,
    ...options,
  })

  if (hideZeroDecimal) {
    return formattedValue.replace(/\.00$/, '')
  }

  return formattedValue
}

export const numberFormatter = (value: number, options?: Intl.NumberFormatOptions) => {
  return value.toLocaleString('en-US', options)
}

const isThousand = (data: number): boolean => data >= THOUSAND
const isMillion = (data: number): boolean => data >= MILLION
const isBillion = (data: number): boolean => data >= BILLION

export const toShortFormat = (data: string | number): string => {
  const dataNumber = Number(data)

  if (isBillion(dataNumber)) {
    return `${(dataNumber / BILLION).toFixed(1)}B`
  }

  if (isMillion(dataNumber)) {
    return `${(dataNumber / MILLION).toFixed(1)}M`
  }

  if (isThousand(dataNumber)) {
    return `${(dataNumber / THOUSAND).toFixed(0)}k`
  }

  return data.toString()
}

export const parseYearFromTerm = (period: OrderTerms): number => {
  return period ? Number(period.replace(/\D/g, '')) : 0
}
