import { FC } from 'react'

import { MaskedTextFormField } from 'components/form-field/TextFormField/MaskedTextFormField'
import { LINKEDIN_PROFILE } from 'components/form-field/consts'
import { ExtendMaskerFieldProps } from 'components/form-field/TextFormField/types'

import classes from './linkedin-nickname-form-field.module.css'

export const LinkedinNicknameFormField: FC<ExtendMaskerFieldProps> = (props) => {
  return (
    <MaskedTextFormField
      type="text"
      mask={`{${LINKEDIN_PROFILE.url}}NICKNAME`}
      blocks={{
        NICKNAME: {
          mask: /^\S*$/i,
        },
      }}
      lazy={false}
      info={
        <>
          <span>You can get the nickname from your linkedin profile: </span>
          <strong>
            www.linkedin.com/in/<span className={classes.nickname}>your_nickname</span>
          </strong>
        </>
      }
      label={props.label ?? 'Linkedin nickname'}
      maxLength={LINKEDIN_PROFILE.maxLength}
      minLength={LINKEDIN_PROFILE.minLengthWithMask}
      {...props}
    />
  )
}
