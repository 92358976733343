import { FC } from 'react'

import { ExtendTextFieldProps } from 'components/form-field/TextFormField/types'
import {
  MAX_BANK_ACCOUNT_NUMBER_LENGTH,
  MIN_BANK_ACCOUNT_NUMBER_LENGTH,
} from 'components/form-field/consts'
import { AnyNumberFormField } from 'components/form-field/TextFormField/AnyNumberFormField'

export const AccountNumberFormField: FC<ExtendTextFieldProps> = (props) => {
  return (
    <AnyNumberFormField
      minLength={MIN_BANK_ACCOUNT_NUMBER_LENGTH}
      maxLength={MAX_BANK_ACCOUNT_NUMBER_LENGTH}
      {...props}
    />
  )
}
