import { ComponentType, FC } from 'react'

import { NoSsr } from 'components/no-ssr/NoSsr'

export function withNoSsr<Props extends object>(Component: ComponentType<Props>) {
  const WithNoSsr: FC<Props> = (props) => {
    return (
      <NoSsr>
        <Component {...props} />
      </NoSsr>
    )
  }

  WithNoSsr.displayName = `withTheme(${Component.displayName})`

  return WithNoSsr
}
