import { useFormContext } from 'react-hook-form'
import type { SetOptional } from 'type-fest'

import { RCFC } from 'utils/types'
import { LoadingButton, LoadingButtonProps } from 'components/loading-button'
import { cn } from 'utils'

import classes from './submit-form-button.module.css'

type SubmitFormButtonProps = SetOptional<Omit<LoadingButtonProps, 'type'>, 'loading'>

export const SubmitFormButton: RCFC<SubmitFormButtonProps> = ({ className, loading, ...props }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext()
  return (
    <LoadingButton
      loading={loading || isSubmitting}
      {...props}
      type="submit"
      className={cn(classes.button, className)}
    />
  )
}
