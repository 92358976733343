import { useEffect, useId, useMemo, useState, FC } from 'react'
import { useController } from 'react-hook-form'

import { cn } from 'utils'

import { BaseFormField } from '../BaseFormField'
import { SelectFormFieldProps } from './types'
import { optionsConverter } from './utils'
import formFieldClasses from '../form-field.module.css'
import classes from './select-form-field.module.css'

export const SelectFormField: FC<SelectFormFieldProps> = ({
  options,
  disabledOptions,
  name,
  defaultValue = '',
  label,
  className,
  classNameInput,
  classNameLabel,
  fullWidth = true,
  placeholder = 'Select',
  description,
  variation,
  info,
  ...selectProps
}) => {
  const [, setFirstMount] = useState(false)
  useEffect(() => {
    //NOTE: DIRTY HACK
    //When form has default value, after reload page show wrong current option
    //We must call second force rerender for fix
    setFirstMount(true)
  }, [])

  const {
    field: { value, ...field },
    fieldState,
    formState,
  } = useController({ name, defaultValue })
  const labelId = useId()
  const mappedOptions = useMemo(() => {
    return optionsConverter(options, disabledOptions)
  }, [disabledOptions, options])

  const hasError = !!fieldState.error

  const fieldClasses = cn(
    formFieldClasses.field,
    classes.select,
    selectProps.disabled && formFieldClasses.disabled,
    fullWidth && formFieldClasses.fullWidth,
    value === '' && classes.placeholder,
    hasError && formFieldClasses.errorField,
    classNameInput,
  )

  return (
    <BaseFormField
      error={fieldState.error}
      label={label}
      labelId={labelId}
      fullWidth={fullWidth}
      className={className}
      classNameLabel={classNameLabel}
      description={description}
      variation={variation}
      info={info}
    >
      <div className={cn(classes.container)}>
        <select
          aria-busy={formState.isLoading}
          aria-invalid={hasError}
          className={fieldClasses}
          id={labelId}
          value={value}
          {...field}
          {...selectProps}
          disabled={formState.isLoading || selectProps.disabled === true}
        >
          {placeholder && (
            <option value="" key="field_empty" className={classes.placeholder} disabled>
              {placeholder}
            </option>
          )}
          {mappedOptions.map((option) => (
            <option
              value={option.value}
              disabled={option.disabled}
              key={name + '-option-' + option.value}
              className={classes.option}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </BaseFormField>
  )
}
