import { MouseEventHandler, FC } from 'react'

import { cn } from 'utils'
import { ReferralModel } from 'api/nest'

import classes from './actions.module.css'

export const enum ActionType {
  delete = 'add',
  edit = 'edit',
}

export interface ActionMenuProps {
  setIsOpen: (value: boolean) => void
  referral: ReferralModel
  onClick: (action: ActionType, referral: ReferralModel) => void
}

const LIST = [
  {
    title: 'Edit',
    action: ActionType.edit,
  },
  {
    title: 'Delete',
    action: ActionType.delete,
  },
]

export const Menu: FC<ActionMenuProps> = ({ setIsOpen, onClick, referral }) => {
  const onClickAction: MouseEventHandler<HTMLLIElement> = (event) => {
    onClick(event.currentTarget.dataset.actionType as ActionType, referral)
    setIsOpen(false)
  }

  return (
    <ul className={cn(classes.menu)}>
      {LIST.map(({ action, title }, index) => (
        <li
          className={classes.menuItem}
          data-action-type={action}
          key={index}
          onClick={onClickAction}
          tabIndex={0}
        >
          <button className={classes.button}>{title}</button>
        </li>
      ))}
    </ul>
  )
}
