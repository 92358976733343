import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import { MAS_API_TOKEN } from 'const/config'

import {
  MapApiCollectionQueryModel,
  MapSalesModel,
  MapSalesQueryModel,
  MarketDataOfIndexChart,
  MarketStatsOfSaleModel,
  MasApiIndexQueryModel,
  MasMarketModel,
  MedianSalePriceModel,
  PriceSoldModel,
  PriceVolatilityModel,
} from './types'

export class MasApi extends NestController {
  constructor() {
    super({
      controller: '',
      headers: {
        //TODO: Transition to env
        'X-CSRFToken': MAS_API_TOKEN,
      },
    })
  }

  getMapSales = (
    markedUuid: string,
    query: MapSalesQueryModel,
    options?: AxiosRequestConfig,
  ): Promise<MapSalesModel> => {
    return this.get<MapSalesModel>(`/mas-api/county/${markedUuid}/sale`, query, options)
  }

  getMasMarketInfo = (
    markedUuid: string,
    query: MapApiCollectionQueryModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.get<MasMarketModel>(`/mas-api/county/${markedUuid}`, query, options)
  }

  getStatsOfSale = (
    markedUuid: string,
    query: MapApiCollectionQueryModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.get<MarketStatsOfSaleModel>(
      `/mas-api/county/${markedUuid}/stats/sale`,
      query,
      options,
    )
  }

  getIndexChartData = (markedUuid: string, query: MasApiIndexQueryModel) => {
    return this.get<MarketDataOfIndexChart>(`/mas-api/index/${markedUuid}`, query, {
      headers: { 'Cache-Control': 'no-cache' }, // temporarily for testing purposes
    })
  }

  getPriceVolatility = (
    markedUuid: string,
    query: MasApiIndexQueryModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.get<PriceVolatilityModel>(
      `/mas-api/index/${markedUuid}/stats/price-volatility`,
      query,
      options,
    )
  }

  getPropertiesSold = (
    markedUuid: string,
    query: MasApiIndexQueryModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.get<PriceSoldModel>(
      `/mas-api/index/${markedUuid}/stats/properties-sold`,
      query,
      options,
    )
  }

  getMedianSalePrice = (
    markedUuid: string,
    query: MasApiIndexQueryModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.get<MedianSalePriceModel>(
      `/mas-api/index/${markedUuid}/stats/median-sale-price`,
      query,
      options,
    )
  }
}
