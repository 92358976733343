import { StrapiCollection } from 'api/strapi/index'

import { FaqCategory } from './types'

export class FaqCategoryCollection extends StrapiCollection<FaqCategory> {
  constructor() {
    super({ controller: 'faq-categories' })
  }

  populateParams = {
    icon: '*',
    faqs: {
      sort: 'title:asc',
      fields: ['id', 'title'],
    },
  }

  override defaultFindOneParams = {
    populate: this.populateParams,
  }

  override defaultFindParams = {
    sort: 'order',
    populate: this.populateParams,
  }
}
