import { routes } from 'utils/routes'
import { IndexMethodologyEnum } from 'enum/index-methodology'

import { NavItem } from './nav-list/nav-list'
import linkedinIcon from './images/linkedin.png'
import instagramIcon from './images/instagram.png'
import statisticsIcon from './images/statistics-white-rounded.png'
import classes from './footer.module.css'

const linkedin_url = 'https://www.linkedin.com/company/rezitrade'
const instagram_url = 'https://www.instagram.com/rezitrade'

export const socialMedia = (origin: string) => [
  {
    href: linkedin_url,
    src: linkedinIcon,
    alt: 'linkedin icon',
    tooltip: '',
  },
  {
    href: instagram_url,
    src: instagramIcon,
    alt: 'instagram icon',
    tooltip: '',
  },
  {
    href: origin + routes.statistics,
    src: statisticsIcon,
    alt: 'statistics page icon',
    tooltip: 'Market Insights',
  },
]

export const FIRST_NAV_LIST: NavItem[] = [
  {
    link: routes.aboutUs,
    label: 'About REZITRADE',
    isAccent: true,
  },
  {
    link: routes.meetTheTeam,
    label: 'Meet the team',
  },
  {
    link: routes.joinUs,
    label: 'Join us',
  },
]

export const SECOND_NAV_LIST: NavItem[] = [
  {
    link: IndexMethodologyEnum.link,
    label: 'Index Methodology',
    isAccent: true,
    isDownload: true,
    docTitle: IndexMethodologyEnum.docTitle,
  },
  {
    link: routes.contactUs,
    label: 'Contact Us',
    isAccent: true,
  },
  {
    link: routes.partnerPortalDashboard,
    label: 'Partner Portal',
    isAccent: true,
    classNameLink: classes.partnerLogin,
  },
]

export const CLIENTS_FIRST_NAV_LIST: NavItem[] = [
  {
    link: routes.theIndex,
    label: 'About HPI Index',
    isAccent: true,
  },
  {
    link: routes.landingHowItWorks(routes.clientsLanding),
    label: 'How it works',
    isAccent: true,
  },
]

export const CLIENTS_SECOND_NAV_LIST: NavItem[] = [
  {
    link: routes.faqs,
    label: 'FAQs',
    isAccent: true,
  },
  {
    link: routes.landingHelp(routes.clientsLanding),
    label: 'Contact Us',
    isAccent: true,
  },
]

export const AGENTS_FIRST_NAV_LIST: NavItem[] = [
  {
    link: routes.theIndex,
    label: 'About HPI Index',
    isAccent: true,
  },
  {
    link: routes.landingHowItWorks(routes.agentsLanding),
    label: 'How it works',
    isAccent: true,
  },
]

export const AGENTS_SECOND_NAV_LIST: NavItem[] = [
  {
    link: routes.faqs,
    label: 'FAQs',
    isAccent: true,
  },
  {
    link: routes.landingHelp(routes.agentsLanding),
    label: 'Contact Us',
    isAccent: true,
  },
  {
    link: routes.partnerPortalDashboard,
    label: 'Partner Portal',
    isAccent: true,
    classNameLink: classes.partnerLogin,
  },
]
