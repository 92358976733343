import { FC } from 'react'

import { cn } from 'utils/cn'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { MAX_FULL_NAME_LENGTH, MIN_FULL_NAME_LENGTH } from '../consts'

interface FullNameFormField extends ExtendTextFieldProps {
  autoComplete?: 'name' | 'cc-name' | 'off'
}

export const FullNameFormField: FC<FullNameFormField> = ({
  autoCompleteGroup,
  autoComplete = 'name',
  ...props
}) => {
  return (
    <TextFormField
      autoComplete={cn(autoCompleteGroup, autoComplete)}
      label={props.label ?? 'Full Name'}
      maxLength={MAX_FULL_NAME_LENGTH}
      minLength={MIN_FULL_NAME_LENGTH}
      {...props}
    />
  )
}
