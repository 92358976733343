import { FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { ComponentPropsWithRef, forwardRef, useCallback } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import dynamic from 'next/dynamic'

import { NestController } from 'api/nest/NestController'
import { IS_DEVELOPMENT_MODE } from 'const/config'

import { GENERAL_FORM_ERROR_NAME } from './FormGeneralError'

interface IForm extends ComponentPropsWithRef<'form'> {
  className?: string
  debug?: boolean
  formMethods: UseFormReturn<any>
  onSubmit: SubmitHandler<any>
  uiValidationErrorMessage?: string
  id?: string
}

const DEFAULT_VALIDATION_ERROR_MESSAGE = 'Please fix the errors in the form and try again.'

const FormDevTools = dynamic(() => import('./FormDevTools'), { ssr: false })

export const Form = forwardRef<HTMLFormElement, IForm>(
  (
    { children, debug, formMethods, onSubmit, className, id, uiValidationErrorMessage, ...rest },
    ref,
  ) => {
    const errorHandler: SubmitErrorHandler<any> = useCallback(
      (errors, event) => {
        // eslint-disable-next-line no-console
        IS_DEVELOPMENT_MODE && console.log('form request error', errors, '\nevent', event)
        toast.error(uiValidationErrorMessage || DEFAULT_VALIDATION_ERROR_MESSAGE)
      },
      [uiValidationErrorMessage],
    )

    const onSubmitHandler = useCallback(
      async (data, event) => {
        try {
          toast.dismiss()
          await onSubmit(data, event)
        } catch (error: unknown) {
          if (NestController.isNestError(error)) {
            const nestError = NestController.getResponseError(error)

            if (typeof nestError === 'string') {
              formMethods.setError(GENERAL_FORM_ERROR_NAME, {
                type: GENERAL_FORM_ERROR_NAME,
                message: nestError,
              })
              return toast.error(nestError)
            } else if (Array.isArray(nestError)) {
              formMethods.setError(GENERAL_FORM_ERROR_NAME, {
                type: GENERAL_FORM_ERROR_NAME,
                message: nestError.join(', '),
              })
            } else {
              Object.entries(nestError).forEach(([key, value]) => {
                formMethods.setError(
                  key,
                  {
                    type: 'manual',
                    message: value.join(', '),
                  },
                  { shouldFocus: true },
                )
              })
            }

            toast.error(DEFAULT_VALIDATION_ERROR_MESSAGE)
          } else {
            if (axios.isAxiosError(error)) {
              toast.error(error.message)
            }
          }
        }
      },
      [formMethods, onSubmit],
    )

    return (
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmitHandler, errorHandler)}
          noValidate
          className={className}
          ref={ref}
          {...rest}
        >
          {children}
        </form>
        {debug && IS_DEVELOPMENT_MODE && <FormDevTools />}
      </FormProvider>
    )
  },
)

Form.displayName = 'Form'
