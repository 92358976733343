/* eslint-disable no-console */
import { AxiosError } from 'axios'

import { IS_DEVELOPMENT_MODE } from 'const/config'

const isAxiosError = (error: AxiosError | Error): error is AxiosError =>
  (error as AxiosError)?.isAxiosError

export const logAxiosError = (error: AxiosError | Error) => {
  if (!IS_DEVELOPMENT_MODE) return
  if (isAxiosError(error)) {
    console.groupCollapsed(`ERROR ${error.config?.method} ${error.config?.url}`)
    console.log(`Status:  ${error.response?.status}: ${error.response?.statusText}`)
    console.log(`Message: ${error.message}`)
    if (error.response?.data) {
      console.log('Data: %O', error.response?.data)
    }
    console.groupEnd()
  } else {
    console.error(error)
  }
}
