import { AxiosRequestConfig } from 'axios'

import { NestController } from '../NestController'
import {
  AuthResponseModel,
  LoginCredentialsModel,
  SignUpCredentialsModel,
  BrokerRegistrationResponseModel,
} from './type'

export class AuthController extends NestController {
  constructor() {
    super({ controller: 'auth' })
  }

  static controllerName = 'auth'
  static signInUrl = '/local/signin'
  static signUpUrl = '/local/signup'

  public loginIn(data: LoginCredentialsModel, config?: AxiosRequestConfig) {
    return this.post<AuthResponseModel>(AuthController.signInUrl, data, config)
  }

  public registration(data: SignUpCredentialsModel) {
    return this.post<BrokerRegistrationResponseModel>(AuthController.signUpUrl, data)
  }

  public me(config?: AxiosRequestConfig) {
    return this.get<AuthResponseModel>('/me', undefined, config)
  }

  public forgotPassword(email: string) {
    return this.post('/password', { email })
  }

  public resetPassword(password: string, token: string) {
    return this.patch(`/password/${token}`, { password: password })
  }

  public resendBrokerValidationCode(email: string) {
    return this.post('/validate', { email })
  }
}
