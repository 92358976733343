import { AxiosRequestConfig } from 'axios'

import { StrapiCollection } from 'api/strapi/index'

import { IHomepageBanner, IHomepageBannerData } from './types'

export class HomepageBanner extends StrapiCollection<IHomepageBannerData> {
  constructor() {
    super({ controller: 'homepage-banner' })
  }

  public getBannerData(options?: AxiosRequestConfig) {
    return this.get<IHomepageBanner>('/', options)
  }
}
