import { FC, useId } from 'react'
import { useController } from 'react-hook-form'

import { cn } from 'utils'

import { BaseFormField } from '../BaseFormField'
import { TextFormFieldProps } from './types'
import formFieldClasses from '../form-field.module.css'

export const TextFormField: FC<TextFormFieldProps> = ({
  name,
  defaultValue = '',
  label,
  classNameInput,
  fullWidth = true,
  className,
  classNameInputWrapper,
  classNameLabel,
  defaultValueTransformer,
  after,
  before,
  variation,
  info,
  description,
  hiddenLabel = false,
  ...inputProps
}) => {
  const { field, fieldState, formState } = useController({
    name,
    defaultValue,
  })
  const labelId = useId()

  const hasError = !!fieldState.error

  const value = (defaultValueTransformer ? defaultValueTransformer(field.value) : field.value) || ''

  return (
    <BaseFormField
      error={fieldState.error}
      label={label}
      labelId={labelId}
      fullWidth={fullWidth}
      className={className}
      before={before}
      after={after}
      classNameInputWrapper={classNameInputWrapper}
      classNameLabel={classNameLabel}
      variation={variation}
      info={info}
      description={description}
      hiddenLabel={hiddenLabel}
    >
      <input
        aria-busy={formState.isLoading}
        aria-invalid={hasError}
        className={cn(
          formFieldClasses.field,
          fullWidth && formFieldClasses.fullWidth,
          hasError && formFieldClasses.errorField,
          classNameInput,
        )}
        disabled={formState.isLoading || inputProps.disabled === true}
        id={labelId}
        {...field}
        {...inputProps}
        value={value}
      />
    </BaseFormField>
  )
}
