import { ReactNode } from 'react'

import { dateFormatter } from 'utils/date'
import { Status } from 'sections/pp-dashboard-page/referrals-grid/status'
import classes from 'sections/pp-dashboard-page/referrals-grid/referrals-grid.module.css'
import { ReferralModel } from 'api/nest'
import { AmountInfo } from 'sections/pp-dashboard-page/referrals-grid/amount-info'

export const DEFAULT_VALUE = '—'

export interface GridColumn {
  headerTitle: string
  dataKey: keyof ReferralModel | 'actions' | 'fullName' | 'amount'
  render?: (data: ReferralModel) => ReactNode
  className?: string
}

export const GRID_COLUMNS: GridColumn[] = [
  {
    headerTitle: 'First Name',
    dataKey: 'firstName',
    className: classes.firstName,
  },
  {
    headerTitle: 'Last Name',
    dataKey: 'lastName',
    className: classes.lastName,
  },
  {
    headerTitle: 'Full Name',
    dataKey: 'fullName',
    render: (data: ReferralModel) => data.firstName + ' ' + data.lastName,
    className: classes.fullName,
  },
  {
    headerTitle: 'REZITRADE Market',
    dataKey: 'market',
    render: (data) => data.market.name,
    className: classes.market,
  },
  {
    headerTitle: 'Referral Date',
    dataKey: 'createdAt',
    render: (data) =>
      dateFormatter(data.createdAt, { month: 'long', day: 'numeric', year: 'numeric' }),
    className: classes.date,
  },
  {
    headerTitle: 'Status',
    dataKey: 'status',
    render: (data) => <Status status={data.status} />,
    className: classes.status,
  },
  {
    headerTitle: 'Amount',
    dataKey: 'amount',
    render: (row) => <AmountInfo referral={row} />,
    className: classes.amount,
  },
  {
    headerTitle: '',
    dataKey: 'actions',
    className: classes.actions,
  },
]
