import { createElement, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'

import { cn } from 'utils'

import styles from './section.module.scss'

const enum SectionTheme {
  default = 'default',
  outerGap = 'outerGap',
  withoutGap = 'withoutGap',
  outerGapSecondSection = 'outerGapSecondSection',
  innerGapSecondSection = 'innerGapSecondSection',
  hero = 'hero',
  stepContainer = 'stepContainer',
}
const Theme = {
  [SectionTheme.default]: styles.default,
  [SectionTheme.outerGap]: styles.outerGap,
  [SectionTheme.withoutGap]: styles.withoutGap,
  [SectionTheme.innerGapSecondSection]: styles.innerGapSecondSection,
  [SectionTheme.outerGapSecondSection]: styles.outerGapSecondSection,
  [SectionTheme.hero]: styles.hero,
  [SectionTheme.stepContainer]: styles.stepContainer,
}

type SectionDecorationType = 'circle' | 'dots'
type SectionDecoration = SectionDecorationType | `${SectionDecorationType},${SectionDecorationType}`

export interface SectionProps {
  theme?: `${SectionTheme}`
  className?: string
  htmlElement?: string
  id?: string | number
  decoration?: SectionDecoration
  children?: ReactNode
}
const _Section: ForwardRefRenderFunction<HTMLElement, SectionProps> = (
  {
    children,
    className,
    theme = SectionTheme.default,
    htmlElement = 'section',
    decoration,
    ...props
  },
  ref,
) => {
  return createElement(
    htmlElement,
    {
      ref: ref,
      ...props,
      className: cn(
        styles.section,
        Theme[theme],
        ...(decoration ? decoration.split(',').map((decor) => styles[decor]) : []),
        className,
      ),
    },
    children,
  )
}

export const Section = forwardRef(_Section)
