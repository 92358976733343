import { NestController } from '../NestController'
import { FactpackModel } from './types'

export class FactpackController extends NestController {
  constructor() {
    super({ controller: 'fact-pack' })
  }

  createFactpack = async (data: FormData) => {
    return this.post<FactpackModel>('/', data)
  }

  uploadDocument = async (uuid: string, data: FormData) => {
    return this.post(`/${uuid}/upload`, data)
  }

  findAll = async () => {
    return this.get<FactpackModel[]>('/')
  }

  findOneByUuid = async (uuid: string) => {
    return this.get<FactpackModel>(`/${uuid}`)
  }

  update = async (uuid: string, data: FormData) => {
    return this.patch(`/${uuid}`, data)
  }

  getFactpackImage = async (uuid: string) => {
    return this.get<Blob>(`/${uuid}/image`, '', {
      responseType: 'blob',
    })
  }

  getFactpackPdf = async (uuid: string, query: any) => {
    return this.get<Blob>(`/${uuid}/latest`, query, {
      responseType: 'blob',
    })
  }
}
