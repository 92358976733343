import { FlowUserRole } from 'enum/flow-user-role'
import { ConfigurationFlowStorage } from 'utils/use-flow-storage.types'
import { routes } from 'utils/routes'

export enum LandingPageTypeEnum {
  clients = 'clients',
  agents = 'agents',
}

export const areHeaderBtnsVisible = (
  flowStorageData: ConfigurationFlowStorage,
  pathname: string | null,
) =>
  !(
    (flowStorageData.role === FlowUserRole.Purchaser &&
      pathname?.startsWith('/configuration-flow')) || // are we in a specific "Purchaser" buying process
    checkForLandingPage(pathname).isLandingPage
  )

export const checkForLandingPage = (
  pathname: string | null,
): {
  isLandingPage: boolean
  landingPageType: LandingPageTypeEnum | null
} => {
  const isClientsLanding = pathname?.startsWith('/clients') // are we on "clients" landing page
  const isAgentsLanding = pathname?.startsWith('/agents') // are we on "agents" landing page

  let type: LandingPageTypeEnum | null = null

  if (isClientsLanding) {
    type = LandingPageTypeEnum.clients
  } else if (isAgentsLanding) {
    type = LandingPageTypeEnum.agents
  }

  return {
    isLandingPage: Boolean(isClientsLanding || isAgentsLanding),
    landingPageType: type,
  }
}

export const getBasicRoute = (landingPageType: LandingPageTypeEnum | null) => {
  switch (landingPageType) {
    case LandingPageTypeEnum.clients:
      return routes.clientsLanding
    case LandingPageTypeEnum.agents:
      return routes.agentsLanding
    default:
      return ''
  }
}
