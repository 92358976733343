import { ORIGIN_URL } from 'const/config'

interface IArrayToSearchParams {
  searchParams: URLSearchParams
  paramName: string
  values: string[]
  clearPrevParamValue?: boolean
  onlyUniqueValue?: boolean
}

const addValuesToSearchParam = ({
  searchParams,
  values,
  paramName,
  onlyUniqueValue = true,
  clearPrevParamValue = false,
}: IArrayToSearchParams) => {
  let newValues: string[] = values

  if (onlyUniqueValue) {
    const uniqueValue = new Set(values)
    newValues = Array.from(uniqueValue)
  }

  newValues.forEach((value, index) => {
    if (index === 0 && clearPrevParamValue) {
      searchParams.set(paramName, value)
      return
    }
    searchParams.append(paramName, value)
  })
}

export const NEXT_PAGE_PARAM = 'next'
export const addNextPageSearchParam = (path: string, nextPage?: string) =>
  nextPage
    ? addPathSearchParameter({
        path,
        value: nextPage,
        name: NEXT_PAGE_PARAM,
      })
    : path

const appendUniqueSearchParam = (searchParams: URLSearchParams, name: string, value: string) => {
  const currentValues = searchParams.getAll(name)
  if (currentValues.includes(value)) return
  searchParams.append(name, value)
}

const getUpdatedUrl = (url: URL, path: string) => {
  if (path.startsWith(url.protocol)) {
    return url.toString()
  }

  if (!Array.from(url.searchParams).length) {
    return url.pathname
  }

  return `${url.pathname}?${url.searchParams.toString()}`
}

interface IAddPathSearchParameter {
  path: string
  name: string
  value: string
  /** if true all previous values of query name would remove **/
  clearPrevValue?: boolean
}

export function addPathSearchParameter({
  path,
  name,
  value,
  clearPrevValue = false,
}: IAddPathSearchParameter): string {
  const url = new URL(path, ORIGIN_URL)

  if (clearPrevValue) {
    url.searchParams.set(name, value)
  } else {
    appendUniqueSearchParam(url.searchParams, name, value)
  }
  return getUpdatedUrl(url, path)
}

export function removePathSearchParameter(path: string, names: string[]): string {
  const url = new URL(path, ORIGIN_URL)

  names.forEach((name) => {
    url.searchParams.delete(name)
  })

  return getUpdatedUrl(url, path)
}

export function addMultiplePathSearchParameters(
  path,
  args: Omit<IAddPathSearchParameter, 'path'>[],
) {
  return args.reduce((acc, options) => {
    return addPathSearchParameter({ ...options, path: acc })
  }, path)
}

export interface IRemoveValueFromSearchParameter {
  path: string
  name: string
  values: string[]
}

export function removeValuesFromSearchParameter({
  values: removeValues,
  name,
  path,
}: IRemoveValueFromSearchParameter): string {
  const url = new URL(path, ORIGIN_URL)

  if (!url.searchParams.has(name)) {
    return getUpdatedUrl(url, path)
  }

  const currentValues = url.searchParams.getAll(name)
  const newValues = currentValues.filter((value) => !removeValues.includes(value))

  if (!newValues.length) {
    url.searchParams.delete(name)
  } else {
    addValuesToSearchParam({
      searchParams: url.searchParams,
      values: newValues,
      paramName: name,
      clearPrevParamValue: true,
    })
  }

  return getUpdatedUrl(url, path)
}

export function resolveUrl(url: string, pathname: string = '/') {
  const base = url.endsWith('/') ? url : url + '/'
  const path = pathname.startsWith('/') ? pathname.slice(1) : pathname
  return base + path
}

export const queryNormalize = (queryValue?: string | string[]) => {
  return Array.isArray(queryValue) ? queryValue[0] : queryValue
}

export const removeStartAndEndSlash = (str: string) => str.replace(/^\/|\/$/g, '')
