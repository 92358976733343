import { AxiosRequestConfig } from 'axios'

import { FindAllMeta } from 'api/nest/types'

import { NestController, NestControllerConfig } from './NestController'

export interface FindAllResponseModel<SingleEntity> {
  items: SingleEntity[]
  meta: FindAllMeta
}

//TODO: Add put, patch, post
export class NestCollection<
  SingleEntity,
  WithPagination extends boolean = false,
  FindQuery extends object = {},
> extends NestController {
  constructor(props: NestControllerConfig) {
    super(props)
  }

  protected defaultFindQuery: FindQuery

  findOne = (id: string, query?: AxiosRequestConfig['params'], config?: AxiosRequestConfig) => {
    return this.get<SingleEntity>(`/${id}`, query, config)
  }

  find = (query?: FindQuery, config?: AxiosRequestConfig) => {
    return this.get<SingleEntity[]>('/', { ...this.defaultFindQuery, ...query }, config)
  }

  findWithPagination = (query?: FindQuery, config?: AxiosRequestConfig) => {
    return this.get<FindAllResponseModel<SingleEntity>>(
      '/',
      { ...this.defaultFindQuery, ...query },
      config,
    )
  }

  delete = (id: string, config?: AxiosRequestConfig) => {
    return this.request.delete(`/${id}`, config)
  }
}
