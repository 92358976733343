import { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'components/error-message'
import { RCFC } from 'utils//types'
import { cn } from 'utils'
import { Label } from 'components/label'
import { LabelProps } from 'components/label/label'

import formFieldClasses from '../form-field.module.css'

export type BaseFormFieldProps = {
  label?: LabelProps['children']
  info?: LabelProps['info']
  variation?: LabelProps['variation']
  description?: JSX.Element | string | null
  error?: FieldError
  fullWidth?: boolean
  className?: string
  classNameInputWrapper?: string
  classNameLabel?: string
  classNameError?: string
  before?: ReactNode
  after?: ReactNode
  hiddenLabel?: boolean
  labelId?: string
}

export type BaseFormFieldClassesProps = Pick<
  BaseFormFieldProps,
  'classNameInputWrapper' | 'classNameLabel' | 'classNameError' | 'className'
>

export const BaseFormField: RCFC<BaseFormFieldProps> = ({
  error,
  children,
  label,
  fullWidth = true,
  className,
  classNameInputWrapper,
  classNameLabel,
  classNameError,
  before,
  after,
  info,
  description,
  variation,
  hiddenLabel = false,
  labelId,
}) => {
  const fullWidthClass = fullWidth && formFieldClasses.fullWidth
  const hiddenLabelClass = hiddenLabel && formFieldClasses.hiddenLabel

  return (
    <div className={cn(fullWidthClass, className)}>
      {label && (
        <Label
          className={cn(classNameLabel, fullWidthClass, hiddenLabelClass)}
          htmlFor={labelId}
          info={info}
          variation={variation}
        >
          {label}
        </Label>
      )}

      {description && <span className={formFieldClasses.description}>{description}</span>}

      <div className={cn(formFieldClasses.container, fullWidthClass, classNameInputWrapper)}>
        {before}
        {children}
        {after}
      </div>

      {error && (
        <ErrorMessage className={cn(formFieldClasses.fieldErrorMessage, classNameError)}>
          {error?.message}
        </ErrorMessage>
      )}
    </div>
  )
}
