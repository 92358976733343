import { FC } from 'react'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL, MAX_REFERRAL_CODE_LENGTH } from '../consts'

export const ReferralCodeFormField: FC<ExtendTextFieldProps> = (props) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.referralCode}
      minLength={MAX_REFERRAL_CODE_LENGTH}
      maxLength={MAX_REFERRAL_CODE_LENGTH}
      info="Please provide unique referral code provided to you, if applicable."
      {...props}
    />
  )
}
