import { FC } from 'react'

import { cn } from 'utils/cn'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL, MAX_LAST_NAME_LENGTH } from '../consts'

export const LastNameFormField: FC<ExtendTextFieldProps> = ({ autoCompleteGroup, ...props }) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.lastName}
      autoComplete={cn(autoCompleteGroup, 'family-name')}
      maxLength={MAX_LAST_NAME_LENGTH}
      {...props}
    />
  )
}
