import { useRouter } from 'next/router'
import { createContext, useCallback, useContext } from 'react'

import { DIALOG_NAME } from 'const/dialogs'
import { useHasQueryParamValue } from 'utils/router'
import { addPathSearchParameter, removeValuesFromSearchParameter } from 'utils/url'

export const DIALOG_QUERY_NAME = 'dialog'

export const useIsOpenDialog = (dialogName: DIALOG_NAME) => {
  return useHasQueryParamValue(DIALOG_QUERY_NAME, dialogName)
}

interface IOpenOptions {
  routeReplace?: boolean
  closePrevModals?: boolean
}

interface ICloseOptions {
  routeReplace?: boolean
  closePrevModals?: boolean
}

export const useControlRouterDialog = () => {
  const { push, replace } = useRouter()

  const open = useCallback(async (dialogName: DIALOG_NAME, options?: IOpenOptions) => {
    const { routeReplace = false, closePrevModals = true } = options || {}
    const nextUrl = addPathSearchParameter({
      path: window.location.pathname + window.location.search,
      name: DIALOG_QUERY_NAME,
      value: dialogName,
      clearPrevValue: closePrevModals,
    })

    const routeType = routeReplace ? replace : push

    await routeType(nextUrl, undefined, {
      shallow: true,
    })

    // replace and push functions are not memoized; dependents on open/close will loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = useCallback(
    async (dialogName: DIALOG_NAME, options?: ICloseOptions) => {
      const { routeReplace = true } = options || {}

      const nextUrl = removeValuesFromSearchParameter({
        path: window.location.pathname + window.location.search,
        name: DIALOG_QUERY_NAME,
        values: [dialogName],
      })

      const routeType = routeReplace ? replace : push
      await routeType(nextUrl, undefined, {
        shallow: true,
      })
    },
    [replace, push],
  )

  return {
    open,
    close,
  }
}

export const useToggleRouterDialog = (dialogName: DIALOG_NAME) => {
  const isOpen = useIsOpenDialog(dialogName)
  const { open, close } = useControlRouterDialog()

  const toggle = useCallback(() => {
    if (isOpen) {
      close(dialogName)
    } else {
      open(dialogName)
    }
  }, [close, dialogName, isOpen, open])

  return {
    toggle,
    isOpen,
  }
}

export const DialogContext = createContext<{
  close: () => void
}>({
  close: () => {},
})

export const useDialogContext = () => {
  return useContext(DialogContext)
}
