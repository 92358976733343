import { FC } from 'react'

import Info from 'icons/info.svg'
import { cn } from 'utils'
import { IconButton } from 'components/icon-button'
import { IconButtonProps } from 'components/icon-button/IconButton'

import classes from './info-icon.module.css'

export const InfoIcon: FC<IconButtonProps> = ({ className, ...rest }) => {
  return (
    <IconButton className={cn(classes.icon, className)} {...rest} type="button">
      <Info />
    </IconButton>
  )
}
