import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { CSSProperties, FC, useCallback, useMemo, useState } from 'react'

import { nest } from 'api'
import { MarketModel } from 'api/nest'
import { ReferralCollectionModel, ReferralModel } from 'api/nest/referrals'
import { AsyncError } from 'components/async-data'
import { Dialog } from 'components/dialog'
import { ReloadPageButton } from 'components/reload-page-button'
import { Pagination } from 'components/pagination'
import { QUERY_KEY } from 'const/query'
import { RecordPerPage } from 'sections/pp-dashboard-page/record-per-page'
import { cn } from 'utils'
import { queryNormalize } from 'utils/url'

import { ConfirmDelete } from '../confirm-delete'
import { EditReferral } from '../edit-referral'
import { ActionsProps, ActionType } from './actions'
import { GRID_COLUMNS } from './consts'
import { GridRow } from './referrals-grid-row'
import classes from './referrals-grid.module.css'

interface ReferralsGridProps {
  canSeeCommissionDetails: boolean
  className?: string
  hasSearch: boolean
  initialData: ReferralCollectionModel
  markets: MarketModel[]
}

/**
 *  NOTE:
 *  1. FullNameColumn not show in table
 *  2. Actions Column has personal width for table
 */
const NOT_MAIN_COLUMN_NUMBER = 2

export const ReferralsGrid: FC<ReferralsGridProps> = ({
  canSeeCommissionDetails,
  className,
  hasSearch,
  initialData,
  markets,
}) => {
  const { query } = useRouter()
  const [editReferral, setEditReferral] = useState<ReferralModel | null>(null)
  const [deleteReferral, setDeleteReferral] = useState<ReferralModel | null>(null)

  const referralsQuery = useQuery(
    [
      QUERY_KEY.referrals,
      queryNormalize(query.referralsPage),
      queryNormalize(query.referralsTake),
      queryNormalize(query.referralsSearch),
    ],
    () =>
      nest.referrals.advancedSearch({
        page: queryNormalize(query.referralsPage),
        take: queryNormalize(query.referralsTake),
        search: queryNormalize(query.referralsSearch),
      }),
    {
      initialData,
    },
  )

  const onCloseEditDialog = () => {
    setEditReferral(null)
  }

  const onAction: ActionsProps['onAction'] = useCallback((action, referral) => {
    if (action === ActionType.delete) {
      setDeleteReferral(referral)
      return
    }

    if (action === ActionType.edit) {
      setEditReferral(referral)
      return
    }
  }, [])

  const columns = useMemo(() => {
    if (canSeeCommissionDetails) return GRID_COLUMNS

    return GRID_COLUMNS.filter((column) => {
      return !['amount', 'status'].includes(column.dataKey)
    })
  }, [canSeeCommissionDetails])

  const hasReferrals = referralsQuery.data?.items?.length > 0

  const { itemCount, pageCount, page, take } = referralsQuery.data?.meta ?? {
    itemCount: 0,
    pageCount: 0,
    page: 0,
    take: 0,
  }

  if (referralsQuery.error) {
    return (
      <AsyncError error={referralsQuery.error}>
        <p>
          Error loading referrals, please <ReloadPageButton /> or try again later
        </p>
      </AsyncError>
    )
  }

  return (
    <>
      <section
        className={cn(classes.grid, referralsQuery.isInitialLoading && classes.wait, className)}
        style={
          {
            '--main-column-number': columns.length - NOT_MAIN_COLUMN_NUMBER,
          } as CSSProperties
        }
      >
        <div className={cn(classes.gridRow, classes.headerRow)}>
          {columns.map((column) => (
            <div
              className={cn(classes.gridItem, classes.headerTitle, column.className)}
              key={column.headerTitle}
            >
              {column.headerTitle}
            </div>
          ))}
        </div>

        {referralsQuery.isInitialLoading && !hasReferrals && (
          <p className={classes.noData}>Loading...</p>
        )}
        {!referralsQuery.isInitialLoading && !hasReferrals && (
          <p className={classes.noData}>
            {hasSearch ? 'No referrals found' : 'No referrals added yet'}
          </p>
        )}

        {referralsQuery.data?.items.map((referral) => (
          <GridRow referral={referral} key={referral.uuid} onAction={onAction} columns={columns} />
        ))}
      </section>
      <div className={classes.footer}>
        <RecordPerPage className={classes.record} takeParamName="referralsTake" />
        <Pagination
          page={page}
          pageCount={pageCount}
          pageParamName="referralsPage"
          className={classes.pagination}
          shallow
        />
        <strong className={classes.rowCount}>
          Showing {(page - 1) * take + 1}-{Math.min(page * take, itemCount)} of {itemCount}
        </strong>
      </div>
      {editReferral && (
        <Dialog onClose={onCloseEditDialog} open={true} title="Edit Referral">
          <EditReferral markets={markets} editReferral={editReferral} />
        </Dialog>
      )}
      {deleteReferral && (
        <ConfirmDelete
          deletedRowId={deleteReferral?.uuid}
          onClose={() => setDeleteReferral(null)}
        />
      )}
    </>
  )
}
