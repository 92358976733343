import { StrapiCollection } from 'api/strapi/index'

import { TeamPerson } from './types'

export class TeamsCollection extends StrapiCollection<TeamPerson> {
  constructor() {
    super({ controller: 'teams' })
  }

  override defaultFindParams = {
    populate: ['photo'],
    sort: ['order'],
  }
}
