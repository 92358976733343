import { NestController } from 'api/nest/NestController'

import { ContactUsCreateModel } from './types'

export class ContactUs extends NestController {
  constructor() {
    super({ controller: 'contact-us' })
  }

  public create(data: ContactUsCreateModel) {
    return this.post<ContactUsCreateModel>('/', data)
  }
}
