import { NestController } from 'api/nest/NestController'
import { wait } from 'utils/helpers'

import { CalculationRequestModel, CalculationResponseModel } from './type'

//TODO: change to real endpoint when it's ready
export class Calculation extends NestController {
  constructor() {
    super({ controller: 'calculation' })
  }

  public getCalculation = async (
    params: CalculationRequestModel,
  ): Promise<CalculationResponseModel> => {
    await wait(1500)
    return {
      estimatedAnnualPrice: 1125,
      payout: 20,
    }
  }
}
