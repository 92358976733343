import { NextRouter, useRouter } from 'next/router'

interface IQueryMatchResult {
  match: boolean
  isEmpty: boolean
  hasKey: boolean
  keyValue?: string | string[]
}

const queryMatch = (
  currentQueries: NextRouter['query'],
  compareKey: string,
  compareKeyValue: string,
): IQueryMatchResult => {
  const hasKey = compareKey in currentQueries

  return {
    match: hasKey && currentQueries[compareKey] === String(compareKeyValue),
    isEmpty: Boolean(currentQueries[compareKey]),
    hasKey: hasKey,
    keyValue: currentQueries[compareKey],
  }
}

export const useQueryMatch = (key: string, value: string | number) => {
  const route = useRouter()

  return {
    ...queryMatch(route.query, key, String(value)),
    route,
  }
}

export const useQueryParam = (name: string): string | string[] | undefined => {
  const routes = useRouter()
  return routes.query[name]
}

export const useStringQueryParam = (name: string): string | undefined => {
  const value = useQueryParam(name)
  return Array.isArray(value) ? value[0] : value
}

export const useHasQueryParamValue = (name: string, value: string) => {
  const currentParamValue = useQueryParam(name)

  if (Array.isArray(currentParamValue)) {
    return currentParamValue.includes(value)
  }

  return currentParamValue === value
}

export function useQueryState<Value extends string | number | boolean>(
  name: string,
  defaultValue?: Value,
): [string | undefined, (newValue: Value) => void]
export function useQueryState<Value extends string | number | boolean>(
  name: string,
  defaultValue: Value,
): [string, (newValue: Value) => void]
export function useQueryState<Value extends string | number | boolean>(
  name: string,
  defaultValue?: Value,
) {
  const { asPath, push, query } = useRouter()
  const currentState = useQueryParam(name) ?? defaultValue

  const setQuery = (newState: Value) => {
    const newQuery = { ...query, [name]: newState }

    push(asPath, {
      query: Object.fromEntries(
        Object.entries(newQuery).filter(([, v]) => !(v === null || v === undefined)),
      ),
    })
  }

  return [Array.isArray(currentState) ? currentState.join(',') : currentState, setQuery] as const
}
