import { ComponentProps, FC } from 'react'

import { Link } from 'components/links'

type SupportLinkProps = Omit<ComponentProps<typeof Link>, 'href' | 'children'>

export const SUPPORT_EMAIL = 'support@rezitrade.com'
export const SUPPORT_EMAIL_PARTNER_PORTAL = 'partner@rezitrade.com'

export const SupportEmailLink: FC<SupportLinkProps> = (props) => {
  const email = window?.location?.pathname?.startsWith('/partner-portal')
    ? SUPPORT_EMAIL_PARTNER_PORTAL
    : SUPPORT_EMAIL

  return (
    <Link href={`mailto:${email}`} theme="primary" {...props}>
      {email}
    </Link>
  )
}

export const SupportTelLink: FC<SupportLinkProps> = (props) => {
  return (
    <Link href="tel:+1-888-555-5555" theme="primary" {...props}>
      +1-888-555-5555
    </Link>
  )
}

export const ContactOurAgent: FC<SupportLinkProps> = ({ ...props }) => {
  return (
    <>
      contact our Customer Support Team at <SupportEmailLink {...props} />
    </>
  )
}
