import { ComponentType, FC } from 'react'

import { RouterDialog, RouterDialogProps } from 'components/dialog/RouterDialog'
import { NoSsr } from 'components/no-ssr'

export function withRouterDialog<Props extends {}>(
  Component: ComponentType<Props>,
  options: Omit<RouterDialogProps, 'onClose' | 'children'>,
) {
  const ComponentInDialog: FC<Props> = (props) => {
    return (
      <NoSsr>
        <RouterDialog {...options}>
          <Component {...props} />
        </RouterDialog>
      </NoSsr>
    )
  }

  ComponentInDialog.displayName = Component.displayName

  return ComponentInDialog
}
