import { FC } from 'react'
import { useToggle } from 'react-use'

import { cn } from 'utils/cn'
import ShowPasswordIcon from 'icons/eye-show.svg'
import HidePasswordIcon from 'icons/eye-hid.svg'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL, MAX_PASSWORD_LENGTH } from '../consts'
import classes from './password-form-field.module.css'

export type PasswordFormField = ExtendTextFieldProps & {
  autoComplete: 'current-password' | 'new-password'
}

export const PasswordFormField: FC<PasswordFormField> = ({
  autoCompleteGroup,
  autoComplete,
  className,
  ...props
}) => {
  const [showPassword, togglePassword] = useToggle(false)

  const Icon = showPassword ? ShowPasswordIcon : HidePasswordIcon

  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.password}
      autoComplete={cn(autoCompleteGroup, autoComplete)}
      maxLength={MAX_PASSWORD_LENGTH}
      classNameInputWrapper={classes.inputWrapper}
      type={showPassword ? 'text' : 'password'}
      className={className}
      classNameInput={classes.input}
      after={
        <button className={classes.button} onClick={togglePassword} type="button">
          <Icon className={classes.icon} />
        </button>
      }
      {...props}
    />
  )
}
