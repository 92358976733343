import type { Primitive } from 'type-fest'

type StrapiModelKey = string
export type StrapiDataModel = Record<StrapiModelKey, any> & {
  id: number
}
type StrapiFilterValue = string | number | boolean

const enum PublicationState {
  /** returns only published entries (default) */
  Live = 'live',
  /** returns both draft entries & published entries */
  Preview = 'preview',
}

const enum StrapiSortType {
  Ascending = 'asc',
  Descending = 'desc',
}

const enum StrapiComplexFilterOperator {
  Or = '$or',
  And = '$and',
}

const enum StrapiBaseFilterOperator {
  Equal = '$eq',
  NotEqual = '$ne',
  LessThan = '$lt',
  GreaterThan = '$gt',
  LessThanOrEqualTo = '$lte',
  GreaterThanOrEqualTo = '$gte',
  IncludedInAnArray = '$in',
  NotIncludedInAnArray = '$notIn',
  ContainsCaseSensitive = '$contains',
  NotContainsCaseSensitive = '$notContains',
  Containsi = '$containsi',
  NotContainsi = '$notContainsi',
  IsNull = '$null',
  NotNull = '$notNull',
  Between = '$between',
  StartsWith = '$startsWith',
  EndsWith = '$endsWith',
}

//Utils
type ExcludePrimitive<T> = Exclude<T, Primitive>
type IsPopulateKey<T> = ExcludePrimitive<T> extends never ? false : true
type StrapiModelWithoutPopulate<Model extends StrapiDataModel> = {
  [Key in keyof Model as IsPopulateKey<Model[Key]> extends true ? never : Key]: Model[Key]
}
type StrapiOnlyPopulateModel<Model extends StrapiDataModel> = {
  [Key in keyof Model as IsPopulateKey<Model[Key]> extends true ? Key : never]: Model[Key]
}

type StrapiOnlyPopulateModelKeys<Model extends StrapiDataModel> =
  keyof StrapiOnlyPopulateModel<Model>

type StrapiOnlyOwnModelKeys<Model extends StrapiDataModel> = keyof StrapiModelWithoutPopulate<Model>

type StrapiModelByPopulate<Model extends StrapiDataModel | StrapiDataModel[]> =
  Model extends (infer A)[] ? ExcludePrimitive<A> : ExcludePrimitive<Model>

//Sort
export type StrapiSortKey<Key extends string> = `${Key}:${StrapiSortType}` | Key

export type StrapiSort<
  Model extends StrapiDataModel,
  Keys extends keyof Model = keyof StrapiModelWithoutPopulate<Model>,
> = Array<Keys extends string ? StrapiSortKey<Keys> : never>

type StrapiPopulateArray<Model extends StrapiDataModel> = Array<StrapiOnlyPopulateModelKeys<Model>>

//Pagination
type StrapiPaginationByOffset = {
  /**
   * Start value (i.e. first entry to return)
   */
  start?: number
  /**
   * Number of entries to return
   * @default 25
   */
  limit?: number
  /**
   * Toggles displaying the total number of entries to the response
   * @default true
   */
  withCount?: boolean
  page?: never
  pageSize?: never
}

type StrapiPaginationByPage = {
  /**
   * Page number
   */
  page?: number
  /**
   * Page size
   * @default 25
   */
  pageSize?: number
  /**
   * Toggles displaying the total number of entries to the response
   * @default true
   */
  withCount?: boolean
  limit?: never
  start?: never
}

type StrapiPagination = StrapiPaginationByPage | StrapiPaginationByOffset

//Filters
type StrapiFilterByBaseOperators = {
  [Key in StrapiBaseFilterOperator]?: StrapiFilterValue
} & {
  [StrapiBaseFilterOperator.IncludedInAnArray]?: StrapiFilterValue[]
  [StrapiBaseFilterOperator.NotIncludedInAnArray]?: StrapiFilterValue[]
  [StrapiBaseFilterOperator.NotNull]?: boolean
  [StrapiBaseFilterOperator.IsNull]?: boolean
}

type StrapiDeepFilter<Model extends StrapiDataModel> = {
  [Key in keyof Model]?: IsPopulateKey<Model[Key]> extends true
    ? StrapiDeepFilter<StrapiModelByPopulate<Model[Key]>> & StrapiFilterByBaseOperators
    : StrapiFilterByBaseOperators | StrapiFilterValue
}

type StrapiBaseFilter<Model extends StrapiDataModel> = {
  [Key in keyof StrapiModelWithoutPopulate<Model>]?: StrapiFilterByBaseOperators
}

type StrapiFilterWithoutOperators<Model extends StrapiDataModel> = Partial<
  Record<StrapiOnlyOwnModelKeys<Model>, StrapiFilterValue>
>

type StrapiComplexFilter<Model extends StrapiDataModel> = {
  [Key in StrapiComplexFilterOperator]?: StrapiBaseFilter<Model>[]
}

type StrapiFilters<Model extends StrapiDataModel> =
  | StrapiComplexFilter<Model>
  | StrapiDeepFilter<Model>

//Fields
type StrapiFieldSelection<Model extends StrapiDataModel> = Model extends StrapiDataModel
  ? Array<keyof StrapiModelWithoutPopulate<Model>>
  : never

//Populate
type StrapiPopulateObj<Model extends StrapiDataModel> = {
  [Key in StrapiOnlyPopulateModelKeys<Model>]?: StrapiQuery<StrapiModelByPopulate<Model[Key]>> | '*'
}
type StrapiPopulate<Model extends StrapiDataModel> =
  | StrapiPopulateObj<Model>
  | StrapiPopulateArray<Model>
  | '*'

export type StrapiQuery<Model extends StrapiDataModel> = {
  sort?: StrapiSort<Model>
  pagination?: StrapiPagination
  filters?: StrapiFilters<Model>
  fields?: StrapiFieldSelection<Model>
  populate?: StrapiOnlyPopulateModelKeys<Model> extends never ? never : StrapiPopulate<Model>
  publicationState?: `${PublicationState}`
} & StrapiFilterWithoutOperators<Model>
