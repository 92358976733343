import { ObjectSchema } from 'yup'

import { AddressCreateModel } from 'api/nest/addresses/types'
import { yup } from 'components/form/yup'
import { CountryEnum } from 'enum/country'
import { UsStateEnum } from 'enum/us-state'

export const ADDRESS_CREATE_SCHEMA: ObjectSchema<AddressCreateModel> = yup.object({
  city: yup.string().defined().min(1, 'Required'), // arbitrary length based on "shortest city in the world" so an empty string is not accepted,
  country: yup.string().oneOf(Object.values(CountryEnum), 'Required').defined(),
  street1: yup.string().defined().min(5, 'Required'), // arbitrary length so an empty string is not accepted
  street2: yup.string().optional().nullable(),
  postalCode: yup
    .string()
    .defined()
    .when('country', {
      is: (value) => value === CountryEnum['United States'],
      then: (schema) => schema.min(5, 'Must be 5 digits'),
    }),
  region: yup
    .string()
    .defined()
    .when('country', {
      is: (value) => value === CountryEnum['United States'],
      then: (schema) => schema.oneOf(Object.values(UsStateEnum), 'Required'),
    }),
})
