import { NestController } from '../NestController'
import { VerifyModel } from './types'

export class PreviewMode extends NestController {
  constructor() {
    super({ controller: 'preview-mode' })
  }

  validateToken(token: string) {
    return this.get<VerifyModel>(`/validate?token=${token}`)
  }
}
