import { memo } from 'react'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'

import { cn } from 'utils'
import { BuyNowButton } from 'components/buy-now-button'
import Logo from 'icons/logo-standard-black-and-blue-trademark.png'
import { Link, LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { Burger } from 'components/header/default-header/burger'
import { HeaderNav } from 'components/header/default-header/nav'
import { useFlowStorage } from 'utils/use-flow-storage'
import classes from 'components/header/default-header/header.module.css'

import { areHeaderBtnsVisible, checkForLandingPage, getBasicRoute } from './utils'

interface HeaderTemplateProps {
  onCloseMenu: () => void
  onToggleMenu: () => void
  isOpenMenu: boolean
}

export const DefaultHeaderTemplate = memo<HeaderTemplateProps>(
  ({ onCloseMenu, isOpenMenu, onToggleMenu }) => {
    const router = useRouter()
    const pathname = usePathname()
    const [flowStorageData] = useFlowStorage()
    const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

    const getHrefValue = () => {
      if (isLandingPage && landingPageType) {
        return getBasicRoute(landingPageType)
      }

      return routes.homePage
    }

    return (
      <>
        <Link href={getHrefValue()} className={classes.logoWrapper} onClick={onCloseMenu}>
          <div className={classes.logoContainer}>
            <NextImage
              src={Logo}
              alt="logo"
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
              className={classes.logo}
            />
          </div>
        </Link>
        <Link href={getHrefValue()} className={cn(classes.title)} onClick={onCloseMenu}>
          Home Price Protection
        </Link>
        {areHeaderBtnsVisible(flowStorageData, pathname) && (
          <>
            <LinkButton theme="secondary" href={routes.homeseller} className={classes.homesellers}>
              Home Sellers
            </LinkButton>
            <BuyNowButton
              toClearStorage={true}
              toIgnoreSessionRole={true}
              className={classes.buyNow}
            >
              {router.pathname.startsWith(routes.configurationFlow) ? 'Start Over' : 'Buy Now'}
            </BuyNowButton>
          </>
        )}
        <Burger open={isOpenMenu} onClick={onToggleMenu} className={classes.burger} />
        <HeaderNav
          className={cn(classes.nav)}
          isMobileMenuOpen={isOpenMenu}
          onCloseMobileMenu={onCloseMenu}
        />
      </>
    )
  },
)

DefaultHeaderTemplate.displayName = 'StaticHeader'
