import { PropertyTypeEnum } from 'enum/property-type'
import { parseYearFromTerm, percentFormatter, usdFormatter } from 'utils/number'

import { OrderTerms } from '../api/nest/orders/types'

interface GetTotalPriceArgs {
  annualPrice: number
  term: OrderTerms
}
export const getTotalPrice = ({ annualPrice, term }: GetTotalPriceArgs) => {
  if (!term) return 0
  return annualPrice * parseYearFromTerm(term)
}

export const triggerOfOrderFormatter = (trigger: number): string => {
  return percentFormatter(trigger * -1, 1)
}

export const socialSecurityNumberFormatter = (socialSecurityNumber: string): string => {
  return socialSecurityNumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
}

export const phoneNumberFormatter = (phoneNumber: string): string => {
  if (typeof phoneNumber !== 'string') return ''
  // values are stored with country code, see PhoneUSFormField component
  return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1-$2-$3-$4')
}

export const annualPriceOrderFormatter = (annualPrice: number = 0): string => {
  return annualPrice ? usdFormatter(annualPrice) : '-'
}

export const totalPriceOrderFormatter = (totalPrice: number = 0): string => {
  return totalPrice ? usdFormatter(totalPrice) : '-'
}

export const calculateAndFormatTotalPrice = (
  term?: OrderTerms,
  annualPrice: number = 0,
): string => {
  if (!term || !annualPrice) return '-'

  return annualPrice ? usdFormatter(getTotalPrice({ term, annualPrice })) : '-'
}

export const getDisplayPropertyType = (propertyType: PropertyTypeEnum) => {
  return {
    [PropertyTypeEnum.SingleFamilyHome]: 'Single Family Home',
    [PropertyTypeEnum.MultiFamilyHome]: 'Multi-Family Home',
    [PropertyTypeEnum.Condo]: 'Condominium',
    [PropertyTypeEnum.CoOp]: 'Cooperative',
  }[propertyType]
}
