import { FC } from 'react'

import { NestValidationErrorResponseData } from 'api/nest/NestController'

import classes from './validation-error-list.module.css'

interface ValidationErrorListProps {
  errors: NestValidationErrorResponseData['errors']
}

export const ValidationErrorList: FC<ValidationErrorListProps> = ({ errors }) => {
  if (Array.isArray(errors) || !errors) return null

  return (
    <ul className={classes.list}>
      {Object.keys(errors).map((key) => (
        <li key={key} className={classes.listItem}>
          <b>{key}: </b>
          <span>{errors[key]?.join(', ')}</span>
        </li>
      ))}
    </ul>
  )
}
