import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import {
  CreateMarketWaitlistRequestModel,
  CreateMarketWaitlistResponseModel,
} from 'api/nest/waitlist/types'

export class WaitlistController extends NestController {
  constructor() {
    super({ controller: 'market-waitlist' })
  }

  public createWaitlistEntry = (
    data: CreateMarketWaitlistRequestModel,
    config?: AxiosRequestConfig,
  ) => {
    return this.post<CreateMarketWaitlistResponseModel>('/', data, config)
  }
}
