import classes from 'components/form-field/PasswordWithQualityFormField/password-withquality-form-field.module.css'

const SPACE_LENGTH = 1
export const MAX_EMAIL_LENGTH = 100
export const MIN_EMAIL_LENGTH = 5
export const MAX_FIRST_NAME_LENGTH = 50
export const MIN_FIRST_NAME_LENGTH = 2
export const MIN_LAST_NAME_LENGTH = 2
export const MAX_LAST_NAME_LENGTH = 50
export const MIN_FULL_NAME_LENGTH = MIN_FIRST_NAME_LENGTH + SPACE_LENGTH + MIN_LAST_NAME_LENGTH
export const MAX_FULL_NAME_LENGTH = MAX_LAST_NAME_LENGTH + SPACE_LENGTH + MAX_FIRST_NAME_LENGTH
export const CREDIT_CARD_NAME_MIN_LENGTH = 2
export const CREDIT_CARD_NAME_MAX_LENGTH = 26
export const MAX_PASSWORD_LENGTH = 50
export const MIN_PASSWORD_LENGTH = 8
export const MAX_CITY_NAME_LENGTH = 50
export const MAX_ADDRESS_LENGTH = 100
export const MAX_ZIP_CODE_LENGTH = 5
export const MAX_REFERRAL_CODE_LENGTH = 50
export const CC_CVV_LENGTH = 3
export const MIN_BANK_ACCOUNT_NUMBER_LENGTH = 5
export const MAX_BANK_ACCOUNT_NUMBER_LENGTH = 17
export const BANK_ROUTING_NUMBER_LENGTH = 9
export const MIN_PHONE_NUMBER_LENGTH = 8 // Solomon Islands - shortest phone numbers including code
export const US_PHONE_NUMBER_LENGTH = 11

/** @description Social Security Number */
export const SSN = {
  mask: '000-00-0000',
  get length() {
    return this.mask.replace(/[^0]/g, '').length
  },
  get placeholder() {
    return this.mask.replace(/0/g, 'X')
  },
  get formattedLength() {
    return this.mask.length
  },
}

export const COMPANY_NAME = {
  minLength: 2,
  maxLength: 50,
}

export const LINKEDIN_PROFILE = {
  url: 'www.linkedin.com/in/',
  minLength: 2,
  get minLengthWithMask() {
    return this.url.length + this.minLength
  },
  maxLength: 100,
}

export const CREDIT_CARD_NUMBER = {
  length: 16,
  getLengthWithSpace(length: number) {
    const numberLengthBetweenSpace = 4
    const spaceCount = Math.floor((length - 1) / numberLengthBetweenSpace)

    return length + spaceCount
  },
  get formattedMinLength() {
    return this.getLengthWithSpace(this.length)
  },
  get formattedMaxLength() {
    return this.getLengthWithSpace(this.length)
  },
} as const

export const CREDIT_CARD_EXPIRATION_DATE = {
  dashLength: 1,
  expirationDateLength: 4,
  get formattedLength() {
    return this.expirationDateLength + this.dashLength
  },
}

export const FORM_FIELD_LABEL = {
  zipCode: 'Zip Code',
  phone: 'Phone',
  dayOfBirth: 'Date of Birth',
  socialNumber: 'Social Security Number',
  city: 'City',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  email: 'Email Address',
  state: 'State',
  address: 'Address',
  cardNumber: 'Card Number',
  ccExpirationDate: 'Expiration Date',
  cvv: 'CVV',
  password: 'Password',
  referralCode: 'Referral Code',
  companyName: 'Company Name',
}

interface Rule {
  title: string
  testRegexp: RegExp
  errorMessage: string
  className?: string
}

export const NEW_PASSWORD_QUALITY_RULE: Rule[] = [
  {
    title: '1 capital letter',
    testRegexp: /[A-Z]/g,
    errorMessage: 'Must contain at least 1 capital letter',
    className: classes.capitalLetter,
  },
  {
    title: '1 lowercase letter',
    testRegexp: /[a-z]/g,
    errorMessage: 'Must contain at least 1 lowercase letter',
    className: classes.lowercaseLetter,
  },
  {
    title: '1 special character',
    errorMessage: 'Must contain at least 1 special character',
    testRegexp: /[^a-zA-Z0-9]/g,
    className: classes.specialCharacter,
  },
  {
    title: '8 characters',
    testRegexp: /.{8,}/g,
    className: classes.lengthLimit,
    errorMessage: 'Must be at least 8 characters',
  },
  {
    title: '1 number',
    testRegexp: /[0-9]/g,
    errorMessage: 'Must contain at least 1 number',
    className: classes.number,
  },
]

export enum SessionStorageKeysEnum {
  phoneInputValidationData = 'phoneInputValidationData',
}

export enum CountriesEnum {
  us = 'United States',
}

export const prefferedCountries = [
  'br',
  'ca',
  'cn',
  'hk',
  'de',
  'in',
  'it',
  'mx',
  'kr',
  'gb',
  'us',
  'vn',
]

export const excludedCountries = ['cu', 'ir', 'ly', 'kp', 'sy']
