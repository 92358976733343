import { ReactNode } from 'react'

export enum CompanyTypeEnum {
  realEstate = 'realEstate',
  mortgage = 'mortgage',
}

export enum FieldTypeEnum {
  agentName = 'agentName',
  companyName = 'companyName',
}

export const PAYER_FIELDS = {
  companyType: 'payer.companyType',
  firstName: 'payer.firstName',
  lastName: 'payer.lastName',
  email: 'payer.email',
  companyName: 'payer.companyName',
  companyUuid: 'payer.companyUuid',
  agentUuid: 'payer.agentUuid',
}

export interface IPayersInfo {
  allDisabled?: boolean
  firstNameDisabled?: boolean
  lastNameDisabled?: boolean
  emailDisabled?: boolean
  companyNameDisabled?: boolean
  title?: string
  subTitle?: string
  titleClassName?: string
  subTitleClassName?: string
  sectionClassName?: string
  customHeader?: ReactNode
}

export interface IChosenAgentData {
  firstName: string
  lastName: string
  uuid: string
}

export const buildQuery = (entries: [key: string, value: string][]) => {
  return Object.fromEntries(new Map(entries))
}

export const getAgentQuery = (fName: string | undefined, lName: string | undefined) => {
  const arr: [key: string, value: string][] = []
  fName && arr.push(['filter[filterField]', 'firstName'], ['filter[filterValue]', fName || ''])
  lName && arr.push(['filter[filterField]', 'lastName'], ['filter[filterValue]', lName || ''])

  return buildQuery(arr)
}

export const getCompanyQuery = (cName: string | undefined) => {
  const arr: [key: string, value: string][] = []
  cName && arr.push(['filter[filterField]', 'name'], ['filter[filterValue]', cName || ''])

  return buildQuery(arr)
}
