import clsx from 'clsx'
import { ComponentProps } from 'react'

import classes from './flex.module.css'

interface FlexProps extends ComponentProps<'div'> {
  alignItems?: 'center' | 'start' | 'end'
  className?: string
  direction?: 'column' | 'row'
  gap?: 'none' | 'small' | 'tiny'
  inline?: boolean
  justifyContent?: 'space-between' | 'start'
}

export const Flex = ({
  children,
  className,
  alignItems,
  direction = 'row',
  gap,
  inline = false,
  justifyContent,
}: FlexProps) => {
  return (
    <div
      className={clsx(className, {
        [classes.gap!]: gap === undefined,
        [classes.gapSmall!]: gap === 'small',
        [classes.gapTiny!]: gap === 'tiny',
        [classes.flex!]: inline === false,
        [classes.inlineFlex!]: inline === true,
        [classes.flexAlignItemsCenter!]: alignItems === 'center',
        [classes.flexAlignItemsStart!]: alignItems === 'start',
        [classes.flexAlignItemsEnd!]: alignItems === 'end',
        [classes.flexDirectionColumn!]: direction === 'column',
        [classes.flexJustifyContentSpaceBetween!]: justifyContent === 'space-between',
        [classes.flexJustifyContentStart!]: justifyContent === 'start',
      })}
    >
      {children}
    </div>
  )
}
