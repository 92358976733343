import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

type Handler = (...evts: any[]) => void

interface IUseIsChangeRouting {
  onChangeComplete?: Handler
  detectChangingOnlyOnSamePage?: boolean
}

export const useIsChangeRouting = ({
  onChangeComplete,
  detectChangingOnlyOnSamePage = true,
}: IUseIsChangeRouting = {}) => {
  const { events, pathname } = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleStart = (nextPath: string) => {
      if (detectChangingOnlyOnSamePage && pathname !== nextPath) return
      setLoading(true)
    }
    const handleComplete = (newPath: string) => {
      if (detectChangingOnlyOnSamePage && pathname !== newPath) {
        onChangeComplete && onChangeComplete(newPath)
      }
      setLoading(false)
    }

    events.on('routeChangeStart', handleStart)
    events.on('routeChangeComplete', handleComplete)
    events.on('routeChangeError', handleComplete)

    return () => {
      events.off('routeChangeStart', handleStart)
      events.off('routeChangeComplete', handleComplete)
      events.off('routeChangeError', handleComplete)
    }
  }, [detectChangingOnlyOnSamePage, events, onChangeComplete, pathname])

  return loading
}
