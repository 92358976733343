import { NestController } from 'api/nest/NestController'

export class LegalDocumentsController extends NestController {
  constructor() {
    super({ controller: 'legal-documents' })
  }

  public getDisclosuresPdfUrl = () => {
    return `${this.request.defaults.baseURL}/disclosures`
  }
}
