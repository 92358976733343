import { StrapiCollection } from 'api/strapi/index'

import { Testimonial } from './types'

export type { Testimonial }

export class TestimonialsCollection extends StrapiCollection<Testimonial> {
  constructor() {
    super({ controller: 'testimonials' })
  }
  override defaultFindParams = {
    populate: ['photo'],
    sort: 'order',
  }
}
