import { FC } from 'react'
import { ToastContainer } from 'react-toastify'

import classes from './toaster.module.css'

interface ToasterProps {}

export const Toaster: FC<ToasterProps> = () => {
  return <ToastContainer newestOnTop toastClassName={classes.toast} />
}
