import { FC, MouseEventHandler, ReactNode, useCallback, useRef } from 'react'
import { useKey } from 'react-use'

import { Portal } from 'components/portal'
import { cn } from 'utils/cn'
import { NoSsr } from 'components/no-ssr'
import { Heading } from 'components/heading'
import { useLockBodyScroll } from 'utils/use-lock-body'
import { DialogContext } from 'components/dialog/utils'

import { CloseDialogButton } from './CloseIcon'
import styles from './dialog.module.css'

type Theme = 'fullScreen' | 'onlyContent'

interface ThemeClasses {
  mainContainer?: string
  dialog?: string
  headerContainer?: string
  header?: string
  closeIcon?: string
  content?: string
  body?: string
}

const THEME: Record<Theme, ThemeClasses> = {
  fullScreen: {
    content: styles.fullscreenThemeContent,
    dialog: styles.fullscreenThemeDialog,
    body: styles.fullscreenThemeBody,
  },
  onlyContent: {
    content: styles.onlyContentThemeContent,
    body: styles.onlyContentThemeBody,
  },
}

export interface DialogProps {
  onClose: () => void
  open: boolean
  children?: ReactNode
  title?: ReactNode
  className?: string
  classes?: ThemeClasses
  withoutHeader?: boolean
  theme?: Theme
  withAnimation?: boolean
  preventNativeClose?: boolean
}

const _Dialog = ({
  onClose,
  children = null,
  title,
  className,
  classes,
  withoutHeader,
  theme,
  withAnimation = true,
  preventNativeClose, // to prevent unintentional dialog close
}: DialogProps) => {
  const contentElement = useRef(null)
  useKey('Escape', preventNativeClose ? () => null : onClose, undefined, [
    onClose,
    preventNativeClose,
  ])
  useLockBodyScroll()

  const themeClasses = theme ? THEME[theme] : {}

  const onCloseClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      preventNativeClose ? () => null : onClose()
    },
    [onClose, preventNativeClose],
  )

  return (
    <Portal>
      <div className={cn(styles.mainContainer, themeClasses.mainContainer, classes?.mainContainer)}>
        <div className={styles.overlay} onClick={onCloseClick} />
        <div
          className={cn(styles.dialog, themeClasses.dialog, className, classes?.dialog)}
          ref={contentElement}
        >
          <section
            className={cn(
              styles.content,
              themeClasses.content,
              withAnimation && styles.animation,
              classes?.content,
            )}
          >
            {!withoutHeader && (
              <header className={cn(styles.headerContainer, classes?.headerContainer)}>
                {title && (
                  <Heading theme="h4" component="h5" className={cn(styles.header, classes?.header)}>
                    {title}
                  </Heading>
                )}
                <CloseDialogButton onClose={preventNativeClose ? () => null : onClose} />
              </header>
            )}

            <DialogContext.Provider value={{ close: onClose }}>
              <main className={cn(styles.body, themeClasses.body, classes?.body)}>{children}</main>
            </DialogContext.Provider>
          </section>
        </div>
      </div>
    </Portal>
  )
}

export const Dialog: FC<DialogProps> = ({ open, ...props }) => {
  if (!open) return null

  return (
    <NoSsr>
      <_Dialog open {...props} />
    </NoSsr>
  )
}
