import { useMemo, FC } from 'react'

import {
  ExtendSelectFormFieldProps,
  SelectArrayOption,
} from 'components/form-field/SelectFormField/types'
import { SelectFormField } from 'components/form-field/SelectFormField/SelectFormField'
import { FORM_FIELD_LABEL } from 'components/form-field/consts'
import { cn } from 'utils'
import { MarketModel } from 'api/nest'

interface SelectMarketFormFieldProps extends Omit<ExtendSelectFormFieldProps, 'options'> {
  options: MarketModel[]
}

export const SelectMarketFormField: FC<SelectMarketFormFieldProps> = ({
  autoCompleteGroup,
  options,
  ...props
}) => {
  const markets: SelectArrayOption[] = useMemo(() => {
    return options.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }))
  }, [options])

  return (
    <SelectFormField
      options={markets}
      label={props.label ?? FORM_FIELD_LABEL.state}
      autoComplete={cn(autoCompleteGroup, 'address-level1')}
      {...props}
    />
  )
}
