import { StrapiCollection } from 'api/strapi/index'

import { UseCase } from './types'

export class UseCaseCollection extends StrapiCollection<UseCase> {
  constructor() {
    super({ controller: 'use-cases' })
  }

  override defaultFindParams = {
    sort: ['order:asc'],
  }
}
