import { ChangeEvent, useCallback, useEffect, useRef, useState, FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { LabelProps } from 'recharts'

import { Avatar } from 'components/avatar'
import { cn } from 'utils'
import { Heading } from 'components/heading'
import { bytesToMb } from 'utils/number'
import { ErrorMessage } from 'components/error-message'
import { Label } from 'components/label'

import classes from './LoadAvatarFormField.module.css'

const SUPPORTED_FILE_TYPES = {
  'image/gif': ['gif'],
  'image/jpeg': ['jpg', 'jpeg'],
  'image/png': ['png'],
}

const SUPPORTED_FILE_EXTENSIONS = Object.keys(SUPPORTED_FILE_TYPES)
const USER_FRIENDLY_FILE_TYPES = Object.values(SUPPORTED_FILE_TYPES).flat().join(', ')

const MAX_FILE_SIZE_MB = 2

interface LoadFileFormFieldProps extends LabelProps {
  name: string
  label?: string
  classNameLabel?: string
  optional?: boolean
}

export const LoadAvatarFormField: FC<LoadFileFormFieldProps> = ({
  name,
  label,
  optional,
  classNameLabel,
  className,
}) => {
  const inputElement = useRef<HTMLInputElement | null>(null)
  const {
    setError,
    clearErrors,
    setValue,
    register,
    formState: { errors },
  } = useFormContext()

  const [fileUrl, serFileUrl] = useState<string | null>(null)

  const validateFile = useCallback(
    (file: File) => {
      if (bytesToMb(file.size) > MAX_FILE_SIZE_MB) {
        setError(
          name,
          { message: `File cannot be larger than ${MAX_FILE_SIZE_MB} MB` },
          { shouldFocus: false },
        )
        return false
      }

      if (!SUPPORTED_FILE_EXTENSIONS.includes(file.type)) {
        setError(
          name,
          {
            message: `Please select supported file type: ${USER_FRIENDLY_FILE_TYPES}`,
          },
          { shouldFocus: false },
        )
        return false
      }

      return true
    },
    [name, setError],
  )

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]

      if (file && validateFile(file)) {
        clearErrors(name)
        setValue(name, file)
        serFileUrl(URL.createObjectURL(file).toString())
      }
    },
    [validateFile, clearErrors, name, setValue],
  )

  const onClickAvatar = () => {
    inputElement.current?.click()
  }

  const handleFileRemove = useCallback(() => {
    serFileUrl(null)
    setValue(name, null)
  }, [name, setValue])

  useEffect(() => {
    register(name)
  }, [name, register])

  const fieldError = errors[name]

  return (
    <div className={className}>
      <Label optional={optional} className={cn(classes.label, classNameLabel)}>
        {label}
      </Label>
      <div className={classes.contentWrapper}>
        <Avatar src={fileUrl} onClick={onClickAvatar} className={classes.avatar} size={90} />

        <div
          className={cn(classes.info, fileUrl && classes.uploadedInfo, fieldError && classes.error)}
        >
          <Heading theme="h5" className={classes.title}>
            Update your profile photo
          </Heading>
          <label className={cn(classes.controlBtn, classes.uploadBtn)}>
            <input
              ref={inputElement}
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              className="visuallyHidden"
              onChange={handleFileChange}
            />
            Upload {fileUrl && 'new'} photo
          </label>
          <p className={classes.content}>
            GIF, PNG or JPEG formats are supported.
            <br /> Maximum file size 2 MB.
          </p>
          <button
            type="button"
            onClick={handleFileRemove}
            className={cn(classes.controlBtn, classes.removeBtn)}
          >
            Remove
          </button>
          {fieldError && (
            <ErrorMessage className={classes.error}>{fieldError.message}</ErrorMessage>
          )}
        </div>
      </div>
    </div>
  )
}
