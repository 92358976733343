import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import {
  AchAuthRequestModel,
  AddCreditCardToInvestorRequestModel,
  AddCreditCardToInvestorResponseModel,
  PlaidLinkModel,
} from 'api/nest/north-capital/types'

export class NorthCapitalController extends NestController {
  constructor() {
    super({ controller: 'north-capital' })
  }

  public getPlaidLink = (investorUuid: string) =>
    this.post<PlaidLinkModel>('/plaid', { accountId: investorUuid })

  public achAuth = (data: AchAuthRequestModel, options?: AxiosRequestConfig) => {
    return this.post('/ach', data, options)
  }

  public changeInvestorCreditCard = (
    data: AddCreditCardToInvestorRequestModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.patch<AddCreditCardToInvestorResponseModel>('/credit-card', data, options)
  }

  public addCreditCardToInvestor = (
    data: AddCreditCardToInvestorRequestModel,
    options?: AxiosRequestConfig,
  ) => {
    return this.post<AddCreditCardToInvestorResponseModel>('/credit-card', data, options)
  }
}
