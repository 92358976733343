import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState, FC } from 'react'
import { toast } from 'react-toastify'

import { nest } from 'api'
import { ReferralModel } from 'api/nest'
import { ConfirmDialog } from 'components/confirm-dialog'
import { QUERY_KEY } from 'const/query'
import WarningIcon from 'icons/warning.svg'

import classes from './confirm-delete.module.css'

interface ConfirmDeleteProps {
  deletedRowId: ReferralModel['uuid']
  onClose: () => void
}

export const ConfirmDelete: FC<ConfirmDeleteProps> = ({ deletedRowId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()

  const confirmDelete = useCallback(async () => {
    try {
      setIsLoading(true)
      await nest.referrals.delete(deletedRowId)
      queryClient.invalidateQueries([QUERY_KEY.referrals])
      toast.success('Referral deleted successfully')
      onClose()
    } catch (e: unknown) {
      toast.error('Error deleting referral. Please try again later')
    } finally {
      setIsLoading(false)
    }
  }, [deletedRowId, onClose, queryClient])

  return (
    <ConfirmDialog
      onConfirm={confirmDelete}
      onCancel={onClose}
      open={true}
      title="Delete referral?"
      loading={isLoading}
    >
      <p className={classes.content}>
        <WarningIcon className={classes.icon} />
        Are you sure you want to delete this referral? Once deleted, it cannot be recovered, and you
        will need to re-enter and submit your customer’s information again.
      </p>
    </ConfirmDialog>
  )
}
