import { ComponentProps, memo, useId } from 'react'

import { InfoTooltip } from 'components/info-tooltip'
import { InfoIcon } from 'components/info-icon'
import { withNoSsr } from 'components/no-ssr'
import { cn } from 'utils'

import classes from './help-tooltip.module.css'

interface HelpTooltipProps {
  className?: string
  textBoxClassName?: string
}

const _HelpTooltip: FCRC<HelpTooltipProps> = ({ className, textBoxClassName, children }) => {
  const id = useId()

  return (
    <>
      <InfoIcon data-tip="" data-for={id} className={className} />
      <InfoTooltip
        id={id}
        clickable
        delayHide={500}
        className={cn(classes.fieldTooltip, textBoxClassName)}
      >
        {children}
      </InfoTooltip>
    </>
  )
}
_HelpTooltip.displayName = 'HelpTooltip'

export const HelpTooltip = memo<ComponentProps<typeof _HelpTooltip>>(withNoSsr(_HelpTooltip))
