import { SelectArrayOption } from 'components/form-field'
import { sortByAlphabet } from 'utils/sorts'

export const enum IAmType {
  OWNER = 'owner',
  INVESTOR = 'investor',
  INSTITUTION = 'institution',
  REAL_ESTATE_BROKER = 'real-estate-broker',
  MEDIA = 'media',
  FINANCIAL_ADVISOR = 'financial-advisor',
  BUILDER = 'builder',
  INSURANCE_AGENT = 'insurance-agent',
  LOAN_OFFICER = 'loan-officer',
  REAL_ESTATE_AGENT = 'real-estate-agent',
  OTHER = 'other',
}

export const enum IAmTypePartnerOptions {
  REGISTERED_PARTNER = 'registered-partner',
  NEW_PARTNER = 'new-partner',
  SUPERVISOR = 'supervisor',
  OTHER = 'other',
}

const sortOptions = (options: SelectArrayOption[]) => {
  options.sort((a, b) => sortByAlphabet(a.label, b.label))
  options.push({
    label: 'Other',
    value: IAmType.OTHER,
  })

  return options
}

const I_AM_TYPE_SELECT_OPTIONS: SelectArrayOption<IAmType>[] = [
  {
    label: 'Home Owner/Buyer',
    value: IAmType.OWNER,
  },
  {
    label: 'Investor',
    value: IAmType.INVESTOR,
  },
  {
    label: 'Institution',
    value: IAmType.INSTITUTION,
  },
  {
    label: 'Real Estate Broker',
    value: IAmType.REAL_ESTATE_BROKER,
  },
  {
    label: 'Media Representative',
    value: IAmType.MEDIA,
  },
  {
    label: 'Financial Advisor',
    value: IAmType.FINANCIAL_ADVISOR,
  },
  {
    label: 'Builder',
    value: IAmType.BUILDER,
  },
  {
    label: 'Insurance Agent',
    value: IAmType.INSURANCE_AGENT,
  },
  {
    label: 'Loan Officer',
    value: IAmType.LOAN_OFFICER,
  },
  {
    label: 'Real Estate Agent',
    value: IAmType.REAL_ESTATE_AGENT,
  },
]

const I_AM_TYPE_PARTNER_SELECT_OPTIONS: SelectArrayOption<IAmTypePartnerOptions | IAmType.OTHER>[] =
  [
    {
      label: 'Registered Partner',
      value: IAmTypePartnerOptions.REGISTERED_PARTNER,
    },

    {
      label: 'New Partner',
      value: IAmTypePartnerOptions.NEW_PARTNER,
    },
    {
      label: 'Supervisor',
      value: IAmTypePartnerOptions.SUPERVISOR,
    },
    {
      label: 'Other',
      value: IAmType.OTHER,
    },
  ]

sortOptions(I_AM_TYPE_SELECT_OPTIONS)
export { I_AM_TYPE_PARTNER_SELECT_OPTIONS, I_AM_TYPE_SELECT_OPTIONS }
