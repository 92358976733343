import { FC, forwardRef, RefObject, SVGProps } from 'react'
import { useClickAway, useKey } from 'react-use'

import { cn } from 'utils'
import { NavLink } from 'components/links'
import { routes } from 'utils/routes'
import ProfileIcon from 'icons/profile.svg'
import ExitIcon from 'icons/exit.svg'

import classes from './user-nav.module.css'

interface UserNavProps {
  close: () => void
}

interface ILink {
  label: string
  to: string
  description: string
  prefetch?: boolean
  icon: FC<SVGProps<SVGElement>>
}

const LINKS: ILink[] = [
  {
    icon: ProfileIcon,
    label: 'My profile',
    description: 'Manage your profile settings, email and password.',
    to: routes.partnerPortalProfile,
  },
  {
    icon: ExitIcon,
    label: 'Logout',
    description: 'End your session and return to REZITRADE.com',
    to: routes.partnerPortalLogout,
    prefetch: false,
  },
]

export const UserNav = forwardRef<HTMLElement, UserNavProps>(({ close }, ref) => {
  useClickAway(ref as RefObject<HTMLElement>, close)
  useKey('Escape', close, undefined, [close])

  return (
    <nav className={cn(classes.navContainer)}>
      <ul className={classes.navList}>
        {LINKS.map(({ label, description, to, icon: Icon, prefetch }) => (
          <li className={classes.navListItem} key={label}>
            <NavLink
              href={to}
              className={classes.link}
              activeClassName={classes.active}
              onClick={close}
              prefetch={prefetch}
            >
              <Icon className={classes.icon} />
              <strong className={classes.label}>{label}</strong>
              <span className={classes.desc}>{description}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
})

UserNav.displayName = 'UserNav'
