import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import {
  CreateOrderModel,
  OrderModel,
  PreviewOrderModel,
  SignOrderModel,
  UpdateOrderModel,
} from 'api/nest/orders/types'

export class OrdersController extends NestController {
  constructor() {
    super({ controller: 'orders' })
  }

  public create = async (data: CreateOrderModel) => {
    return await this.post<OrderModel>('/?paymentSystem=authorize', data)
  }

  public preview = async (data: PreviewOrderModel) => {
    return await this.post<OrderModel>('/preview', data)
  }

  public getById = (uuid: string, options?: AxiosRequestConfig) => {
    return this.get<OrderModel>(`/${uuid}`, options)
  }

  public getDownloadAgreementLink = (uuid: string) => {
    return `${this.request.defaults.baseURL}/${uuid}/agreement.pdf`
  }

  public sign = (uuid: string, newData: SignOrderModel, options?: AxiosRequestConfig) => {
    return this.patch<OrderModel>(`/${uuid}/sign`, newData, options)
  }

  public update = (uuid: string, newData: UpdateOrderModel, options?: AxiosRequestConfig) => {
    return this.patch<OrderModel>(`/${uuid}`, newData, options)
  }
}
