import { FC, useCallback } from 'react'

import { Dialog } from 'components/dialog'
import { DialogProps } from 'components/dialog/Dialog'
import { Button, ButtonProps } from 'components/button'
import { LoadingButton } from 'components/loading-button'

import classes from './confirm-dialog.module.css'

interface ConfirmDialogProps extends Omit<DialogProps, 'onClose'> {
  onConfirm: () => void
  onCancel: () => void
  confirmButtonText?: string
  cancelButtonText?: string
  loading?: boolean
  confirmButtonTheme?: ButtonProps['theme']
  cancelButtonTheme?: ButtonProps['theme']
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  onCancel,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  cancelButtonTheme,
  confirmButtonTheme,
  title = 'You sure?',
  children,
  loading,
  ...rest
}) => {
  const innerCancel = useCallback(() => {
    !loading && onCancel()
  }, [loading, onCancel])

  return (
    <Dialog onClose={innerCancel} title={title} {...rest}>
      {children}
      <div className={classes.actions}>
        <LoadingButton
          onClick={onConfirm}
          theme={confirmButtonTheme ?? 'primaryDanger'}
          loading={!!loading}
          autoFocus
        >
          {confirmButtonText}
        </LoadingButton>
        <Button onClick={innerCancel} theme={cancelButtonTheme ?? 'text'}>
          {cancelButtonText}
        </Button>
      </div>
    </Dialog>
  )
}
