import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { HelpTooltip } from 'components/help-tooltip'
import { nest } from 'api'
import { Dialog, DialogProps } from 'components/dialog/Dialog'
import { Heading } from 'components/heading'
import { LinkButton } from 'components/links'
import { Flex } from 'components/flex'
import { Text } from 'components/text'
import { PurchaseTypeEnum } from 'enum/purchaser-type'
import { FlowUserRole } from 'enum/flow-user-role'
import { routes } from 'utils/routes'
import { useFlowStorage } from 'utils/use-flow-storage'

import { useSessionStorage } from '../../../utils/use-session-storage'
import classes from './add-order-dialog.module.css'

export const AddOrderDialog = (
  props: DialogProps & {
    isEligibleForDeferredPayments: boolean
    setDialogOpen: (arg: boolean) => void
  },
) => {
  const [, setFlowStorageData] = useFlowStorage()
  const [, setMaxPayout] = useSessionStorage<number | undefined>('maxPayout', undefined)

  const { push } = useRouter()
  const meQuery = useQuery(['me'], () => nest.auth.me(), {
    enabled: props.open,
  })

  const popupData = [
    {
      purchaseType: PurchaseTypeEnum.CoveredByPartner,
      title: 'PARTNER',
      description: 'You cover the contract cost for the homeowner.',
      tooltip: "Opt for this if you're paying for the contract as a gesture, like a closing gift.",
    },
    {
      purchaseType: PurchaseTypeEnum.PaidByHomeowner,
      title: 'HOMEOWNER',
      description: 'Homeowner directly pays; you facilitate arranging the contract.',
      tooltip:
        'Choose this if the homeowner is responsible for payment, with your guidance to navigate the contract setup.',
    },
    {
      purchaseType: PurchaseTypeEnum.PaidByThirdParty,
      title: 'THIRD-PARTY',
      description: 'A third party pays; you coordinate setting up the contract.',
      tooltip:
        "Select this when you're arranging the contract with payment from another party, like a family member or the home seller.",
    },
  ]

  const getPartnerFlows = () => {
    if (props.isEligibleForDeferredPayments) {
      popupData.unshift({
        purchaseType: PurchaseTypeEnum.PartnerDeferredPayment,
        title: 'ADMIN',
        description: 'Admin manages contract setup and payment options.',
        tooltip:
          "Opt for this if you're managing both contract setup and payment, whether processing directly or arranging alternatives like partner invoicing.",
      })
    }

    return popupData
  }

  const handleClick = (purchaseType: PurchaseTypeEnum) => {
    props.setDialogOpen(false)
    setFlowStorageData({
      broker: meQuery.data?.broker,
      purchaseType,
      role: FlowUserRole.Partner,
    })
    setMaxPayout(undefined)
    push(routes.partnerPortalNewOrderStepMarket)
  }

  return (
    <Dialog {...props} withoutHeader>
      <Flex direction="column">
        <Heading className={classes.heading} theme="h3">
          Contract Purchase: Who&apos;s Responsible for Payment?
        </Heading>
        {getPartnerFlows().map(({ purchaseType, title, description, tooltip }) => (
          <Flex alignItems="center" key={title + purchaseType}>
            <LinkButton
              className={classes.button}
              theme="secondary"
              onClick={() => handleClick(purchaseType)}
            >
              {title}
            </LinkButton>
            <HelpTooltip textBoxClassName={classes.tooltipBox}>
              <Text fontSize="s">{tooltip}</Text>
            </HelpTooltip>
            <Text fontSize="s">{description}</Text>
          </Flex>
        ))}
      </Flex>
    </Dialog>
  )
}
