import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'

import { PostalCodeModel } from './types'

export class PostalCodesController extends NestController {
  constructor() {
    super({ controller: 'postal-codes' })
  }

  public findByPostalCode = (postalCode: string, options?: AxiosRequestConfig) => {
    return this.get<PostalCodeModel>(postalCode, options)
  }
}
