import { FC } from 'react'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { COMPANY_NAME, FORM_FIELD_LABEL } from '../consts'

export const CompanyNameFormField: FC<ExtendTextFieldProps> = ({ autoCompleteGroup, ...props }) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.companyName}
      maxLength={COMPANY_NAME.maxLength}
      minLength={COMPANY_NAME.minLength}
      {...props}
    />
  )
}
