import { FormNameType } from 'utils/types'
import { StrapiSortKey } from 'api/strapi'
import { SelectArrayOption } from 'components/form-field'
import { Article } from 'api'

import { ArticleSearchFormModel } from './search-form'

export const ARTICLES_SEARCH_FORM_FIELD: FormNameType<ArticleSearchFormModel> = {
  search: 'search',
  tagSlug: 'tagSlug',
  sort: 'sort',
}

export const ALL_TAGS_OPTION_SLUG = 'all'

export const SORT_OPTIONS: SelectArrayOption<StrapiSortKey<keyof Article>>[] = [
  {
    value: 'custom_published_at:desc',
    label: 'Newest',
  },
  {
    value: 'custom_published_at:asc',
    label: 'Oldest',
  },
]
