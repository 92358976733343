import NextImage, { StaticImageData } from 'next/image'

import { Link } from 'components/links'
import { LandingPageTypeEnum } from 'components/header/default-header/utils'
import { InfoTooltip } from 'components/info-tooltip'

import classes from './footer.module.css'
import {
  FIRST_NAV_LIST,
  SECOND_NAV_LIST,
  CLIENTS_FIRST_NAV_LIST,
  CLIENTS_SECOND_NAV_LIST,
  AGENTS_FIRST_NAV_LIST,
  AGENTS_SECOND_NAV_LIST,
} from './consts'

interface ISocialMediaIconBuilder {
  href: string
  src: StaticImageData
  alt: string
  tooltip: string
}

export const socialMediaIconBuilder = ({ href, src, alt, tooltip }: ISocialMediaIconBuilder) => {
  return (
    <Link
      href={href}
      target="_blank"
      key={href}
      className={classes.navLink}
      data-tip=""
      data-for={tooltip}
    >
      <NextImage
        src={src}
        alt={alt}
        sizes="100vw"
        style={{
          width: '40px',
          height: '40px',
        }}
        className={classes.logo}
      />
      <InfoTooltip id={tooltip} clickable delayHide={100} className={classes.fieldTooltip}>
        {tooltip}
      </InfoTooltip>
    </Link>
  )
}

export const getNavLists = (
  isLandingPage: boolean,
  landingPageType: LandingPageTypeEnum | null,
) => {
  if (isLandingPage && landingPageType) {
    switch (landingPageType) {
      case LandingPageTypeEnum.clients:
        return {
          firstNavList: CLIENTS_FIRST_NAV_LIST,
          secondNavList: CLIENTS_SECOND_NAV_LIST,
        }
      case LandingPageTypeEnum.agents:
        return {
          firstNavList: AGENTS_FIRST_NAV_LIST,
          secondNavList: AGENTS_SECOND_NAV_LIST,
        }

      default:
        break
    }
  }

  return {
    firstNavList: FIRST_NAV_LIST,
    secondNavList: SECOND_NAV_LIST,
  }
}
