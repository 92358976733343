import { FC } from 'react'

import { useIsChangeRouting } from 'utils/use-page-router-event'

import classes from './global-page-loader.module.css'

interface GlobalPageLoaderProps {}

export const GlobalPageLoader: FC<GlobalPageLoaderProps> = () => {
  const isChangingRoute = useIsChangeRouting({ detectChangingOnlyOnSamePage: false })

  if (!isChangingRoute) {
    return null
  }

  return (
    <div className={classes.loader} title="Redirecting...">
      <span />
      <span />
    </div>
  )
}
