import { FormNameType } from 'utils/types'
import { yup, YUP_ERROR_MESSAGE } from 'components/form/yup'
import { EMAIL_RULE, FIRST_NAME_RULE, LAST_NAME_RULE, ZIP_CODE_RULE } from 'components/form-field'

import { ISubscribeForm } from './types'

export const SUBSCRIBE_FIELDS_NAME: FormNameType<ISubscribeForm> = {
  lastName: 'lastName',
  firstName: 'firstName',
  email: 'email',
  postalCode: 'postalCode',
  whoAmI: 'whoAmI',
  agree: 'agree',
}

export const SUBSCRIBE_VALIDATOR = yup.object().shape({
  [SUBSCRIBE_FIELDS_NAME.firstName]: FIRST_NAME_RULE,
  [SUBSCRIBE_FIELDS_NAME.lastName]: LAST_NAME_RULE,
  [SUBSCRIBE_FIELDS_NAME.email]: EMAIL_RULE,
  [SUBSCRIBE_FIELDS_NAME.postalCode]: ZIP_CODE_RULE,
  [SUBSCRIBE_FIELDS_NAME.whoAmI]: yup.string().required(),
  [SUBSCRIBE_FIELDS_NAME.agree]: yup
    .boolean()
    .required()
    .oneOf([true], YUP_ERROR_MESSAGE.mixed?.required),
})
