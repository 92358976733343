import { NestController } from 'api/nest/NestController'

import { ISubscribeFormData } from './types'

export class HomeSellers extends NestController {
  constructor() {
    super({ controller: 'sellers' })
  }

  public subscribe(data: ISubscribeFormData) {
    return this.post<ISubscribeFormData>('/', data)
  }
}
