import { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { IS_DEVELOPMENT_MODE } from 'const/config'
import { cn } from 'utils'

import classes from './portal.module.css'

interface IPortal {
  children: ReactNode
  className?: string
  /** default - inner to body */
  parentSelector?: string
  /** @default div */
  el?: 'div' | 'section'
}

/**
 * Portal doesn't work with srr(document not found)
 * Use next/dynamic for component where use Portal with srr=false option
 */
export const Portal = ({
  children,
  className = 'root-portal',
  el = 'div',
  parentSelector,
}: IPortal) => {
  const [container] = useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement(el)
  })

  useEffect(() => {
    container.classList.add(cn(classes.portal), className)
    const parentElement = parentSelector ? document.querySelector(parentSelector) : document.body

    if (!parentElement) {
      // eslint-disable-next-line no-console
      if (IS_DEVELOPMENT_MODE) console.error('Parent element for portal not found')
      return
    }

    parentElement.appendChild(container)
    return () => {
      parentElement.removeChild(container)
    }
  }, [className, container, parentSelector])

  return ReactDOM.createPortal(children, container)
}
