import { AxiosRequestConfig } from 'axios'

import { Article, SearchArticleModel } from 'api/strapi/articles/types'
import { StrapiCollection, StrapiQuery, StrapiSort } from 'api/strapi/index'
import { ALL_TAGS_OPTION_SLUG } from 'sections/articles/search-form/consts'

export class ArticlesCollection extends StrapiCollection<Article> {
  constructor() {
    super({ controller: 'articles' })
  }

  static defaultSort: StrapiQuery<Article>['sort'] = ['custom_published_at:desc']

  override defaultFindOneParams = {
    populate: {
      related_articles: {
        populate: ['cover', 'tags'],
      },
      tags: {
        fields: '*',
      },
      cover: {
        fields: '*',
      },
      content_sections: {
        populate: [
          'team_people.photo',
          'image',
          'first_section',
          'first_section_options',
          'second_section',
          'second_section_image',
          'second_section_options',
          '*',
        ],
        fields: '*',
      },
    },
  }

  override defaultFindParams = {
    populate: ['cover', 'tags'],
  }

  private getFiltersForSearch({ search, tagSlug }: SearchArticleModel) {
    const filters = {}

    if (tagSlug && tagSlug !== ALL_TAGS_OPTION_SLUG) {
      filters['tags'] = { slug: { $eq: tagSlug } }
    }

    if (search) {
      const searchValue = { $containsi: search.trim() }
      filters['$or'] = [
        { title: searchValue },
        { description: searchValue },
        { content_for_search: searchValue },
      ]
    }

    return filters
  }

  articlesPageSearch = (params: SearchArticleModel, config?: AxiosRequestConfig) => {
    const { sort, page } = params

    return this.find(
      {
        pagination: {
          page: page,
          pageSize: 12,
        },
        filters: this.getFiltersForSearch(params),
        sort: [sort ?? ArticlesCollection.defaultSort] as StrapiSort<Article>,
      },
      config,
    )
  }

  async findFeatured(): Promise<Article | undefined> {
    const { items } = await this.find({
      featured: true,
      pagination: { limit: 1 },
      sort: ['custom_published_at:desc'],
    })
    return items?.[0] || undefined
  }

  async findOneBySlug(searchSlug: Article['slug']): Promise<Article | undefined> {
    const { items } = await this.find({
      filters: {
        slug: { $eq: searchSlug },
      },
      // @ts-ignore
      populate: this.defaultFindOneParams.populate,
    })

    return items?.[0] || undefined
  }
}
