import { FC } from 'react'

import { ReferralModel } from 'api/nest'
import { usdFormatter } from 'utils'

import { DEFAULT_VALUE } from '../consts'
import classes from './amount-info.module.css'

interface AmountInfoProps {
  referral: ReferralModel
}

export const AmountInfo: FC<AmountInfoProps> = ({ referral }) => {
  const { referralAmount, orderAmount } = referral

  if (!referralAmount && !orderAmount) {
    return <>{DEFAULT_VALUE}</>
  }

  return (
    <span className={classes.container}>
      {referralAmount && (
        <span className={classes.additionalInfo}>
          Referral:
          <strong> {usdFormatter(referralAmount)}</strong>
        </span>
      )}
      {orderAmount && (
        <span className={classes.additionalInfo}>
          Order:
          <strong> {usdFormatter(orderAmount)}</strong>
        </span>
      )}
    </span>
  )
}
