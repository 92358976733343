import { ComponentProps, FC, forwardRef, memo, ReactNode } from 'react'

import { cn } from 'utils'

import classes from './Alert.module.css'

export interface AlertProps {
  theme?: 'info' | 'error' | 'warning'
  fullWidth?: boolean
  className?: string
  icon?: FC
  children: ReactNode
}

const _Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ children, theme = 'info', fullWidth, className, icon: Icon }, ref) => {
    return (
      <div
        className={cn(
          classes.alert,
          fullWidth && classes.fullWidth,
          !!Icon && classes.withIcon,
          classes[`${theme}Theme`],
          className,
        )}
        ref={ref}
        role={theme === 'error' ? 'alert' : 'note'}
      >
        {Icon && (
          <div className={classes.icon}>
            <Icon />
          </div>
        )}
        {children}
      </div>
    )
  },
)

_Alert.displayName = 'Alert'

export const Alert = memo<ComponentProps<typeof _Alert>>(_Alert)
