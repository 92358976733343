import { AxiosRequestConfig } from 'axios'

import { NestController } from 'api/nest/NestController'
import {
  PurchaserCreateModel,
  PurchaserModel,
  PurchaserUpdateModel,
} from 'api/nest/purchaser/types'

export class PurchaserController extends NestController {
  constructor() {
    super({ controller: 'purchasers' })
  }

  public confirmEmail = (email: string) => {
    return this.post<'Success'>(`/confirm-email`, { email })
  }

  public create = (data: PurchaserCreateModel, options?: AxiosRequestConfig) => {
    return this.post<PurchaserModel>(`/`, data, options)
  }

  public getByEmail = (email: string) => {
    return this.get<PurchaserModel>(`/email`, { email })
  }

  public getByUuid = (uuid: string) => {
    return this.get<PurchaserModel>(`/${uuid}`)
  }

  public update = (uuid: string, data: PurchaserUpdateModel) => {
    return this.patch<PurchaserModel>(`/${uuid}`, data)
  }

  public validate = ({
    uuid,
    ...params
  }: {
    uuid: string
    token: string
    isActivationLink?: boolean
  }) => {
    return this.post<'Success'>(`/${uuid}/validate`, params)
  }
}
