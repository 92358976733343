import { ReactNode } from 'react'

import { Alert } from 'components/Alert'
import { NestController } from 'api/nest/NestController'
import { SHORT_DEFAULT_ERROR_MESSAGE } from 'api/request'
import { ValidationErrorList } from 'components/async-data/validation-error-list'
import { ContactOurAgent } from 'components/support-link'
import { cn } from 'utils'
import { AlertProps } from 'components/Alert/Alert'
import WarningIcon from 'icons/warning.svg'

import classes from './async-error.module.css'

export interface AsyncErrorProps extends Omit<AlertProps, 'theme' | 'children'> {
  error: unknown
  errorParser?: (error: unknown) => string
  children?: ReactNode
  withContactUs?: boolean
  className?: string
}

export const AsyncError = ({
  errorParser,
  error,
  children,
  withContactUs = true,
  className,
  ...props
}: AsyncErrorProps) => {
  if (!error) {
    return null
  }
  /* prettier-ignore */
  const errorInfo =
    errorParser
      ? errorParser(error)
      : typeof error === 'string'
        ? error
        : NestController.getResponseError(error, SHORT_DEFAULT_ERROR_MESSAGE)

  if (!errorInfo) {
    return null
  }

  return (
    <Alert
      theme="error"
      fullWidth
      className={cn(classes.error, className)}
      {...props}
      icon={WarningIcon}
    >
      {typeof errorInfo === 'string' ? (
        <span className={classes.errorMessage}>{errorInfo}</span>
      ) : (
        <>
          Bad request data
          <ValidationErrorList errors={errorInfo} />
        </>
      )}
      <p>{children}</p>
      {withContactUs && (
        <p>
          If the problem persists, please <ContactOurAgent />
        </p>
      )}
    </Alert>
  )
}
