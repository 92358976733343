import { useRef, FC } from 'react'
import { useToggle } from 'react-use'

import { cn, usdFormatter } from 'utils'
import { Avatar } from 'components/avatar'
import Chevron from 'icons/chevron-down.svg'
import { UserNav } from 'components/header/partner-portal-header/user-nav'
import { BrokerModelWithPermissions } from 'utils/get-server-side-props-utils/withAuthUser'
import { canSeeCommissionDetails } from 'utils/partner-portal-helpers'

import classes from './user-info.module.css'

interface UserInfoProps {
  user: BrokerModelWithPermissions
}

export const UserInfo: FC<UserInfoProps> = ({ user }) => {
  const [isOpenMenu, toggle] = useToggle(false)
  const navContainer = useRef<HTMLDivElement>(null)

  return (
    <div className={classes.container}>
      {canSeeCommissionDetails(user) && (
        <p className={classes.amountContainer}>
          <span className={classes.text}>Total earned commission</span>
          <strong className={classes.amount}>{usdFormatter(user.totalCommission || 0)}</strong>
        </p>
      )}

      <div className={classes.menuContainer} ref={navContainer}>
        <button className={cn(classes.menuButton, isOpenMenu && classes.showNav)} onClick={toggle}>
          {user.profileImage && <Avatar rounded size={26} className={classes.avatar} />}
          <span className={classes.fullName}>
            {user.firstName} {user.lastName}
          </span>
          <Chevron className={classes.arrow} />
        </button>
        {isOpenMenu && <UserNav close={() => toggle(false)} ref={navContainer} />}
      </div>
    </div>
  )
}
