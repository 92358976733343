import { useForm as useFormReactHookForm, FieldValues } from 'react-hook-form'
import type { UseFormReturn, UseFormProps } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { yup } from 'components/form/yup'

//TODO: add correct type to schema
export function useForm<TFieldValues extends FieldValues = FieldValues, TContext extends any = any>(
  schema: any = yup.object().shape({}),
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<any, any> {
  return useFormReactHookForm({
    delayError: 500,
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    ...props,
  })
}
