import { useRouter } from 'next/router'
import { FC } from 'react'

import { DialogProps } from 'components/dialog/Dialog'
import { Heading } from 'components/heading'
import { LinkButton } from 'components/links'
import { Flex } from 'components/flex'
import { FlowUserRole } from 'enum/flow-user-role'
import { PurchaseTypeEnum } from 'enum/purchaser-type'
import { routes } from 'utils/routes'
import { useFlowStorage } from 'utils/use-flow-storage'
import { getNextFlowStep } from 'utils/use-flow-storage.utils'
import { useControlRouterDialog, withRouterDialog } from 'components/dialog'
import { DIALOG_NAME } from 'const/dialogs'
import { Text } from 'components/text'

import classes from './buy-now-dialog.module.css'
import { useSessionStorage } from '../../utils/use-session-storage'

interface IBuyNowDialogContentProps {
  classes: Record<string, string | undefined>
}

const BuyNowDialogContent: FC<IBuyNowDialogContentProps & DialogProps> = (props) => {
  const { close } = useControlRouterDialog()
  const [flowStorageData, setFlowStorageData] = useFlowStorage()
  const [, setMaxPayout] = useSessionStorage<number | undefined>('maxPayout', undefined)
  const { push } = useRouter()

  const handleClick = (role: FlowUserRole, purchaseType: PurchaseTypeEnum) => {
    const shouldClearStorage = flowStorageData.toClearStorage
    const newFlowStorageData = {
      coverage: shouldClearStorage ? undefined : flowStorageData?.coverage,
      market: shouldClearStorage ? undefined : flowStorageData?.market,
      purchaseType,
      role,
    }
    const maxPayout = shouldClearStorage ? undefined : flowStorageData?.market?.defaultMaxPayout

    close(DIALOG_NAME.buyNowDialog)
    setFlowStorageData(newFlowStorageData)
    setMaxPayout(maxPayout)
    push(getNextFlowStep(newFlowStorageData)?.link || routes.stepMarket)
  }

  return (
    <div className={props.classes?.body}>
      <Heading className={classes.heading} theme="h3">
        Home Price Protection: Who&apos;s it for?
      </Heading>
      <Flex alignItems="center">
        <LinkButton
          theme="secondary"
          onClick={() => handleClick(FlowUserRole.Solo, PurchaseTypeEnum.ForOwnHome)}
          className={classes.btn}
        >
          MY HOME
        </LinkButton>
        <Text fontSize="s">
          If you&apos;re purchasing a contract for your own home, select here.
        </Text>
      </Flex>
      <Flex alignItems="center">
        <LinkButton
          theme="secondary"
          onClick={() => handleClick(FlowUserRole.GiftPurchaser, PurchaseTypeEnum.ForOtherHome)}
          className={classes.btn}
        >
          ANOTHER HOME
        </LinkButton>
        <Text fontSize="s">
          If you&apos;re buying a contract for the benefit of another homeowner, please choose this
          option.
        </Text>
      </Flex>
    </div>
  )
}

export const BuyNowDialog: FC<IBuyNowDialogContentProps> = withRouterDialog(BuyNowDialogContent, {
  modalName: DIALOG_NAME.buyNowDialog,
  theme: 'onlyContent',
  withoutHeader: true,
})
