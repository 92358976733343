import { StrapiController } from 'api/strapi/index'
import { ArticlesCollection } from 'api/strapi/articles'

import {
  AboutUsPage,
  GivingBackPage,
  HowItWorksPage,
  JoinUsPage,
  TheIndexPage,
  WhyBuyPage,
  HomeSellersPage,
} from './types'
import { HomePage, MeetTheTeamPage, OurSolution } from './types'

const ARTICLES_SECTION_OPTIONS = {
  populate: {
    items: {
      populate: '*',
      sort: ArticlesCollection.defaultSort,
    },
  },
}

export class PagesController extends StrapiController {
  homePage() {
    return this.getData<HomePage>('/home', {
      populate: {
        faqs: {
          populate: '*',
        },
        welcome_content: {
          populate: '*',
        },
        cta: {
          populate: '*',
        },
        articles: ARTICLES_SECTION_OPTIONS,
      },
    })
  }

  why() {
    return this.getData<WhyBuyPage>('/why-buy', {
      populate: {
        faqs: {
          populate: '*',
        },
        welcome_content: {
          populate: '*',
        },
        cta: {
          populate: '*',
        },
        articles: ARTICLES_SECTION_OPTIONS,
      },
    })
  }

  how() {
    return this.getData<HowItWorksPage>('/how-it-work', {
      populate: ['faqs', 'welcome_content'],
    })
  }

  theIndex() {
    return this.getData<TheIndexPage>('/the-index', {
      populate: ['faqs', 'welcome_content', 'stats'],
    })
  }

  aboutUs() {
    return this.getData<AboutUsPage>('/about', {
      populate: ['faqs'],
    })
  }

  givingBack() {
    return this.getData<GivingBackPage>('/giving-back')
  }

  joinUs() {
    return this.getData<JoinUsPage>('/join-us')
  }

  meetTheTeam() {
    return this.getData<MeetTheTeamPage>('/meet-the-team', {
      populate: [],
    })
  }

  ourSolution() {
    return this.getData<OurSolution>('/our-solution')
  }

  homeSellers() {
    return this.getData<HomeSellersPage>('/home-sellers', {
      populate: ['things_to_knows'],
    })
  }
}
