import { ReactNode } from 'react'

import { OrderModel } from 'api/nest/orders/types'
import { Flex } from 'components/flex'
import { Link } from 'components/links'
import { OrderStatus } from 'components/order-status'
import { Text } from 'components/text'
import classes from 'sections/pp-dashboard-page/orders-grid/orders-grid.module.css'
import { dateFormatter, usdFormatter } from 'utils'
import { routes } from 'utils/routes'

export const DEFAULT_VALUE = '—'

export interface GridColumn {
  headerTitle: string
  dataKey: keyof OrderModel | 'actions' | 'contractHolderName'
  render?: (data: OrderModel) => ReactNode
  className?: string
}

export const GRID_COLUMNS: GridColumn[] = [
  {
    headerTitle: 'Contract #',
    dataKey: 'contractNumber',
    render: (data) => (
      <Flex direction="column" gap="none">
        <Link href={routes.partnerPortalOrder(data.uuid)}>{data.contractNumber}</Link>
        <Text fontSize="s">Created {dateFormatter(data.createdAt)}</Text>
      </Flex>
    ),
    className: classes.createdAt,
  },
  {
    headerTitle: 'Property / Market',
    dataKey: 'property',
    render: (data) => (
      <Flex direction="column" gap="none">
        <Text fontSize="s">{data.property.address.street1}</Text>
        {data.property.address.street2 && <Text fontSize="s">{data.property.address.street2}</Text>}
        <Text fontSize="s">{data.market?.name}</Text>
      </Flex>
    ),
    className: classes.contract,
  },
  {
    headerTitle: 'Contract Holder',
    dataKey: 'contractHolderName',
    render: (data) => data.investor?.firstName + ' ' + data.investor?.lastName,
    className: classes.contractHolderName,
  },
  {
    headerTitle: 'Maximum Payout',
    dataKey: 'amount',
    className: classes.maxPayout,
    render: (data) => usdFormatter(data.maximumPayout),
  },
  {
    headerTitle: 'Status',
    dataKey: 'status',
    render: (data) => <OrderStatus status={data.status.statusName} />,
    className: classes.status,
  },
  {
    headerTitle: '',
    dataKey: 'actions',
    className: classes.actions,
  },
]
