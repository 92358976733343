import * as yup from 'yup'

export const YUP_ERROR_MESSAGE: yup.LocaleObject = {
  mixed: {
    required: 'Required',
  },
  number: {
    positive: 'Must be a positive number',
    integer: 'Must be an integer',
  },
  string: {
    email: 'Must be a valid email',
    length: 'Must be exactly ${length} characters',
    min: 'Must be at least ${min} characters',
    max: 'Must be at most ${max} characters',
  },
}

yup.setLocale(YUP_ERROR_MESSAGE)

export { yup }
