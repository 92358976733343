import { FC } from 'react'
import type { SetOptional } from 'type-fest'

import { Dialog, DialogProps } from 'components/dialog/Dialog'
import { useControlRouterDialog, useIsOpenDialog } from 'components/dialog/utils'
import { DIALOG_NAME } from 'const/dialogs'

type BaseDialogProps = SetOptional<Omit<DialogProps, 'open'>, 'onClose'>

export interface RouterDialogProps extends BaseDialogProps {
  modalName: DIALOG_NAME
}

export const RouterDialog: FC<RouterDialogProps> = ({ modalName, onClose, ...dialogProps }) => {
  const isOpen = useIsOpenDialog(modalName)
  const { close: closeDialog } = useControlRouterDialog()

  const handlerClose = async () => {
    await closeDialog(modalName)
    onClose && onClose()
  }

  return <Dialog {...dialogProps} onClose={handlerClose} open={isOpen} />
}
