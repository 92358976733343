import { FC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import _ from 'lodash'

import { Form, useForm } from 'components/form'
import {
  CheckboxFormField,
  EmailFormField,
  FirstNameFormField,
  LastNameFormField,
  InternationalPhoneFormField,
  SelectFormField,
  SelectMarketFormField,
  ZipCodeFormField,
} from 'components/form-field'
import { SubmitFormButton } from 'components/submit-form-button'
import { Button } from 'components/button'
import { cn } from 'utils'
import { MarketModel } from 'api/nest'
import { DIALOG_NAME } from 'const/dialogs'
import { useDialogContext } from 'components/dialog/utils'

import classes from './referral-form.module.css'
import { HOME_VALUE_OPTIONS, NEW_REFERRAL_FIELD, NEW_REFERRAL_VALIDATION } from './consts'
import { ReferralFormModel } from './types'

export interface ReferralFormProps {
  onSubmit: SubmitHandler<ReferralFormModel>
  markets: MarketModel[]
  dialogName: DIALOG_NAME.dashboardNewReferral | DIALOG_NAME.dashboardEditReferral
  update?: boolean
  defaultValues?: Partial<ReferralFormModel>
}

export const ReferralForm: FC<ReferralFormProps> = ({
  onSubmit,
  markets,
  update,
  defaultValues,
}) => {
  const { close } = useDialogContext()
  const formMethods = useForm(NEW_REFERRAL_VALIDATION, { defaultValues, mode: 'onChange' })

  const sortedMarkets = _.sortBy(markets, (m) => m.name, 'asc')

  return (
    <>
      <p className={classes.desc}>
        Please {update ? 'update and resubmit' : 'complete and submit'} your customer’s information
        below. Note that this information <b>cannot</b> be changed after your referral has purchased
        a contract.
      </p>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <div className={classes.fields}>
          <FirstNameFormField
            name={NEW_REFERRAL_FIELD.firstName}
            label="Customer’s first name"
            placeholder="Enter first name"
          />
          <LastNameFormField
            name={NEW_REFERRAL_FIELD.lastName}
            label="Customer’s last name"
            placeholder="Enter last name"
          />
          <EmailFormField
            name={NEW_REFERRAL_FIELD.email}
            label="Customer’s email address"
            placeholder="Enter email address"
          />
          <InternationalPhoneFormField
            name={NEW_REFERRAL_FIELD.phone}
            label="Customer’s mobile phone number"
            formMethods={formMethods}
          />
          <SelectFormField
            name={NEW_REFERRAL_FIELD.homeValue}
            options={HOME_VALUE_OPTIONS}
            label="Customer’s estimated home value"
          />
          <ZipCodeFormField
            name={NEW_REFERRAL_FIELD.postalCode}
            label="Customer's property zip code"
            placeholder="Enter zip code"
          />
          <SelectMarketFormField
            name={NEW_REFERRAL_FIELD.marketUuid}
            options={sortedMarkets}
            label="REZITRADE Market of interest"
            classNameLabel={classes.marketSelectLabel}
          />
        </div>

        <CheckboxFormField
          name={NEW_REFERRAL_FIELD.agreeToDisclosed}
          classNameLabel={classes.checkboxLabel}
        >
          I certify that I have disclosed this information at the request of the customer and in
          accordance with applicable State and Federal law.
        </CheckboxFormField>

        <SubmitFormButton theme="primary" className={cn(classes.btn, classes.submit)}>
          {update ? 'Update' : 'Submit'} referral
        </SubmitFormButton>
        <Button theme="text" className={classes.btn} onClick={close}>
          Cancel
        </Button>
      </Form>
    </>
  )
}
