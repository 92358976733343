import { FC } from 'react'

import { cn } from 'utils/cn'

import { FORM_FIELD_LABEL, MAX_ZIP_CODE_LENGTH } from '../consts'
import { ExtendTextFieldProps } from './types'
import { MaskedTextFormField } from './MaskedTextFormField'

export const ZipCodeFormField: FC<ExtendTextFieldProps> = ({
  autoCompleteGroup,
  placeholder = 'Enter the zip code',
  ...props
}) => {
  return (
    <MaskedTextFormField
      mask="00000"
      label={props.label ?? FORM_FIELD_LABEL.zipCode}
      autoComplete={props.autoComplete ?? cn(autoCompleteGroup, 'postal-code')}
      maxLength={MAX_ZIP_CODE_LENGTH}
      placeholder={placeholder}
      data-1p-ignore
      {...props}
    />
  )
}
