import NextImage from 'next/image'
import { usePathname } from 'next/navigation'

import { cn } from 'utils'
import { Section } from 'components/section'
import Logo from 'icons/reverse-white-trademark.png'
import { Heading } from 'components/heading'
import { checkForLandingPage } from 'components/header/default-header/utils'

import { SubFooter } from './sub-footer'
import { NavList } from './nav-list/nav-list'
import { SubscribeForm } from './subscribe-form'
import { socialMedia } from './consts'
import { socialMediaIconBuilder, getNavLists } from './utils'
import classes from './footer.module.css'

export interface FooterProps {
  className?: string
}

export const Footer: FCRC<FooterProps> = ({ className, children, ...props }) => {
  const pathname = usePathname()
  const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

  const socialIconsBuilder = (className?: string) => (
    <div className={cn(classes.socialMediaWrapper, className)}>
      {socialMedia(window.location.origin).map(socialMediaIconBuilder)}
    </div>
  )

  const { firstNavList, secondNavList } = getNavLists(isLandingPage, landingPageType)

  return (
    <footer {...props} className={cn(classes.footer, className)}>
      <div className={classes.container}>
        <Section className={classes.section}>
          <div className={classes.leftFootersection}>
            <div>
              <div className={classes.logoContainer}>
                <NextImage
                  src={Logo}
                  alt="logo"
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                  className={classes.logo}
                />
              </div>
              <p>
                REZITRADE is revolutionizing residential real estate. We provide a pioneering
                index-based platform that delivers unique risk solutions, most notably our Home
                Price Protection.
              </p>
              <nav className={classes.nav}>
                <NavList items={firstNavList} />
                <NavList items={secondNavList} />
              </nav>
            </div>
            {socialIconsBuilder(classes.desktopView)}
          </div>
          <div>
            <Heading theme="h3" component="h3">
              Stay in the know
            </Heading>
            <p>
              Sign up to get the latest information on Home Price Protection, including new markets,
              added features, market insights, and more.
            </p>

            <SubscribeForm />
            {socialIconsBuilder(classes.tabletView)}
          </div>
        </Section>
      </div>

      <SubFooter />
    </footer>
  )
}
