import { FC } from 'react'
import { DefaultSeo as NextDefaultSeo } from 'next-seo'

import { abstract } from 'const/seo-data'

import { ORIGIN_URL } from '../../const/config'

export const DefaultSeo: FC = () => {
  return (
    <NextDefaultSeo
      defaultTitle="REZITRADE | Home Price Protection"
      titleTemplate="%s | REZITRADE"
      themeColor="#ffffff"
      description="Home Price Protection"
      openGraph={{
        type: 'website',
        url: `${ORIGIN_URL}`,
        title: 'Rezitrade',
        description: abstract,
        images: [
          {
            url: '/seo/main-image.jpg',
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/seo/favicon.ico',
        },
        {
          rel: 'icon',
          sizes: '16x16',
          type: 'image/png',
          href: '/seo/favicon-16x16.png',
        },
        {
          rel: 'icon',
          sizes: '32x32',
          type: 'image/png',
          href: '/seo/favicon-32x32.png',
        },
        {
          rel: 'apple-touch-icon',
          href: '/seo/apple-touch-icon.png',
          sizes: '180x180',
        },
      ]}
    />
  )
}
