import { FC } from 'react'

import { cn } from 'utils'

import classes from './loader.module.css'

export interface LoaderProps {
  className?: string
  size?: 'medium' | 'large' | 'small'
  theme?: 'accent' | 'inherit'
}

export const Loader: FC<LoaderProps> = ({ className, size, theme }) => {
  return (
    <div
      className={cn(
        classes.ldsEllipsis,
        size && classes[`${size}Size`],
        theme && classes[`${theme}Theme`],
        className,
      )}
    >
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
