import clsx from 'clsx'
import { createElement, ReactHTML, ReactNode } from 'react'

import classes from './text.module.css'

export const Text = ({
  children,
  component = 'p',
  fontSize = 's',
}: {
  children: ReactNode
  component?: keyof ReactHTML
  fontSize?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl'
}) => {
  return createElement(
    component,
    {
      className: clsx({
        [classes.fontSizeXxs!]: fontSize === 'xxs',
        [classes.fontSizeXs!]: fontSize === 'xs',
        [classes.fontSizeS!]: fontSize === 's',
        [classes.fontSizeM!]: fontSize === 'm',
        [classes.fontSizeL!]: fontSize === 'l',
        [classes.fontSizeXl!]: fontSize === 'xl',
        [classes.fontSize2xl!]: fontSize === '2xl',
        [classes.fontSize3xl!]: fontSize === '3xl',
        [classes.fontSize4xl!]: fontSize === '4xl',
        [classes.fontSize5xl!]: fontSize === '5xl',
        [classes.fontSize6xl!]: fontSize === '6xl',
      }),
    },
    children,
  )
}
