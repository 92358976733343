import { FC } from 'react'

import { cn } from 'utils/cn'

import { ExtendTextFieldProps } from './types'
import { TextFormField } from './TextFormField'
import { FORM_FIELD_LABEL, MAX_FIRST_NAME_LENGTH } from '../consts'

export const FirstNameFormField: FC<ExtendTextFieldProps> = ({ autoCompleteGroup, ...props }) => {
  return (
    <TextFormField
      label={props.label ?? FORM_FIELD_LABEL.firstName}
      autoComplete={cn(autoCompleteGroup, 'given-name')}
      maxLength={MAX_FIRST_NAME_LENGTH}
      {...props}
    />
  )
}
