import { ComponentProps, FC } from 'react'

import { ExtendedSliderFormField } from './ExtendedSliderFormField'

type MaxPayoutSliderFormField = Omit<
  ComponentProps<typeof ExtendedSliderFormField>,
  'formatter' | 'description' | 'info'
>

export const MaxPayoutSliderFormField: FC<MaxPayoutSliderFormField> = ({ label, ...props }) => {
  return <ExtendedSliderFormField formatter={ExtendedSliderFormField.formatCurrency} {...props} />
}
