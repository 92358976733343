import { AxiosRequestConfig } from 'axios'

import { StrapiDataModel, StrapiPagination, StrapiQuery } from 'api/strapi'
import { StrapiController, StrapiControllerConfig } from 'api/strapi/StrapiController'
import { StrapiResponseCollection } from 'api/strapi/StrapiResponse'

export type StrapiCollectionConfig = Omit<AxiosRequestConfig<never>, 'params' | 'body'>
export class StrapiCollection<TModel extends StrapiDataModel> extends StrapiController {
  defaultFindOneParams = {}
  defaultFindParams = {}

  constructor(config?: RequiredByKey<StrapiControllerConfig, 'controller'>) {
    super(config)
  }

  public async findOne(
    id: number | string,
    params?: StrapiQuery<TModel>,
    config?: StrapiCollectionConfig,
  ): Promise<TModel> {
    const url = `/${id}`
    const query = { ...this.defaultFindOneParams, ...params }
    return await this.getData<TModel>(url, query, config)
  }

  public async find(
    searchParams?: StrapiQuery<TModel>,
    config?: StrapiCollectionConfig,
  ): Promise<{ items: TModel[]; pagination: StrapiPagination }> {
    const url = '/'
    const query = {
      ...this.defaultFindParams,
      ...searchParams,
    }
    const result = await this.get<StrapiResponseCollection<TModel>>(url, query, config)
    return {
      items: result.data || [],
      pagination: result.meta.pagination,
    }
  }

  public async listIds(
    searchParams?: StrapiQuery<TModel>,
    config?: StrapiCollectionConfig,
  ): Promise<number[]> {
    // @ts-ignore
    const defaultQueries: StrapiQuery<TModel> = {
      fields: ['id'],
    }

    const { items } = await this.find({ ...searchParams, ...defaultQueries }, config)

    return items.map(({ id }) => id)
  }

  public async listSlugs(
    searchParams?: StrapiQuery<TModel>,
    config?: StrapiCollectionConfig,
  ): Promise<string[]> {
    // @ts-ignore
    const defaultQueries: StrapiQuery<TModel> = {
      fields: ['slug'],
    }

    const { items } = await this.find({ ...searchParams, ...defaultQueries }, config)

    return items.map(({ slug }) => slug)
  }

  public async count(
    searchParams?: StrapiQuery<TModel>,
    config?: Omit<AxiosRequestConfig, 'params' | 'body'>,
  ) {
    return await this.get<number>('/count', searchParams, config)
  }
}
