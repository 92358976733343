import { AddressModel } from 'api/nest/addresses/types'
import { CountryEnum } from 'enum/country'

export const addressFormatter = (address?: AddressModel): JSX.Element | null => {
  if (!address) return null

  const { street1, street2, city, region, postalCode, country } = address

  return (
    <>
      {street1}
      {street2 && (
        <>
          <br />
          {street2}
        </>
      )}
      <br />
      {city}, {region} {postalCode} {country !== CountryEnum['United States'] && country}
    </>
  )
}
