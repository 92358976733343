import { EMAIL_RULE, FIRST_NAME_RULE, LAST_NAME_RULE, PHONE_RULE } from 'components/form-field'
import { yup } from 'components/form/yup'
import { ORDER_TERMS } from 'const/order-terms'
import { PropertyValueSource } from 'enum/property-value-source'
import { PropertyTypeEnum } from 'enum/property-type'
import { UsStateEnum } from 'enum/us-state'
import { ADDRESS_CREATE_SCHEMA } from 'schemas/address-create'
import { CompanyTypeEnum } from 'sections/configuration-flow-page/payment/payer/utils'

export const CONTRACT_STEP_SCHEMA = yup.object({
  agreeDisclosures: yup.boolean().required(),
  agreeTermsOfService: yup.boolean().required(),
  isEsigned: yup.boolean().required(),
  userClickedNext: yup.boolean().required(),
})

export const COVERAGE_STEP_SCHEMA = yup.object({
  period: yup.string().oneOf(ORDER_TERMS).required(),
  maxPayout: yup.number().required(),
  triggerLevel: yup.number().required(),
  annualPrice: yup.number().required(),
  totalPrice: yup.number().required(),
  offeringUuid: yup.string().required(),
})

export const MARKET_STEP_SCHEMA = yup.object({
  uuid: yup.string().uuid().required(),
  state: yup.string().oneOf(Object.values(UsStateEnum)),
  zipCode: yup.string(), // optional in context of step but required on ZipCodeForm
  defaultMaxPayout: yup.number().required(),
  canEditMaxPayout: yup.boolean().required(),
  defaultOfferingUuid: yup.string().uuid(),
})

export const PAYMENT_STEP_SCHEMA_AUTH_NET = yup.object().shape({
  billingAddress: ADDRESS_CREATE_SCHEMA.required(),
  cardExpirationDate: yup.string().required().min(4, 'Must be entered in MM/YY format'),
  cardholderName: yup.string().required(),
  cardNumber: yup.string().required(),
  cardSecurityCode: yup.string().required().min(3, 'Must be 3 or 4 digits'),
  sameBillingMailingAddress: yup.boolean(),
})

export const PAYMENT_STEP_SCHEMA_BOA = yup.object().shape({
  billingAddress: ADDRESS_CREATE_SCHEMA.required(),
  cardExpirationDate: yup.string().required().min(4, 'Must be entered in MM/YY format'),
  cardholderName: yup.string().required(),
  sameBillingMailingAddress: yup.boolean(),
})

export const PAYMENT_SUCCESS_SCHEMA = yup.object({
  transactionId: yup.number().required(),
})

export const PERSONAL_INFO_STEP_SCHEMA = yup.object().shape({
  firstName: FIRST_NAME_RULE.required(),
  lastName: LAST_NAME_RULE.required(),
  email: EMAIL_RULE.required(),
  phone: PHONE_RULE.required(),
  mailingAddress: ADDRESS_CREATE_SCHEMA.required(),
  samePropertyMailingAddress: yup.boolean(),
})

export const PURCHASER_INFO_STEP_SCHEMA = yup.object().shape({
  firstName: FIRST_NAME_RULE.required(),
  lastName: LAST_NAME_RULE.required(),
  email: EMAIL_RULE.required(),
  phone: PHONE_RULE.required(),
})

export const PROPERTY_STEP_SCHEMA = yup.object({
  address: ADDRESS_CREATE_SCHEMA.required(),
  estimatedValue: yup.number().required(),
  estimatedValueSource: yup
    .string()
    .oneOf(Object.values(PropertyValueSource), 'Dropdown selection required.')
    .required(),
  estimatedValueOtherSource: yup.string().when('estimatedValueSource', {
    is: (value) => value === PropertyValueSource.Other,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  owner: yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: PHONE_RULE.required(),
      email: EMAIL_RULE.required(),
    })
    .default(undefined)
    .optional(),
  payer: yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: EMAIL_RULE.required(),
      companyName: yup.string().required(),
      companyType: yup
        .string()
        .oneOf(Object.values(CompanyTypeEnum), 'Partner Company Type selection required.'),
      companyUuid: yup.string().required(),
      agentUuid: yup.string().required(),
      isPaymentDeferred: yup.boolean().required(),
    })
    .default(undefined)
    .optional(),
  type: yup.string().oneOf(Object.values(PropertyTypeEnum)).required(),
})

export const REVIEW_STEP_SCHEMA = yup.object({
  reviewed: yup.boolean().oneOf([true]).required(),
})

export const REVIEW_STEP_SCHEMA_ATTESTATION = yup.object({
  agreeAttestation: yup.boolean().oneOf([true]).required(),
  reviewed: yup.boolean().oneOf([true]).required(),
})
