import { OrderTerms } from '../api/nest/orders/types'

export const ORDER_TERMS: OrderTerms[] = [
  '1 year',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
  '6 years',
]
