import { MarketModel } from 'api/nest/markets'

import { FindAllResponseModel } from '../NestCollection'

export const enum ReferralStatus {
  paid = 'Paid',
  pending = 'Pending',
  processing = 'Processing',
  cancelled = 'Cancelled',
}

export interface ReferralModel {
  uuid: string
  createdAt: string
  updatedAt: string
  email: string
  firstName: string
  lastName: string
  postalCode: string
  phone: string
  homeValue: string
  status: ReferralStatus
  market: MarketModel
  marketUuid: string
  marketName: string
  referralAmount?: number
  orderAmount?: number
}

export interface ReferralAllModel extends Omit<ReferralModel, 'marketUuid' | 'marketName'> {
  market: MarketModel
}

export interface ReferralCreateModel {
  email: string
  firstName: string
  lastName: string
  postalCode: string
  phone: string
  marketUuid: string
}

export interface ReferralUpdateModel extends Omit<ReferralCreateModel, 'brokerUuid'> {
  uuid: string
}

export interface ReferralQuery {
  order: string
}

export interface ReferralCollectionModel extends FindAllResponseModel<ReferralModel> {}
