import { useWatch } from 'react-hook-form'
import { FC } from 'react'

import { cn } from 'utils'
import { NEW_PASSWORD_QUALITY_RULE } from 'components/form-field/consts'
import CheckmarkIcon from 'icons/checkmark.svg'

import classes from './password-withquality-form-field.module.css'

interface QualityListProps {
  fieldName: string
}

export const QualityList: FC<QualityListProps> = ({ fieldName }) => {
  const password = useWatch({ name: fieldName })

  return (
    <ul className={classes.ruleList}>
      {NEW_PASSWORD_QUALITY_RULE.map(({ testRegexp, title, className }) => {
        testRegexp.lastIndex = 0 //NOTE: reset lastIndex for each test
        const done = password && testRegexp.test(password)

        return (
          <li key={title} className={cn(className, classes.rule, done && classes.done)}>
            <CheckmarkIcon className={classes.icon} />
            {title}
          </li>
        )
      })}
    </ul>
  )
}
