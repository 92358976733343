import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// dev tools are automatically excluded from prod builds
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Head from 'next/head'
import { Playfair_Display, Roboto } from 'next/font/google'

import { Layout as DefaultLayout } from 'components/layout'
import { routes } from 'utils/routes'
import { GlobalPageLoader } from 'components/global-page-loader'
import { Toaster } from 'components/toaster'

import { DefaultSeo } from '../components/seo'
import { usePreviewModeNotification } from '../utils/use-preview-mode-notification'

import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

const playfair_display = Playfair_Display({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  display: 'swap',
})
const roboto = Roboto({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  display: 'swap',
})

interface BaseComponentsProps {
  children: ReactNode
}

const BaseComponents = ({ children }: BaseComponentsProps) => {
  usePreviewModeNotification()

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <DefaultSeo />
      {children}
      <Toaster />
      <GlobalPageLoader />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function MyApp({ Component, pageProps, router }: AppProps) {
  const withFontsComponent = (
    <>
      <style jsx global>{`
        :root {
          --font-family: ${roboto.style.fontFamily};
          --font-family-heading: ${playfair_display.style.fontFamily};
        }
      `}</style>
      <Component {...pageProps} />
    </>
  )

  if (router.pathname.startsWith(routes.partnerPortal)) {
    return <BaseComponents>{withFontsComponent}</BaseComponents>
  }

  return (
    <BaseComponents>
      <DefaultLayout>{withFontsComponent}</DefaultLayout>
    </BaseComponents>
  )
}

export default MyApp
