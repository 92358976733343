import { StrapiCollection } from 'api/strapi/index'

import { ReasonModel } from './types'

export class WhyBuyReasonsCollection extends StrapiCollection<ReasonModel> {
  constructor() {
    super({ controller: 'why-buy-reasons' })
  }

  override defaultFindOneParams = {
    populate: ['image'],
  }

  override defaultFindParams = {
    populate: ['image'],
  }
}
