import { FC } from 'react'

import { Link } from 'components/links'
import { cn } from 'utils'

import classes from './nav-list.module.css'

export interface NavItem {
  label: string
  link: string
  isAccent?: boolean
  className?: string
  classNameLink?: string
  isDownload?: boolean
  docTitle?: string
}

interface NavListProps {
  items: NavItem[]
}

export const NavList: FC<NavListProps> = ({ items }) => {
  return (
    <ul className={classes.list}>
      {items.map(({ link, isAccent, label, className, classNameLink, isDownload, docTitle }) => (
        <li
          className={cn(classes.listItem, isAccent && classes.listItemAccent, className)}
          key={label}
        >
          {isDownload ? (
            <a className={classes.link} href={link} download={docTitle}>
              {label}
            </a>
          ) : (
            <Link href={link} className={cn(classes.link, classNameLink)}>
              {label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}
