import { ChangeEventHandler, useCallback, FC } from 'react'
import { useRouter } from 'next/router'

import { useQueryParam } from 'utils/router'
import { addPathSearchParameter } from 'utils/url'
import { cn } from 'utils'

import classes from './record-per-page.module.css'

interface RecordPerPageProps {
  className?: string
  takeParamName?: string
}

const OPTIONS = ['10', '25', '50']

export const RecordPerPage: FC<RecordPerPageProps> = ({ className, takeParamName }) => {
  const { asPath, push } = useRouter()
  const value = useQueryParam(takeParamName || 'take')

  const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      push(
        addPathSearchParameter({
          path: asPath,
          name: takeParamName || 'take',
          value: e.target.value,
          clearPrevValue: true,
        }),
        undefined,
        { scroll: false, shallow: true },
      )
    },
    [asPath, push, takeParamName],
  )

  return (
    <div className={cn(classes.container, className)}>
      <select onChange={onChange} defaultValue={value} className={classes.select}>
        {OPTIONS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <span className={classes.text}>Records per page</span>
    </div>
  )
}
