/* eslint-disable no-console */
import axios, { Axios, AxiosError, AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { CMS_API_BASE_URL } from 'const/config'
import { BackendError } from 'api/strapi/BackendError'
import { resolveUrl } from 'utils/url'
import { StrapiResponseCollection, StrapiResponseRecord } from 'api/strapi/StrapiResponse'

import { logAxiosError } from './utils'

export interface StrapiControllerConfig extends AxiosRequestConfig {
  controller?: string
}

export class StrapiController {
  static async handleRequestError(error: AxiosError) {
    logAxiosError(error)
    return Promise.reject(error)
  }

  static async handleResponseError(error: AxiosError) {
    if (error.isAxiosError) {
      if (error.response?.status && ![401, 403].includes(error.response?.status)) {
        // do not output auth errors
        logAxiosError(error)
      }
      // @ts-ignore
      const responseMessage = error.response?.data?.message ?? error.message
      // @ts-ignore
      const responseErrors = error.response?.data?.errors
      return Promise.reject(new BackendError(responseMessage, responseErrors, error.response))
    }

    return Promise.reject(error)
  }

  static get baseUrl() {
    return CMS_API_BASE_URL
  }
  static resolveUrl(pathname: string): string {
    return resolveUrl(CMS_API_BASE_URL, pathname)
  }

  /**
   * @see [Complex strapi queries]{@link https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering}
   */
  static paramsSerializer(
    params: string | Record<string, string> | string[][] | URLSearchParams | undefined,
  ): string {
    return qs.stringify(params, { encodeValuesOnly: true })
  }

  protected readonly client: Axios
  controller?: string

  constructor({ controller, ...restAxiosConfig }: StrapiControllerConfig = {}) {
    if (controller) this.controller = controller

    this.client = axios.create({
      baseURL: StrapiController.resolveUrl(this.controller ?? ''),
      // withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      ...restAxiosConfig,
      paramsSerializer: {
        serialize: StrapiController.paramsSerializer,
      },
    })
    this.client.interceptors.request.use(undefined, StrapiController.handleRequestError)
    this.client.interceptors.response.use(undefined, StrapiController.handleResponseError)
  }

  protected async get<T = any>(
    url: string,
    query?: AxiosRequestConfig<T>['params'],
    config?: Omit<AxiosRequestConfig<T>, 'params'>,
  ) {
    try {
      const response = await this.client.get<T>(url, {
        ...config,
        params: query,
      })
      return response.data
    } catch (error) {
      console.log((error as BackendError).response)
      throw error
    }
  }

  protected async getData<T, Response extends StrapiResponseRecord<T> = StrapiResponseRecord<T>>(
    url: string,
    query?: AxiosRequestConfig<Response>['params'],
    config?: Omit<AxiosRequestConfig<Response>, 'params'>,
  ) {
    const response = await this.get<Response>(url, query, config)
    return response.data
  }

  protected async post<T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: Omit<AxiosRequestConfig, 'data'>,
  ): Promise<T> {
    const response = await this.client.post<T>(url, data, config)
    return response.data
  }

  protected async put<T extends StrapiResponseRecord<any> | StrapiResponseCollection<any>>(
    url: string,
    data: AxiosRequestConfig['data'],
    config?: Omit<AxiosRequestConfig, 'data'>,
  ): Promise<T> {
    const response = await this.client.put<T>(url, data, config)
    return response.data
  }
}
