export const enum DIALOG_NAME {
  salesByLocationFullscreen = 'market:sales-by-location',
  homePriceIndexFullscreen = 'market:home-price-index',
  priceVolatilityFullscreen = 'market:price-volatility',
  propertiesSoldFullscreen = 'market:properties-sold',
  medianSalePricesFullscreen = 'market:median-sale-prices',
  searchMarketByZipCode = 'search-market-by-zip-code',
  dashboardNewReferral = 'dashboard:new-referral',
  dashboardEditReferral = 'dashboard:edit-referral',
  homePageHowItWorksVideo = 'home-page:how-it-works-video',
  howItWorksPageSearchMarketForCalculation = 'how-it-works-page:calculation',
  theIndexPageMethodologyVideo = 'the-index-page:methodology-video',
  joinUsSendInvitation = 'join-us:send-invitation',
  marketNotAvailable = 'configuration-flow:market-not-available',
  estimatedValue = 'configuration-flow:estimated-home-value',
  homePagePricingCalculations = 'pricing-calculations',
  disabledMarketStep = 'disabledMarketStep',
  buyNowDialog = 'buyNowDialog',
  selectionsDialog = 'selectionsDialog',
  reviewStepAcknowledgement = 'reviewStepAcknowledgement',
  disabledAfterReviewDialog = 'disabledAfterReviewDialog',
  statisticsDialog = 'statisticsDialog',
}
