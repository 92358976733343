import { FC } from 'react'

import { ButtonProps } from 'components/button'
import styles from 'components/button/button.module.css'
import { cn } from 'utils'

import { Link, LinkProps } from './Link'

export type LinkButtonProps = Omit<LinkProps, 'href' | 'theme'> & {
  theme?: ButtonProps['theme']
  href?: string | null
}

export const LinkButton: FC<LinkButtonProps> = ({ theme, className, children, href, ...props }) => {
  const themes = theme ? (Array.isArray(theme) ? theme : [theme]).map((theme) => styles[theme]) : []
  const classes = cn(styles.button, ...themes, className)

  if (!href) {
    return (
      <span {...props} className={classes}>
        {children}
      </span>
    )
  }

  return (
    <Link {...props} href={href} className={classes}>
      {children}
    </Link>
  )
}
