import { NestController } from 'api/nest/NestController'
import { PaymentSystemEnum } from 'api/nest/payment-system/types'

export class PaymentSystemController extends NestController {
  constructor() {
    super({ controller: 'payment-system' })
  }

  public getPaymentSystem = async () => {
    return await this.get<PaymentSystemEnum>('/select')
  }
}
