import { FormNameType } from 'utils/types'
import { ReferralFormModel } from 'sections/pp-dashboard-page/referral-form/types'
import { yup } from 'components/form/yup'
import {
  EMAIL_RULE,
  FIRST_NAME_RULE,
  LAST_NAME_RULE,
  SelectFormFieldOptions,
  ZIP_CODE_RULE,
  PHONE_RULE,
} from 'components/form-field'

export const NEW_REFERRAL_FIELD: FormNameType<ReferralFormModel> = {
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  homeValue: 'homeValue',
  marketUuid: 'marketUuid',
  postalCode: 'postalCode',
  agreeToDisclosed: 'agreeToDisclosed',
}

//TODO: re-comment when backend will be ready
export const NEW_REFERRAL_VALIDATION = yup.object().shape({
  [NEW_REFERRAL_FIELD.email]: EMAIL_RULE,
  [NEW_REFERRAL_FIELD.firstName]: FIRST_NAME_RULE.required(),
  [NEW_REFERRAL_FIELD.lastName]: LAST_NAME_RULE.required(),
  [NEW_REFERRAL_FIELD.phone]: PHONE_RULE.required(),
  [NEW_REFERRAL_FIELD.homeValue]: yup.string().trim().required(),
  [NEW_REFERRAL_FIELD.marketUuid]: yup.string().required(),
  [NEW_REFERRAL_FIELD.postalCode]: ZIP_CODE_RULE.required(),
  [NEW_REFERRAL_FIELD.agreeToDisclosed]: yup
    .boolean()
    .isTrue('You cannot continue until confirm')
    .required(),
})

export const HOME_VALUE_OPTIONS: SelectFormFieldOptions = {
  '$250,000 or less': '$250,000 or less',
  '$250,001 - $500,000': '$250,001 - $500,000',
  '$500,001 - $750,000': '$500,001 - $750,000',
  '$750,001 - $1,000,000': '$750,001 - $1,000,000',
  '$1,000,001 or more': '$1,000,001 or more',
}
