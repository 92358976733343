import { useRef, useState, FC } from 'react'
import { useClickAway } from 'react-use'

import { ReferralModel } from 'api/nest'
import { Loader } from 'components/loader'

import { ActionMenuProps, Menu } from './menu'
import classes from './actions.module.css'

export interface ActionsProps {
  referral: ReferralModel
  loading?: boolean
  onAction: ActionMenuProps['onClick']
}

export const Actions: FC<ActionsProps> = ({ loading, onAction, referral }) => {
  const [isOpen, setIsOpen] = useState(false)
  const actions = useRef<HTMLDivElement | null>(null)

  useClickAway(actions, () => setIsOpen(false))

  return (
    <div className={classes.container} ref={actions}>
      <br />
      <button
        className={classes.button}
        onClick={isOpen && loading ? () => {} : () => setIsOpen(true)}
      >
        {loading ? (
          <Loader className={classes.loader} />
        ) : (
          <>
            <span className={classes.dot} />
            <span className={classes.dot} />
            <span className={classes.dot} />
          </>
        )}
      </button>

      {isOpen && <Menu setIsOpen={setIsOpen} onClick={onAction} referral={referral} />}
    </div>
  )
}
