import { FC } from 'react'

import { PasswordFormField } from 'components/form-field/TextFormField'
import { cn } from 'utils'

import { QualityList } from './QualityList'
import classes from './password-withquality-form-field.module.css'

interface PasswordWithQualityFormFieldProps extends PasswordFormField {}

export const PasswordWithQualityFormField: FC<PasswordWithQualityFormFieldProps> = ({
  className,
  ...rest
}) => {
  return (
    <div className={cn(classes.container, className)}>
      <PasswordFormField {...rest} className={classes.field} />

      <strong className={classes.ruleTitle}>Passwords must contain at least:</strong>
      <QualityList fieldName={rest.name} />
    </div>
  )
}
