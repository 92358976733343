import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useDebounce, useSearchParam } from 'react-use'
import { useRouter } from 'next/router'

import { Form } from 'components/form'
import { SearchFormField } from 'components/form-field'
import { addPathSearchParameter, removePathSearchParameter } from 'utils/url'

import classes from './search-field.module.css'

export enum QueryKeyEnum {
  referrals = 'referralsSearch',
  contracts = 'ordersSearch',
}

interface SearchFieldProps {
  placeholder: string
  queryKey: QueryKeyEnum
  className?: string
}

export enum SearchFieldsEnum {
  search = 'search',
}

export const SearchField: FC<SearchFieldProps> = ({ className, placeholder, queryKey }) => {
  const { push, asPath } = useRouter()
  const searchFieldValue = useSearchParam(SearchFieldsEnum.search)
  const formMethods = useForm({
    defaultValues: {
      [SearchFieldsEnum.search]: searchFieldValue || '',
    },
  })
  const value = formMethods.watch(SearchFieldsEnum.search)

  useDebounce(
    () => {
      if (value && value.trim()) {
        push(
          addPathSearchParameter({
            path: asPath,
            name: queryKey,
            value: value.trim() || '',
            clearPrevValue: true,
          }),
          undefined,
          { shallow: true },
        )
      } else {
        push(removePathSearchParameter(asPath, [queryKey]), undefined, {
          shallow: true,
        })
      }
    },
    800,
    [value],
  )

  return (
    <Form formMethods={formMethods} onSubmit={() => {}} className={className}>
      <SearchFormField
        name={SearchFieldsEnum.search}
        placeholder={`Search ${placeholder}`}
        className={classes.searchContainer}
        formMethods={formMethods}
      />
    </Form>
  )
}
