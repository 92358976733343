import { ReactNode } from 'react'

import { Button, ButtonProps } from 'components/button'
import { RCFC } from 'utils/types'
import { cn } from 'utils'

import classes from './loading-button.module.css'

export interface LoadingButtonProps extends ButtonProps {
  loading: boolean
  contentHideMode?: 'partial'
  children: ReactNode
}

export const LoadingButton: RCFC<LoadingButtonProps> = ({
  children,
  className,
  loading,
  disabled,
  contentHideMode,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      className={cn(classes.buttonLoading, className)}
      disabled={loading || disabled}
      data-loading={loading}
      role={loading ? 'progressbar' : undefined}
    >
      <span
        className={cn(classes.content, classes[`${contentHideMode}HideMode`])}
        data-loading={loading}
      >
        {children}
      </span>
    </Button>
  )
}
