import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'

import { Section } from 'components/section'
import { cn } from 'utils'

import classes from './base-header.module.css'

interface BaseHeaderProps {
  className?: string
  children?: ReactNode
}

export const HEADER_ID = 'main-header'

const _BaseHeader: ForwardRefRenderFunction<HTMLHeadingElement, BaseHeaderProps> = (
  { children, className },
  ref,
) => {
  return (
    <Section
      htmlElement="header"
      theme="hero"
      ref={ref}
      className={cn(classes.header, classes.headerContent, className)}
      id={HEADER_ID}
    >
      {children}
    </Section>
  )
}

export const BaseHeader = forwardRef(_BaseHeader)
