import { ButtonHTMLAttributes, ComponentProps, DetailedHTMLProps, FC } from 'react'

import { cn } from 'utils'

import classes from './button.module.css'

export type ButtonTheme =
  | 'icon'
  | 'link'
  | 'text'
  | 'primary'
  | 'primaryLight'
  | 'primaryDanger'
  | 'primarySmall'
  | 'primarySmallLight'
  | 'secondary'
  | 'secondarySmall'
  | 'accentLink'
  | 'accentLinkToMobileBtn'
  | 'secondaryToMobileBtn'
  | 'goBack'
  | 'inlineLink'
  | 'clearStyle'

export interface ButtonProps extends ComponentProps<'button'> {
  icon?: boolean
  theme?: ButtonTheme
  fullWidth?: boolean
}

export const Button: FC<ButtonProps> = ({
  className,
  fullWidth,
  icon,
  type,
  theme = 'primary',
  ...props
}) => {
  return (
    <button
      type={type ?? 'button'}
      className={cn(
        classes.button,
        classes[theme],
        fullWidth && classes.fullWidth,
        icon && classes.icon,
        className,
      )}
      {...props}
    />
  )
}
