import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { DEFAULT_ERROR_MESSAGE, Request } from 'api/request'
import { resolveUrl } from 'utils/url'
import { BASE_NEST_URL } from 'const/config'
import { routes } from 'utils/routes'
import { isBrowser, isObject } from 'utils/helpers'

export interface NestControllerConfig extends AxiosRequestConfig {
  controller: string
}

export interface NestBaseErrorResponseData {
  statusCode: number
  message: string
  error: string
}

export interface NestValidationErrorBySingleField extends NestBaseErrorResponseData {
  field: string
}

export interface NestValidationStringErrors {
  statusCode: number
  errors: string[]
}

export interface NestValidationErrorObject {
  statusCode: number
  errors: Record<string, string[]>
}

export type NestValidationErrorResponseData = NestValidationStringErrors | NestValidationErrorObject

export type ServerIsNotAvailableErrorResponseData = string

export type NestErrorResponse = AxiosError<
  | NestBaseErrorResponseData
  | NestValidationErrorResponseData
  | ServerIsNotAvailableErrorResponseData
  | NestValidationErrorBySingleField
>

export class NestController extends Request {
  constructor({ controller, ...restAxiosConfig }: NestControllerConfig) {
    super({
      baseURL: resolveUrl(BASE_NEST_URL, controller),
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { encodeValuesOnly: true })
        },
      },
      ...restAxiosConfig,
    })
  }

  static isNestError = (error: unknown): error is NestErrorResponse => {
    if (!axios.isAxiosError(error)) return false

    return (
      NestController.isBaseResponseError(error) ||
      NestController.isValidationError(error) ||
      NestController.isNotAvailableServerError(error) ||
      NestController.isValidationErrorBySingleField(error)
    )
  }

  static getResponseError = (
    error: unknown,
    defaultMessage: string = DEFAULT_ERROR_MESSAGE,
  ): string | Record<string, string[]> | string[] => {
    if (NestController.isNestError(error)) {
      if (NestController.isValidationErrorBySingleField(error)) {
        const responseData = error.response?.data

        if (responseData) {
          return {
            [responseData.field]: [responseData.message || responseData.error],
          }
        }
      }

      if (NestController.isBaseResponseError(error)) {
        return error.response?.data.message || defaultMessage
      }

      if (NestController.isValidationError(error)) {
        return error.response?.data.errors || defaultMessage
      }
    }

    if (axios.isAxiosError(error)) {
      return error.message || defaultMessage
    }

    return defaultMessage
  }

  static isBaseResponseError(error: unknown): error is AxiosError<NestBaseErrorResponseData> {
    if (!axios.isAxiosError(error)) return false

    const responseData = error.response?.data

    return (
      isObject(responseData) &&
      ('message' in responseData || 'error' in responseData) &&
      'statusCode' in responseData
    )
  }

  static isNotAvailableServerError = (
    error: unknown,
  ): error is AxiosError<ServerIsNotAvailableErrorResponseData> => {
    if (!axios.isAxiosError(error)) return false

    const responseData = error.response?.data

    return Boolean(
      responseData && typeof responseData === 'string' && error.response?.status === 502,
    )
  }

  static isValidationErrorBySingleField = (
    error: unknown,
  ): error is AxiosError<NestValidationErrorBySingleField> => {
    if (!axios.isAxiosError(error)) return false

    const responseData = error.response?.data

    return (
      isObject(responseData) && NestController.isBaseResponseError(error) && 'field' in responseData
    )
  }

  static isValidationError(error: unknown): error is AxiosError<NestValidationErrorResponseData> {
    if (!axios.isAxiosError(error)) return false

    const responseData = error.response?.data

    return isObject(responseData) && 'errors' in responseData
  }

  static isValidationObjectError(error: unknown): error is AxiosError<NestValidationErrorObject> {
    if (!this.isValidationError(error)) return false

    const responseData = error.response?.data

    return isObject(responseData) && isObject(responseData.errors)
  }

  handlerResponseError(error: NestErrorResponse): void | Promise<AxiosError> {
    // eslint-disable-next-line no-console
    //console.log('error', error)

    if (
      error.response?.status === 401 &&
      window.location.pathname.startsWith(routes.partnerPortal)
    ) {
      //prevent error in SSR
      if (isBrowser() && window) {
        window.location.href = routes.partnerPortalLogin
      }
    }
    Request.responseErrorLogger(error)
    return Promise.reject(error)
  }
}
