import { startCase } from 'lodash'
import { FC } from 'react'

import { OrderStatusEnum } from 'enum/order-status'
import { cn } from 'utils'

import classes from './order-status.module.css'

interface StatusProps {
  status: OrderStatusEnum
}

const CLASSES_BY_STATUS: Record<OrderStatusEnum, string | undefined> = {
  [OrderStatusEnum.PurchaseInitiated]: classes.purchaseInitiated,
  [OrderStatusEnum.PaymentInitiated]: classes.paymentInitiated,
  [OrderStatusEnum.Suspended]: classes.suspended,
  [OrderStatusEnum.Declined]: classes.declined,
  [OrderStatusEnum.IssuedActive]: classes.issuedActive,
  [OrderStatusEnum.IssuedPending]: classes.issuedPending,
  [OrderStatusEnum.NotIssued]: classes.notIssued,
  [OrderStatusEnum.Cancelled]: classes.cancelled,
  [OrderStatusEnum.CancelledByClient]: classes.cancelledByClient,
  [OrderStatusEnum.Expired]: classes.expired,
  [OrderStatusEnum.PayoutOwed]: classes.payoutOwed,
  [OrderStatusEnum.PayoutCompleted]: classes.payoutCompleted,
}

export const OrderStatus: FC<StatusProps> = ({ status }) => {
  return (
    <span className={cn(classes.orderStatus, CLASSES_BY_STATUS[status])}>{startCase(status)}</span>
  )
}
