import {
  AgreementsController,
  Candidates,
  InvestorController,
  NorthCapitalController,
  OfferingsController,
  PreviewMode,
  WaitlistController,
  PostalCodesController,
} from 'api/nest'
import { CTACollection } from 'api/strapi/cta'
import { MasApi } from 'api/nest/mas-api'

import { ArticlesCollection } from './strapi/articles'
import {
  AuthController,
  BrokerController,
  Calculation,
  MarketsCollection,
  ReferralsCollection,
} from './nest'
import { FaqCategoryCollection } from './strapi/faq-categories'
import { FaqCollection } from './strapi/faqs'
import { NewslettersController } from './strapi/newsletters'
import { OpportunitiesCollection } from './strapi/opportunities'
import { OrdersController } from './nest/orders'
import { PagesController } from './strapi/pages'
import { TagsCollection } from './strapi/tags'
import { TeamsCollection } from './strapi/teams'
import { TestimonialsCollection } from './strapi/testimonials'
import { UseCaseCollection } from './strapi/use-cases'
import { WhyBuyReasonsCollection } from './strapi/why-buy-reasons'
import { ContactUs } from './nest/contact-us'
import { Subscriptions } from './nest/subscriptions'
import { CompanyReferral } from './nest/company-referral/inex'
import { PaymentController } from './nest/payment'
import { PaymentSystemController } from './nest/payment-system'
import { PropertiesController } from './nest/properties/api'
import { LegalDocumentsController } from './nest/legal-documents'
import { HomeSellers } from './nest/home-sellers'
import { HomepageBanner } from './strapi/homepage-banner'
import { ThingsToKnow } from './strapi/thingsToKnow'
import { PurchaserController } from './nest/purchaser'
import { FactpackController } from './nest/factpack'
import { RealEstateCompanyController } from './nest/real-estate-company'
import { MortgageCompanyController } from './nest/mortgage-company'

export * from './strapi/articles/types'
export * from './strapi/faq-categories/types'
export * from './strapi/faqs/types'
export * from './strapi/pages/types'
export * from './strapi/tags/types'
export * from './strapi/testimonials/types'

export const strapi = {
  articles: new ArticlesCollection(),
  faqCategories: new FaqCategoryCollection(),
  faqs: new FaqCollection(),
  newsletters: new NewslettersController(),
  opportunities: new OpportunitiesCollection(),
  pages: new PagesController(),
  tags: new TagsCollection(),
  teams: new TeamsCollection(),
  testimonials: new TestimonialsCollection(),
  useCases: new UseCaseCollection(),
  whyBuyReasons: new WhyBuyReasonsCollection(),
  homepageBanner: new HomepageBanner(),
  thingsToKnow: new ThingsToKnow(),
}

export const nest = {
  auth: new AuthController(),
  broker: new BrokerController(),
  investor: new InvestorController(),
  markets: new MarketsCollection(),
  northCapital: new NorthCapitalController(),
  orders: new OrdersController(),
  referrals: new ReferralsCollection(),
  agreements: new AgreementsController(),
  calculation: new Calculation(),
  cta: new CTACollection(),
  masApi: new MasApi(),
  offerings: new OfferingsController(),
  contactUs: new ContactUs(),
  subscriptions: new Subscriptions(),
  candidates: new Candidates(),
  previewMode: new PreviewMode(),
  companyReferral: new CompanyReferral(),
  payment: new PaymentController(),
  paymentSystem: new PaymentSystemController(),
  properties: new PropertiesController(),
  waitlist: new WaitlistController(),
  legalDocuments: new LegalDocumentsController(),
  postalCodes: new PostalCodesController(),
  homesellers: new HomeSellers(),
  purchaser: new PurchaserController(),
  factpack: new FactpackController(),
  realEstateCompany: new RealEstateCompanyController(),
  mortgageCompany: new MortgageCompanyController(),
}
