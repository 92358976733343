import { memo, FC } from 'react'

import { cn } from 'utils'
import { ReferralModel } from 'api/nest'

import { GRID_COLUMNS, GridColumn } from './consts'
import { ActionMenuProps, Actions } from './actions'
import classes from './referrals-grid.module.css'

interface GridRowProps {
  referral: ReferralModel
  onAction: ActionMenuProps['onClick']
  columns: GridColumn[]
}

const _GridRow: FC<GridRowProps> = ({ referral, onAction, columns }) => {
  return (
    <div className={classes.gridRow}>
      {columns.map((column) => (
        <div className={cn(classes.gridItem, column.className)} key={column.dataKey}>
          {column.dataKey === 'actions' && <Actions referral={referral} onAction={onAction} />}
          {column.dataKey !== 'actions' &&
            (column.render ? column.render(referral) : referral[column.dataKey])}
        </div>
      ))}
    </div>
  )
}

export const GridRow = memo<GridRowProps>(_GridRow)
