import { OptionValue, SelectFormFieldOptions } from './types'

interface IOptionsConverterResult {
  label: string | number
  value: OptionValue
  disabled?: boolean
}

export function optionsConverter(
  options: SelectFormFieldOptions,
  disabledOptions?: OptionValue[],
): IOptionsConverterResult[] {
  if (Array.isArray(options)) {
    return options.map((option) => ({
      ...option,
      disabled: disabledOptions?.includes(option.value) || option.disabled,
    }))
  }

  return Object.entries(options).map(([value, label]) => ({
    value,
    label,
    disabled: disabledOptions?.includes(value),
  }))
}
