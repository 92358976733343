import { useCookie, useEffectOnce } from 'react-use'
import { toast } from 'react-toastify'

export const usePreviewModeNotification = () => {
  const [value] = useCookie('is-enabled-preview-mode')

  useEffectOnce(() => {
    if (!value) return

    const isEnabled = value === 'true'
    toast(`Preview mode ${isEnabled ? 'enabled' : 'disabled'}`, {
      toastId: `preview_mode_${value}`,
      type: isEnabled ? 'warning' : 'success',
    })
  })
}
