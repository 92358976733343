import NextLink from 'next/link'
import { ComponentProps, FC } from 'react'

import { cn } from 'utils'

import classes from './link.module.css'

export type LinkProps = ComponentProps<typeof NextLink> & {
  theme?: 'primaryLight' | 'primary'
  href: string
}

export const Link: FC<LinkProps> = ({
  children,
  href,
  passHref,
  locale,
  replace,
  scroll,
  shallow,
  prefetch,
  theme,
  as,
  className,
  ...anchorProps
}) => {
  return (
    <NextLink
      href={href}
      passHref={passHref}
      locale={locale}
      replace={replace}
      scroll={scroll}
      prefetch={prefetch}
      shallow={shallow}
      as={as}
      className={cn(theme && `${classes.styledLink} ${classes[theme]}`, className)}
      {...anchorProps}
    >
      {children}
    </NextLink>
  )
}
