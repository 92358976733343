export enum QUERY_KEY {
  CTA = 'CTA',
  videoPlaceholder = 'videoPlaceholder',
  searchMarketByZipCode = 'searchMarketByZipCode',
  indexChartData = 'indexChartData',
  indexChartLastItemData = 'indexChartLastItemData',
  mediaSalesChartData = 'mediaSalesChartData',
  priceVolatilityChartData = 'priceVolatilityChartData',
  propertiesSoldChart = 'propertiesSoldChart',
  userMarket = 'userMarket',
  pricesOfMarket = 'pricesOfMarket',
  company = 'company',
  companyReferral = 'companyReferral',
  order = 'order',
  investor = 'investor',
  homepageBanner = 'homepageBanner',
  thingsToKnow = 'thingsToKnow',
  purchaser = 'purchaser',
  property = 'property',
  orders = 'orders',
  referrals = 'referrals',
  contracts = 'contracts',
  signingUrl = 'signingUrl',
  previewAgreementPdfUrl = 'previewAgreementPdfUrl',
  useCases = 'useCases',
  markets = 'markets',
  howItWorksPageData = 'howItWorksPageData',
  homePageData = 'homePageData',
  factPack = 'factPack',
  factPackImage = 'factPackImage',
  uploadFactPack = 'uploadFactPack',
  agents = 'agents',
  realEstateCompany = 'realEstateCompany',
  mortgageCompany = 'mortgageCompany',
}
