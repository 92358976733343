import { FC } from 'react'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

import { NoSsr } from 'components/no-ssr'
import { cn } from 'utils'

import classes from './info-tooltip.module.css'

export const InfoTooltip: FC<TooltipProps> = ({ className, ...rest }) => {
  return (
    <NoSsr>
      <ReactTooltip
        place="top"
        effect="solid"
        type="light"
        border
        borderColor="var(--color-primary-75)"
        className={cn(classes.tooltip, className)}
        arrowColor="var(--background-color-primary)"
        {...rest}
      />
    </NoSsr>
  )
}
