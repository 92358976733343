import { ReactNode, FC } from 'react'
import NextImage from 'next/image'

import { Link, LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { UserInfo } from 'components/header/partner-portal-header/user-info'
import { cn } from 'utils'
import { BrokerModelWithPermissions } from 'utils/get-server-side-props-utils/withAuthUser'
import Logo from 'icons/logo-standard-black-and-blue-trademark.png'

import { BaseHeader } from '../base-header'
import classes from './partner-portal-header.module.css'

export interface PartnerPortalHeaderProps {
  backLink?: string
  backLinkText?: ReactNode
  showStartOverButton?: boolean
  onStartOver?: () => void
  authUser?: BrokerModelWithPermissions
  withAuthUser?: boolean
}

export const PartnerPortalHeader: FC<PartnerPortalHeaderProps> = ({
  backLinkText,
  backLink,
  authUser,
  withAuthUser,
}) => {
  return (
    <BaseHeader className={cn(classes.header, withAuthUser && classes.sectionAuth)}>
      <Link href={routes.partnerPortalDashboard} className={classes.logoWrapper}>
        <div className={classes.logoContainer}>
          <NextImage
            src={Logo}
            alt="logo"
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
            className={classes.logo}
          />
        </div>
      </Link>
      <Link href={routes.partnerPortalDashboard} className={classes.title}>
        <h2>Partner Portal</h2>
      </Link>
      {backLink && (
        <LinkButton className={classes.backLink} theme="goBack" href={backLink}>
          {backLinkText}
        </LinkButton>
      )}
      {withAuthUser && authUser && <UserInfo user={authUser} />}
    </BaseHeader>
  )
}
