import { FC } from 'react'

import { cn } from 'utils'

import { CREDIT_CARD_NUMBER, FORM_FIELD_LABEL } from '../consts'
import { ExtendMaskerFieldProps } from './types'
import { MaskedTextFormField } from './MaskedTextFormField'

export const CreditCardFormField: FC<ExtendMaskerFieldProps> = ({
  autoCompleteGroup,
  mask = '0000 0000 0000 0000',
  ...props
}) => {
  return (
    <MaskedTextFormField
      mask={mask}
      label={props.label ?? FORM_FIELD_LABEL.cardNumber}
      autoComplete={cn(autoCompleteGroup, 'cc-number')}
      minLength={CREDIT_CARD_NUMBER.formattedMinLength}
      {...props}
      maxLength={CREDIT_CARD_NUMBER.formattedMaxLength}
      {...props}
    />
  )
}
