import { FC } from 'react'

import { Section } from 'components/section'
import { Link } from 'components/links'
import { cn } from 'utils'
import { routes } from 'utils/routes'

import classes from './sub-footer.module.css'

const SUB_FOOTER_NAV: Record<string, string> = {
  'Privacy Policy': routes.privacyPolicy,
  'Terms of Use': routes.termsOfUse,
}

interface SubFooterProps {
  className?: string
}

export const SubFooter: FC<SubFooterProps> = ({ className }) => {
  const year = new Date().getFullYear()

  return (
    <div className={cn(classes.container, className)}>
      <Section theme="withoutGap" htmlElement="div" className={classes.section}>
        <span className={classes.copyPast}>© {year} REZITRADE LLC</span>

        <nav className={classes.nav}>
          <ul className={classes.list}>
            {Object.entries(SUB_FOOTER_NAV).map(([name, link]) => (
              <li key={name} className={classes.listItem}>
                <Link href={link} className={classes.listItemLink}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </Section>
    </div>
  )
}
