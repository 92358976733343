/**
 * Need for check asynchronous operations in dev environment
 */
export const wait = (timer: number, isReject?: boolean, response?: any) => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => (isReject ? reject(response) : resolve(response)), timer)
  })
}

export const isObject = <T = {}>(obj: unknown): obj is T => {
  return obj !== null && typeof obj === 'object' && Array.isArray(obj) === false
}

export const isBrowser = () => typeof window !== 'undefined'

export const isDebugMode = () => {
  return process.env.NODE_ENV === 'development' || (isBrowser() && window.debug)
}

export const clearUndefined = (obj: {} = {}) => {
  const result = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      result[key] = obj[key]
    }
  })
  return result
}

export const getKeyByValue = <T extends Record<any, any>>(object: T, value: T[keyof T]) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const disableEscapeNewLineSymbol = (str: string) => {
  return str.replace(/\\n/g, '\n')
}

// Authorize integration will only work with https (use ngrok); provide shortcut through process when using http locally
export const shouldSkipPayment =
  isBrowser() && process.env.NODE_ENV === 'development' && window.location.protocol === 'http:'
