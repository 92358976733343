import { AxiosRequestConfig } from 'axios'

import { FindAllQuery, MarketModel, MarketModelByZipCode } from './types'
import { NestCollection } from '../NestCollection'
import { OfferingModel } from '../offerings/types'

export class MarketsCollection extends NestCollection<MarketModel, true, FindAllQuery> {
  constructor() {
    super({ controller: 'markets' })
  }

  override defaultFindQuery: FindAllQuery = {
    filters: {
      isEnabled: { $eq: true },
    },
  }

  async findByRequestIP(options?: AxiosRequestConfig): Promise<MarketModel> {
    try {
      return await this.get('/ip', options)
    } catch (error) {
      return this.get('default') // Assuming MarketModel has a default constructor
    }
  }

  async finDefault(): Promise<MarketModel> {
    return this.get('/default')
  }

  async findByUserOrDefault(): Promise<MarketModel> {
    try {
      return await this.findByRequestIP()
    } catch (e) {
      return await this.finDefault()
    }
  }

  async findOneBySlug(searchSlug: MarketModel['slug']): Promise<MarketModel | undefined> {
    const markets = await this.find({
      filters: {
        slug: { $eq: searchSlug },
        ...this.defaultFindQuery.filters,
      },
    })

    return markets[0]
  }

  public findByUuid = (uuid: string) => {
    return this.get<MarketModel>(uuid)
  }

  public findByZipCode = (postalCode: string) => {
    return this.get<MarketModelByZipCode[]>('', {
      postalCode,
    })
  }

  sendLinkToEmail = (uuid: string, email: string) => {
    return this.post(`/${uuid}/send-link`, { email })
  }

  getAvailableOfferings(marketUuid: string) {
    return this.get<OfferingModel[]>(`/${marketUuid}/available-offerings`)
  }
}
